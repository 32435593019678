import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import { useAlertSnackbar } from '../contexts/AlertSnackbarContext';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';


const ReportLayout = ({ title, path, previewTable, filterTable, activeFilterTable, handleExport, translateTable }) => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { showAlert } = useAlertSnackbar();
    const [data, setData] = useState([]);
    const [activeTable, setActiveTable] = useState(activeFilterTable || null);

    const [dailyDate, setDailyDate] = useState({
        startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
        endDate: new Date().toISOString().slice(0, 10)
    });

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}${path}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            params: {
                startDate: dayjs(dailyDate.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dailyDate.endDate).format('YYYY-MM-DD'),
            }
        }).then(function (response) {
            setData(response.data);
        }).catch(function (error) {
            showAlert('เกิดข้อผิดพลาดในการโหลดข้อมูล', 'error');
        }).finally(function () {
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, [dailyDate, path]);




    return (
        <div className='flex flex-col' style={{ minHeight: 'calc(100vh - 95px)' }}>
            <div className='flex justify-between w-full mb-6'>
                {/*<h1 className='text-xl font-semibold text-gray-800'>{title}</h1>*/}

                {filterTable && (
                    <div className='flex justify-center gap-4 mb-4'>
                        {
                            filterTable.map((filter, index) => (
                                <Chip
                                    key={index}
                                    label={filter}
                                    clickable
                                    color={activeTable === filter ? 'primary' : 'default'}
                                    onClick={() => setActiveTable(filter)}
                                />
                            ))
                        }
                    </div>
                )}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className=' flex justify-end gap-4'>
                        <div className=''>
                            <DatePicker
                                label="วันที่เริ่มต้น"
                                value={dailyDate.startDate}
                                onChange={(newValue) => {
                                    setDailyDate({
                                        ...dailyDate,
                                        startDate: newValue.format("YYYY-MM-DD"),
                                    });
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField fullWidth {...params} size="small" />}
                            />
                        </div>
                        <div className=''>
                            <DatePicker
                                label="วันที่สิ้นสุด"
                                value={dailyDate.endDate}
                                onChange={(newValue) => {
                                    setDailyDate({
                                        ...dailyDate,
                                        endDate: newValue.format("YYYY-MM-DD"),
                                    });
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField fullWidth {...params} size="small" />}
                            />
                        </div>
                        <button
                            className='bg-indigo-800 text-white px-8 py-2 rounded-md self-start'
                            disabled={isLoading}
                            onClick={() => handleExport(data, title, dailyDate)}
                        >
                            Download Excel
                        </button>
                    </div>
                </LocalizationProvider>
            </div>



            <div className='overflow-x-auto bg-white mb-4'>
                <table className='w-full max-w-full'>
                    <thead>
                        <tr>
                            {previewTable.map((header, index) => (
                                <th key={index} className='border-b border-gray-300 p-2 text-left'>
                                    {translateTable ? translateTable[header] : header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.data &&
                                data.data[activeTable]
                                ?
                                data.data[activeTable]?.map((row, index) => (
                                    <tr key={index}>
                                        {previewTable?.map((header, index) => (
                                            <td key={index} className='border-b border-gray-300 p-2'>{row[header]}</td>
                                        ))}
                                    </tr>
                                ))
                                :
                                data.data?.map((row, index) => (
                                    <tr key={index}>
                                        {previewTable?.map((header, index) => (
                                            <td key={index} className='border-b border-gray-300 p-2'>{row[header]}</td>
                                        ))}
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReportLayout;