import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from "../layouts/layout"
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import GoogleMapIframe from '../components/GoogleMapIframe';
import * as XLSX from 'xlsx';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Drawer from '@mui/material/Drawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

export default function Inquiry() {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [productList, setProductList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);
    const [filter, setFilter] = useState('all');
    const [openDialog, setOpenialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteWebsite, setDeleteWebsite] = useState(null);
    const [singleData, setSingleData] = useState({});
    const [nowfocusonwebsite, setNowfocusonwebsite] = useState('customer_inquiry');
    const [openEdit, setOpenEdit] = useState(false);
    const [allAdmin, setAllAdmin] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    const fetchProducts = useCallback((value = 1, fill = 'all') => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry/all-company/${value}/${fill}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {

            }
            if (response.data.status === 200) {
                setProductList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.all);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setAllAdmin(response.data.data);
            }
        }).catch(function (error) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'ไม่สามารถเรียกข้อมูล Admin ได้'
            });
        });
    }, []);


    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchProducts(value, filter);
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setSingleData({});
        setSelectedAdmin(null);
    };

    async function fetchData(urls) {
        const dataSets = [];

        for (let url of urls) {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BACKEND_URL}${url}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
                    }
                });
                if (response.status === 200) {
                    dataSets.push(response.data.data);
                }
            } catch (error) {
                console.error(`Error fetching data from ${url}:`, error);
            }
        }

        return dataSets;
    }

    function exportToExcel(dataSets, sheetNames) {
        const wb = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const ws = XLSX.utils.json_to_sheet(dataSet);
            XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
        });

        XLSX.writeFile(wb, "exported_data.xlsx");
    }

    const handleExport = async () => {
        setIsLoading(true);
        const urls = [
            '/inquiry/export/gse',
            '/inquiry/export/gsi',
            '/inquiry/export/mcap'
        ];

        const sheetNames = [
            'gse-stores.com',
            'gseinsurebroker.com',
            'mcapital.co.th'
        ];

        const dataSets = await fetchData(urls);

        if (dataSets.length === sheetNames.length) {
            exportToExcel(dataSets, sheetNames);
        } else {
            console.error("Unexpected data format received from the API or some URLs might have failed.");
        }

        setIsLoading(false);
    };

    const handleFilter = (e) => {
        page.current = 1;
        setFilter(e.target.value);
        setIsLoading(true)
        fetchProducts(1, e.target.value);
    }

    const convertWebsiteTotable = (website) => {
        switch (website) {
            case 'gse-stores.com':
                return 'customer_inquiry';
            case 'mcapital.co.th':
                return 'mcapital_inquiry';
            case 'gseinsurebroker.com':
                return 'gseinsure_inquiry';
            default:
                return 'ไม่ระบุ';
        }
    }

    const handleDelete = (id, website) => {
        setIsLoading(true);
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/complaint/delete/${convertWebsiteTotable(website)}/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                setProductList(productList.filter((item) => item.id !== id));
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'ลบข้อมูลไม่สำเร็จ'
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'ลบข้อมูลไม่สำเร็จ'
            });
        });
    }

    const fetchSingleInquiry = (id, website) => {
        setNowfocusonwebsite(website);
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/by-company/${id}/${convertWebsiteTotable(website)}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setSingleData(response.data.data);
            setIsLoading(false);
            setOpenEdit(true);
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'เกิดข้อผิดพลาด'
            });
        });
    }

    const convertStatus = (status) => {
        switch (status) {
            case 1:
                return { status: 'ยังไม่ได้มอบหมาย', bgColor: 'rgb(250 204 21)', textColor: 'text-black' };
            case 2:
                return { status: 'มอบหมายแล้ว', bgColor: 'rgb(96 165 250)', textColor: 'text-white' };
            default:
                return { status: 'ยังไม่ได้ระบุ', bgColor: 'bg-gray-400', textColor: 'text-white' };
        }
    }

    const handleConverToOrder = () => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/convert-to-order`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: singleData.id,
                ref: singleData.ref,
                assign_to: selectedAdmin,
            }
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                setProductList(productList.filter((item) => item.id !== singleData.id));
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'แปลงข้อมูลเป็น Order สำเร็จ'
                });
                setOpenEdit(false);
                setSelectedAdmin(null);
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'แปลงข้อมูลเป็น Order ไม่สำเร็จ'
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'แปลงข้อมูลเป็น Order ไม่สำเร็จ'
            });
        });
    }

    return (
        <Layout mainClass='bg-gray-100 '>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4 items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>รายการผู้สนใจ</h1>
                        <select
                            className='border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-0 focus:border-gray-300'
                            value={filter}
                            onChange={handleFilter}
                        >
                            <option value="all">ทั้งหมด</option>
                            <option value="customer_inquiry">gse-stores.com</option>
                            <option value="mcapital_inquiry">mcapital.co.th</option>
                            <option value="gseinsure_inquiry">gseinsurebroker.com</option>
                        </select>
                    </div>
                    <div className='flex gap-4'>
                        <button disabled={isLoading} className='bg-indigo-800 px-4 rounded-md text-white' onClick={handleExport}>
                            {isLoading ? 'Exporting...' : 'Export as Excel'}
                        </button>
                        {/** 
                        <button className='bg-indigo-800 px-4 rounded-md text-white' onClick={handleExport}>Export as Excel</button>
                        <button className='bg-gray-200 px-4 rounded-md' onClick={() => fetchProducts()}>Reload</button>
                        */}
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full grid grid-cols-5 gap-4 table-row-head`} >
                            <div className='font-bold'>ชื่อลูกค้า</div>
                            <div className='font-bold'>หมายเลขโทรศัพท์</div>
                            <div className='font-bold'>วันที่</div>
                            <div className='font-bold'>จากเว็บไซต์</div>
                            <div className='text-right font-bold'>จัดการ</div>
                        </div>
                        {productList.length > 0 && productList.map((product, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full grid grid-cols-5 items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='' onClick={() => {
                                    fetchSingleInquiry(product.id, product.ref);
                                }}>{product.customer}</div>
                                <div className=''>{product.phone}</div>
                                <div className=''>
                                    {
                                        new Date(product.date).toLocaleDateString('th-TH', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })
                                    }
                                </div>
                                <div className=''>{product.ref}</div>
                                <div className='flex justify-end gap-2'>
                                    <Tooltip title="จัดการ" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            fetchSingleInquiry(product.id, product.ref);
                                        }} >
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            setDeleteId(product.id);
                                            setDeleteWebsite(product.ref);
                                            setOpenialog(true);
                                        }}>
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div className='ml-4 hidden lg:block'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Drawer
                anchor={'right'}
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <DialogTitle>
                    <div className='flex justify-between'>
                        <div className='flex gap-2'>
                            ข้อมูลลูกค้าผู้สนใจ
                            <Chip
                                label={convertStatus(singleData.c_status).status}
                                sx={{
                                    backgroundColor: convertStatus(singleData.c_status).bgColor,
                                    color: convertStatus(singleData.c_status).textColor,
                                }}
                            />
                        </div>
                        <IconButton aria-label="close" onClick={handleCloseEdit} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DialogContent>
                    <div className='draw-inner-cc'>

                        <div className='mb-6 pt-2'>

                            <Autocomplete
                                sx={{ width: '100%' }}
                                options={allAdmin}
                                getOptionLabel={(option) => option.admin_name}
                                renderInput={(params) => <TextField {...params} label="เลือกสาขาที่ต้องการมอบหมาย" variant="outlined" />}
                                onChange={(event, newValue) => {
                                    setSelectedAdmin(newValue ? newValue.id : null);
                                }}
                                value={selectedAdmin ? allAdmin.find((item) => item.id === selectedAdmin) : null}
                            />
                        </div>

                        {nowfocusonwebsite === 'gse-stores.com' &&
                            <div>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ชื่อ - นามสกุล</label>
                                        <p className='mb-4'>{singleData.c_name} {singleData.c_lastname}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>อาชีพ</label>
                                        <p className='mb-4'>{singleData.c_company}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ที่อยู่</label>
                                        <p className='mb-4'>
                                            {singleData.c_address}<br />
                                            แขวง/ตำบล: {singleData.c_sub_district} <br />
                                            อำเภอ/เขต: {singleData.c_district} <br />
                                            จังหวัด: {singleData.c_province} {singleData.c_post_code}
                                        </p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>เบอร์โทรศัพท์</label>
                                        <p className='mb-4 '>{singleData.c_phone}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>อีเมล</label>
                                        <p className='mb-4'>{singleData.c_email}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>วันที่สมัคร</label>
                                        <p className='mb-4'>{singleData.create_at}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>สนใจสินค้า</label>
                                        <p className='mb-4'>{singleData.interesting_in === 'gold' ? 'ทอง' : 'เครื่องใช้ไฟฟ้า'}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>IP Address</label>
                                        <p className=''>{singleData.c_ip}</p>
                                    </div>
                                </div>

                                <h3 className='text-xl font-semibold mb-4 mt-8'>แผนที่</h3>
                                <GoogleMapIframe lat={singleData.c_lat} lng={singleData.c_lng} />
                            </div>
                        }

                        {nowfocusonwebsite === 'mcapital.co.th' &&
                            <div>
                                <h3 className='text-xl font-semibold mb-4'>ข้อมูลการติดต่อ</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ชื่อ - นามสกุล</label>
                                        <p className='mb-4'>{singleData.c_name}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>เบอร์โทรศัพท์</label>
                                        <p className='mb-4'>{singleData.c_phone}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>อีเมล</label>
                                        <p className='mb-4'>{singleData.c_email ? singleData.c_email : '-'}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>วันที่สมัคร</label>
                                        <p className='mb-4'>{singleData.create_at}</p>
                                    </div>
                                </div>
                                <h3 className='text-xl font-semibold mb-4 mt-8'>สถานประกอบการ</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ที่อยู่</label>
                                        <p className='mb-4'>
                                            {singleData.c_address}<br />
                                            แขวง/ตำบล: {singleData.c_sub_district} <br />
                                            อำเภอ/เขต: {singleData.c_district} <br />
                                            จังหวัด: {singleData.c_province}
                                        </p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ชื่อกิจการ</label>
                                        <p className='mb-4'>{singleData.c_business}</p>
                                    </div>
                                </div>
                                <h3 className='text-xl font-semibold mb-4 mt-8'>ข้อมูลธุรกิจ</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ประเภทธุรกิจ</label>
                                        <p className='mb-4'>{singleData.c_business_type}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>รายได้ต่อเดือน</label>
                                        <p className='mb-4'>{singleData.c_income}</p>
                                    </div>
                                </div>
                            </div>
                        }

                        {nowfocusonwebsite === 'gseinsurebroker.com' &&
                            <div>
                                <h3 className='text-xl font-semibold mb-4'>ข้อมูลการติดต่อ</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ชื่อ-นามกสุล ผู้เอาประกัน</label>
                                        <p className='mb-4'>{singleData.customer_name}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>เบอร์โทรศัพท์</label>
                                        <p className='mb-4'>{singleData.customer_phone}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>อีเมล</label>
                                        <p className='mb-4'>{singleData.customer_email ? singleData.customer_email : '-'}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>วันที่สมัคร</label>
                                        <p className='mb-4'>{singleData.create_at}</p>
                                    </div>
                                </div>

                                <h3 className='text-xl font-semibold mb-4'>ข้อมูลรถยนต์</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>แบรนด์รถยนต์</label>
                                        <p className='mb-4'>{singleData.c_make}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>รุ่นรถยนต์</label>
                                        <p className='mb-4'>{singleData.c_model}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ปีรถ</label>
                                        <p className='mb-4'>{singleData.c_year}</p>
                                    </div>
                                    <div>
                                        <label className='block text-xs text-gray-500'>รุ่นย่อย</label>
                                        <p className='mb-4'>{singleData.c_sub_model}</p>
                                    </div>
                                </div>

                                <h3 className='text-xl font-semibold mb-4'>ประเภทประกัน</h3>
                                <div className='grid lg:grid-cols-2 gap-4'>
                                    <div>
                                        <label className='block text-xs text-gray-500'>ประเภทประกันที่สนใจ</label>
                                        <p className='mb-4'>{singleData.insurance_type}</p>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>ปิดหน้าต่าง</Button>
                    <Button
                        onClick={handleConverToOrder}
                        variant="contained"
                        color="primary"
                    >บันทึกการเปลี่ยนแปลง</Button>
                </DialogActions>
            </Drawer>

            <Dialog
                open={openDialog}
                onClose={() => { setOpenialog(false); }}
            >
                <DialogTitle>
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    เมื่อลบข้อมูลแล้วจะไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลใช่หรือไม่?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenialog(false); }}>ยกเลิก</Button>
                    <Button onClick={() => {
                        handleDelete(deleteId, deleteWebsite);
                        setOpenialog(false);
                    }}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>


            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}