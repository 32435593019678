import { useState, useEffect, useContext, useRef } from 'react';
import Layout from "../../layouts/layout";
import { useParams, Link } from "react-router-dom";
import api from '../../services/apiService';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {
    Autocomplete, TextField, Button, FormControl, FormLabel,
    FormControlLabel, FormHelperText, RadioGroup, Radio, CircularProgress, InputAdornment
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LoadingContext from '../../contexts/LoadingContext';
import { useAlertSnackbar } from '../../contexts/AlertSnackbarContext';

const EditPurchaseRequest = () => {

    const { id } = useParams();
    const formRef = useRef(null);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { showAlert } = useAlertSnackbar();
    const [loading, setLoading] = useState(false);
    const [branches, setBranches] = useState([]);
    const [data, setData] = useState({});
    const [expenses, setExpenses] = useState({
        customerExpensesPerDay: {},
        customerExpensesPerMonth: {},
        guarantorExpensesPerDay: {},
        guarantorExpensesPerMonth: {},
    });

    const [profitPerMonth, setProfitPerMonth] = useState(0);
    const [profitPerDay, setProfitPerDay] = useState(0);
    const [IFSPerMonth, setIFSPerMonth] = useState(0);
    const [IFSPerDay, setIFSPerDay] = useState(0);
    const [hiddenCostsPerMonth, setHiddenCostsPerMonth] = useState(0);
    const [hiddenCostsPerDay, setHiddenCostsPerDay] = useState(0);
    const [remainingIncomePerMonth, setRemainingIncomePerMonth] = useState(0);
    const [remainingIncomePerDay, setRemainingIncomePerDay] = useState(0);

    const [totalExpenses, setTotalExpenses] = useState({
        perMonth: 0,
        perDay: 0
    });
    const [netRemainingIncome, setNetRemainingIncome] = useState({
        perMonth: 0,
        perDay: 0
    });

    const [guarantorProfitPerMonth, setGuarantorProfitPerMonth] = useState(0);
    const [guarantorProfitPerDay, setGuarantorProfitPerDay] = useState(0);
    const [guarantorIFSPerMonth, setGuarantorIFSPerMonth] = useState(0);
    const [guarantorIFSPerDay, setGuarantorIFSPerDay] = useState(0);
    const [guarantorHiddenCostsPerMonth, setGuarantorHiddenCostsPerMonth] = useState(0);
    const [guarantorHiddenCostsPerDay, setGuarantorHiddenCostsPerDay] = useState(0);
    const [guarantorRemainingIncomePerMonth, setGuarantorRemainingIncomePerMonth] = useState(0);
    const [guarantorRemainingIncomePerDay, setGuarantorRemainingIncomePerDay] = useState(0);

    const [guarantorTotalExpenses, setGuarantorTotalExpenses] = useState({
        perMonth: 0,
        perDay: 0
    });
    const [guarantorNetRemainingIncome, setGuarantorNetRemainingIncome] = useState({
        perMonth: 0,
        perDay: 0
    });

    const [productType, setProductType] = useState('');

    const admin_branch_id = parseInt(localStorage.getItem('admin_branch_id'));
    const admin_role = localStorage.getItem('admin_role');

    useEffect(() => {
        setLoading(true);
        api.get('/branch-admin/get-all')
            .then(response => {
                setBranches(response.data.data);
                if (admin_role === '2' && admin_branch_id && !id) {
                    setValue('branch', response.data.data.find(branch => branch.id === admin_branch_id));
                }
            }).catch(error => {
                console.error('Error fetching branches:', error);
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        document.title = 'คำขอใช้บริการ ';
        const fetchData = async () => {
            try {
                const response = await api.get(`/purchase-request/single/${id}`);
                const resData = response.data.data;
                setData(resData);


                const expensesData = response.data.expenses;
                const customerExpensesPerDay = expensesData.find(expense => expense.expenses_type === 'customerExpensesPerDay');
                const customerExpensesPerMonth = expensesData.find(expense => expense.expenses_type === 'customerExpensesPerMonth');
                const guarantorExpensesPerDay = expensesData.find(expense => expense.expenses_type === 'guarantorExpensesPerDay');
                const guarantorExpensesPerMonth = expensesData.find(expense => expense.expenses_type === 'guarantorExpensesPerMonth');

                setExpenses({
                    customerExpensesPerDay: customerExpensesPerDay,
                    customerExpensesPerMonth: customerExpensesPerMonth,
                    guarantorExpensesPerDay: guarantorExpensesPerDay,
                    guarantorExpensesPerMonth: guarantorExpensesPerMonth,
                });

                setValue('dateTimeOfRequest', dayjs(resData.created_at));
                setValue('first_name', resData.first_name || '');
                setValue('last_name', resData.last_name || '');
                setValue('nickname', resData.nickname || '');
                setValue('customer_age', resData.customer_age || '');
                setValue('date_of_birth', dayjs(resData.date_of_birth) || '');
                setValue('customer_id_card', resData.customer_id_card || '');
                setValue('date_of_expiry', dayjs(resData.date_of_expiry) || '');
                setValue('marital_status', resData.marital_status || '');
                setValue('address_status', resData.address_status || '');
                setValue('num_of_residents', resData.num_of_residents || '0');
                setValue('num_of_children', resData.num_of_children || '0');
                setValue('children_studying', resData.children_studying || '0');
                setValue('phone_number', resData.phone_number || '');
                setValue('facebook', resData.facebook || '');
                setValue('line_id', resData.line || '');
                setValue('customer_address', resData.customer_address || '');
                setValue('sub_district', resData.sub_district || '');
                setValue('district', resData.district || '');
                setValue('province', resData.province || '');
                setValue('postcode', resData.postcode || '');
                setValue('period_of_residence', resData.period_of_residence || '');
                setValue('career_type', resData.career_type || '');
                setValue('career_description', resData.career_description || '');
                setValue('career_address', resData.career_address || '');
                setValue('career_sub_district', resData.career_sub_district || '');
                setValue('career_district', resData.career_district || '');
                setValue('career_province', resData.career_province || '');
                setValue('career_postcode', resData.career_postcode || '');
                setValue('career_phone', resData.career_phone || '');
                setValue('period_of_employment', resData.period_of_employment || '');
                setValue('career_income', resData.career_income || '');
                setValue('career_income_type', resData.career_income_type || '');
                setValue('spouse_first_name', resData.spouse_first_name || '');
                setValue('spouse_last_name', resData.spouse_last_name || '');
                setValue('spouse_nickname', resData.spouse_nickname || '');
                setValue('spouse_age', resData.spouse_age || '');
                setValue('spouse_phone', resData.spouse_phone || '');
                setValue('spouse_career', resData.spouse_career || '');
                setValue('spouse_career_address', resData.spouse_career_address || '');
                setValue('spouse_career_sub_district', resData.spouse_career_sub_district || '');
                setValue('spouse_career_district', resData.spouse_career_district || '');
                setValue('spouse_career_province', resData.spouse_career_province || '');
                setValue('spouse_career_postcode', resData.spouse_career_postcode || '');
                setValue('spouse_career_period_of_employment', resData.spouse_career_period_of_employment || '');
                setValue('spouse_career_income', resData.spouse_career_income || '');
                setValue('spouse_career_income_type', resData.spouse_career_income_type || '');

                /* Emergency Contact */
                setValue('emergency_firstname', resData.emergency_firstname || '');
                setValue('emergency_lastname', resData.emergency_lastname || '');
                setValue('emergency_nickname', resData.emergency_nickname || '');
                setValue('emergency_age', resData.emergency_age || '');
                setValue('emergency_relationship', resData.emergency_relationship || '');
                setValue('emergency_phone', resData.emergency_phone || '');
                setValue('emergency_address', resData.emergency_address || '');
                setValue('emergency_sub_district', resData.emergency_sub_district || '');
                setValue('emergency_district', resData.emergency_district || '');
                setValue('emergency_province', resData.emergency_province || '');
                setValue('emergency_postcode', resData.emergency_postcode || '');

                /* Guarantor */
                setValue('guarantor_firstname', resData.guarantor_firstname || '');
                setValue('guarantor_lastname', resData.guarantor_lastname || '');
                setValue('guarantor_nickname', resData.guarantor_nickname || '');
                setValue('guarantor_age', resData.guarantor_age || '');
                setValue('guarantor_date_of_birth', dayjs(resData.guarantor_date_of_birth) || '');
                setValue('guarantor_id_card', resData.guarantor_id_card || '');
                setValue('guarantor_id_card_exp', dayjs(resData.guarantor_id_card_exp) || '');
                setValue('guarantor_marital_status', resData.guarantor_marital_status || '');
                setValue('guarantor_address_status', resData.guarantor_address_status || '');
                setValue('guarantor_number_of_residents', resData.guarantor_number_of_residents || '');
                setValue('guarantor_number_of_children', resData.guarantor_number_of_children || '');
                setValue('children_currently_studying', resData.children_currently_studying || '');
                setValue('guarantor_phone', resData.guarantor_phone || '');
                setValue('guarantor_facebook', resData.guarantor_facebook || '');
                setValue('guarantor_line_id', resData.guarantor_line_id || '');

                setValue('guarantor_address', resData.guarantor_address || '');
                setValue('guarantor_sub_district', resData.guarantor_sub_district || '');
                setValue('guarantor_district', resData.guarantor_district || '');
                setValue('guarantor_province', resData.guarantor_province || '');
                setValue('guarantor_postcode', resData.guarantor_postcode || '');
                setValue('guarantor_period_of_residence', resData.guarantor_period_of_residence || '');

                setValue('guarantor_career_type', resData.guarantor_career_type || '');
                setValue('guarantor_career_description', resData.guarantor_career_description || '');
                setValue('guarantor_career_address', resData.guarantor_career_address || '');
                setValue('guarantor_career_sub_district', resData.guarantor_career_sub_district || '');
                setValue('guarantor_career_district', resData.guarantor_career_district || '');
                setValue('guarantor_career_province', resData.guarantor_career_province || '');
                setValue('guarantor_career_postcode', resData.guarantor_career_postcode || '');
                setValue('guarantor_career_phone', resData.guarantor_career_phone || '');
                setValue('guarantor_career_period_of_employment', resData.guarantor_career_period_of_employment || '');
                setValue('guarantor_career_income', resData.guarantor_career_income || '');
                setValue('guarantor_career_income_type', resData.guarantor_career_income_type || '');

                setValue('guarantor_spouse_first_name', resData.guarantor_spouse_first_name || '');
                setValue('guarantor_spouse_last_name', resData.guarantor_spouse_last_name || '');
                setValue('guarantor_spouse_nickname', resData.guarantor_spouse_nickname || '');
                setValue('guarantor_spouse_age', resData.guarantor_spouse_age || '');
                setValue('guarantor_spouse_phone', resData.guarantor_spouse_phone || '');
                setValue('guarantor_spouse_career', resData.guarantor_spouse_career || '');
                setValue('guarantor_spouse_career_address', resData.guarantor_spouse_career_address || '');
                setValue('guarantor_spouse_poe', resData.guarantor_spouse_poe || '');
                setValue('guarantor_spouse_income', resData.guarantor_spouse_income || '');
                setValue('guarantor_spouse_income_type', resData.guarantor_spouse_income_type || '');


                setValue('cost_per_month', resData.cost_per_month || 0);
                setValue('cost_per_day', resData.cost_per_day || 0);

                setValue('guarantor_cost_per_month', resData.guarantor_cost_per_month || 0);
                setValue('guarantor_cost_per_day', resData.guarantor_cost_per_day || 0);

                // Expenses
                setValue('customerExpensesPerMonth.living', customerExpensesPerMonth.living || 0);
                setValue('customerExpensesPerMonth.utility', customerExpensesPerMonth.utility || 0);
                setValue('customerExpensesPerMonth.house_rent', customerExpensesPerMonth.house_rent || 0);
                setValue('customerExpensesPerMonth.family_expenses', customerExpensesPerMonth.family_expenses || 0);
                setValue('customerExpensesPerMonth.house_loan', customerExpensesPerMonth.house_loan || 0);
                setValue('customerExpensesPerMonth.vehicle_loan', customerExpensesPerMonth.vehicle_loan || 0);
                setValue('customerExpensesPerMonth.lone_installment', customerExpensesPerMonth.lone_installment || 0);
                setValue('customerExpensesPerMonth.other_expenses', customerExpensesPerMonth.other_expenses || 0);

                setValue('customerExpensesPerDay.living', customerExpensesPerDay.living || 0);
                setValue('customerExpensesPerDay.utility', customerExpensesPerDay.utility || 0);
                setValue('customerExpensesPerDay.house_rent', customerExpensesPerDay.house_rent || 0);
                setValue('customerExpensesPerDay.family_expenses', customerExpensesPerDay.family_expenses || 0);
                setValue('customerExpensesPerDay.house_loan', customerExpensesPerDay.house_loan || 0);
                setValue('customerExpensesPerDay.vehicle_loan', customerExpensesPerDay.vehicle_loan || 0);
                setValue('customerExpensesPerDay.lone_installment', customerExpensesPerDay.lone_installment || 0);
                setValue('customerExpensesPerDay.other_expenses', customerExpensesPerDay.other_expenses || 0);

                setValue('guarantorExpensesPerMonth.living', guarantorExpensesPerMonth.living || 0);
                setValue('guarantorExpensesPerMonth.utility', guarantorExpensesPerMonth.utility || 0);
                setValue('guarantorExpensesPerMonth.house_rent', guarantorExpensesPerMonth.house_rent || 0);
                setValue('guarantorExpensesPerMonth.family_expenses', guarantorExpensesPerMonth.family_expenses || 0);
                setValue('guarantorExpensesPerMonth.house_loan', guarantorExpensesPerMonth.house_loan || 0);
                setValue('guarantorExpensesPerMonth.vehicle_loan', guarantorExpensesPerMonth.vehicle_loan || 0);
                setValue('guarantorExpensesPerMonth.lone_installment', guarantorExpensesPerMonth.lone_installment || 0);
                setValue('guarantorExpensesPerMonth.other_expenses', guarantorExpensesPerMonth.other_expenses || 0);

                setValue('guarantorExpensesPerDay.living', guarantorExpensesPerDay.living || 0);
                setValue('guarantorExpensesPerDay.utility', guarantorExpensesPerDay.utility || 0);
                setValue('guarantorExpensesPerDay.house_rent', guarantorExpensesPerDay.house_rent || 0);
                setValue('guarantorExpensesPerDay.family_expenses', guarantorExpensesPerDay.family_expenses || 0);
                setValue('guarantorExpensesPerDay.house_loan', guarantorExpensesPerDay.house_loan || 0);
                setValue('guarantorExpensesPerDay.vehicle_loan', guarantorExpensesPerDay.vehicle_loan || 0);
                setValue('guarantorExpensesPerDay.lone_installment', guarantorExpensesPerDay.lone_installment || 0);
                setValue('guarantorExpensesPerDay.other_expenses', guarantorExpensesPerDay.other_expenses || 0);

                setValue('product_type', resData.product_type || '');
                setProductType(resData.product_type || '');

                setValue('amount', resData.amount || 0);
                setValue('loan_period', resData.loan_period || 0);
                setValue('installment_value', resData.installment_value || 0);


                setValue('branch', resData.branch);

            } catch (error) {
                console.error('Error fetching data: ', error);
            };
        }
        fetchData();
    }, [id]);

    const validationSchema = yup.object().shape({
        branch: yup.object().shape({
            id: yup.string().required('Branch is required'),
            admin_name: yup.string().required('Branch is required'),
            admin_username: yup.string().required('Branch is required'),
            admin_role: yup.string().required('Branch is required'),
        }),
        dateTimeOfRequest: yup
            .date()
            .test('isFuture', 'Date and time cannot be in the future', value => {
                return dayjs().isAfter(dayjs(value));
            })
            .test('isValidDateRange', 'Date is not within a valid range', value => {
                const minDate = dayjs('2000-01-01');
                return dayjs(value).isAfter(minDate);
            })
            .required('กรุณาระบุวันที่'),
    });


    const { handleSubmit, control, reset, setValue, formState: { errors }, setError, clearErrors } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleFormSubmit = async (data) => { };

    const formatNumberWithCommas = (number) => {
        return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    useEffect(() => {

        if (!data.career_income || !data.cost_per_month || !data.cost_per_day) {
            return;
        }

        const calculateFinancials = ({ income, costPerMonth, costPerDay, incomeType }) => {
            // Convert input to numbers to avoid string concatenation issues
            income = parseFloat(income) || 0;
            costPerMonth = parseFloat(costPerMonth) || 0;
            costPerDay = parseFloat(costPerDay) || 0;

            // If the income type is daily, convert it to monthly by multiplying by the average number of working days
            if (incomeType === 'รายวัน') {
                income *= 30;
                costPerMonth = costPerDay * 30;
            } else {
                costPerDay = costPerMonth / 30;
            }

            let monthlyProfit = income - costPerMonth;
            let dailyProfit = monthlyProfit / 30;
            let ifsPerMonth = income > 0 ? (monthlyProfit * 100) / income : 0;
            let ifsPerDay = ifsPerMonth / 30; // Assuming 30 days in a month

            let hiddenPerMonth = monthlyProfit * 0.20;
            let hiddenPerDay = hiddenPerMonth / 30;

            let remainingPerMonth = monthlyProfit - hiddenPerMonth;
            let remainingPerDay = remainingPerMonth / 30;

            // Update state with rounded values
            setProfitPerMonth(parseFloat(monthlyProfit.toFixed(2)));
            setProfitPerDay(parseFloat(dailyProfit.toFixed(2)));
            setIFSPerMonth(parseFloat(ifsPerMonth.toFixed(2)));
            setIFSPerDay(parseFloat(ifsPerDay.toFixed(2)));
            setHiddenCostsPerMonth(parseFloat(hiddenPerMonth.toFixed(2)));
            setHiddenCostsPerDay(parseFloat(hiddenPerDay.toFixed(2)));
            setRemainingIncomePerMonth(parseFloat(remainingPerMonth.toFixed(2)));
            setRemainingIncomePerDay(parseFloat(remainingPerDay.toFixed(2)));
        };

        calculateFinancials({
            income: data.career_income,
            costPerMonth: data.cost_per_month,
            costPerDay: data.cost_per_day,
            incomeType: data.career_income_type
        });


        const calculateGuarantorFinancials = ({ income, costPerMonth, costPerDay, incomeType }) => {
            // Convert input to numbers to avoid string concatenation issues
            income = parseFloat(income) || 0;
            costPerMonth = parseFloat(costPerMonth) || 0;
            costPerDay = parseFloat(costPerDay) || 0;

            // If the income type is daily, convert it to monthly by multiplying by the average number of working days
            if (incomeType === 'รายวัน') {
                income *= 30;
                costPerMonth = costPerDay * 30;
            } else {
                costPerDay = costPerMonth / 30;
            }

            let monthlyProfit = income - costPerMonth;
            let dailyProfit = monthlyProfit / 30;
            let ifsPerMonth = income > 0 ? (monthlyProfit * 100) / income : 0;
            let ifsPerDay = ifsPerMonth / 30; // Assuming 30 days in a month

            let hiddenPerMonth = monthlyProfit * 0.20;
            let hiddenPerDay = hiddenPerMonth / 30;

            let remainingPerMonth = monthlyProfit - hiddenPerMonth;
            let remainingPerDay = remainingPerMonth / 30;

            // Update state with rounded values
            setGuarantorProfitPerMonth(parseFloat(monthlyProfit.toFixed(2)));
            setGuarantorProfitPerDay(parseFloat(dailyProfit.toFixed(2)));
            setGuarantorIFSPerMonth(parseFloat(ifsPerMonth.toFixed(2)));
            setGuarantorIFSPerDay(parseFloat(ifsPerDay.toFixed(2)));
            setGuarantorHiddenCostsPerMonth(parseFloat(hiddenPerMonth.toFixed(2)));
            setGuarantorHiddenCostsPerDay(parseFloat(hiddenPerDay.toFixed(2)));
            setGuarantorRemainingIncomePerMonth(parseFloat(remainingPerMonth.toFixed(2)));
            setGuarantorRemainingIncomePerDay(parseFloat(remainingPerDay.toFixed(2)));
        }

        calculateGuarantorFinancials({
            income: data.guarantor_career_income,
            costPerMonth: data.cost_per_month,
            costPerDay: data.cost_per_day,
            incomeType: data.guarantor_career_income_type
        });


    }, [data]);

    const expenseKeyTranslations = {
        living: 'ค่าครองชีพ',
        utility: 'ค่าสาธารณูปโภค',
        house_rent: 'ค่าเช่าบ้าน',
        family_expenses: 'ค่าใช้จ่ายครอบครัว',
        house_loan: 'ค่าผ่อนบ้านและที่ดิน',
        vehicle_loan: 'ค่าผ่อนชําระยานพาหนะ',
        lone_installment: 'ค่าผ่อนชําระเงินกู้',
        other_expenses: 'ค่าใช้จ่ายอื่นๆ'
    };

    const handleExpenseChange = (name, type, value) => {
        const newExpenses = { ...expenses };
        newExpenses[type][name] = value;
        setExpenses(newExpenses);
    };

    useEffect(() => {
        if (!expenses.customerExpensesPerMonth || !expenses.guarantorExpensesPerMonth) {
            return;
        }

        const calculateTotalExpenses = () => {
            let totalCustomerExpenses = 0;
            let totalGuarantorExpenses = 0;

            for (const key in expenses.customerExpensesPerMonth) {
                if (key === 'id' || key === 'expenses_type' || key === 'purchase_request_id') {
                    continue;
                }
                totalCustomerExpenses += parseFloat(expenses.customerExpensesPerMonth[key]) || 0;
            }

            for (const key in expenses.guarantorExpensesPerMonth) {
                totalGuarantorExpenses += parseFloat(expenses.guarantorExpensesPerMonth[key]) || 0;
            }

            setTotalExpenses({
                perMonth: totalCustomerExpenses,
                perDay: totalCustomerExpenses / 30
            });
        }

        calculateTotalExpenses();

        const calculateNetRemainingIncome = () => {

            setNetRemainingIncome({
                perMonth: remainingIncomePerMonth - totalExpenses.perMonth,
                perDay: remainingIncomePerDay - totalExpenses.perDay
            });
        }

        calculateNetRemainingIncome();

        const calculateGuarantorTotalExpenses = () => {
            let totalCustomerExpenses = 0;
            let totalGuarantorExpenses = 0;

            for (const key in expenses.customerExpensesPerMonth) {
                if (key === 'id' || key === 'expenses_type' || key === 'purchase_request_id') {
                    continue;
                }
                totalCustomerExpenses += parseFloat(expenses.customerExpensesPerMonth[key]) || 0;
            }

            for (const key in expenses.guarantorExpensesPerMonth) {
                totalGuarantorExpenses += parseFloat(expenses.guarantorExpensesPerMonth[key]) || 0;
            }

            setGuarantorTotalExpenses({
                perMonth: totalGuarantorExpenses,
                perDay: totalGuarantorExpenses / 30
            });
        }

        calculateGuarantorTotalExpenses();

        const calculateGuarantorNetRemainingIncome = () => {

            setGuarantorNetRemainingIncome({
                perMonth: guarantorRemainingIncomePerMonth - guarantorTotalExpenses.perMonth,
                perDay: guarantorRemainingIncomePerDay - guarantorTotalExpenses.perDay
            });
        }

        calculateGuarantorNetRemainingIncome();

    }, [expenses, remainingIncomePerMonth, remainingIncomePerDay, totalExpenses, guarantorRemainingIncomePerMonth, guarantorRemainingIncomePerDay, guarantorTotalExpenses]);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>


            <div className='flex justify-between w-full mb-8'>
                <h1 className='text-base lg:text-2xl font-bold'>
                    <Link to='/purchase-request'> <ArrowBackOutlinedIcon /></Link>
                    คำขอใช้บริการ {data?.service_request_id}
                </h1>
            </div>
            <form ref={formRef} onSubmit={handleSubmit(handleFormSubmit)}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                    <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 items-start'>
                        <div className='col-span-3 max-w-[750px] mx-auto'>
                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลผู้ซื้อสินค้า</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อ"
                                                    error={!!errors.first_name}
                                                    helperText={errors.first_name?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="นามสกุล"
                                                    error={!!errors.last_name}
                                                    helperText={errors.last_name?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="nickname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อเล่น"
                                                    error={!!errors.nickname}
                                                    helperText={errors.nickname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="customer_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุ"
                                                    error={!!errors.customer_age}
                                                    helperText={errors.customer_age?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="date_of_birth"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    label="วันเกิด"
                                                    error={!!errors.date_of_birth}
                                                    helperText={errors.date_of_birth?.message}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* บัตรประจําตัวเลขที */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="customer_id_card"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="บัตรประจําตัวเลขที่"
                                                    error={!!errors.customer_id_card}
                                                    helperText={errors.customer_id_card?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        // allow only numbers, and max length of 13
                                                        const value = e.target.value.replace(/\D/g, '').slice(0, 13);
                                                        field.onChange(value);
                                                        // If the value is not 13 characters long, show an error
                                                        if (value.length !== 13) {
                                                            setError('customer_id_card', {
                                                                type: 'manual',
                                                                message: 'กรุณากรอกเลขบัตรประจำตัว 13 หลัก'
                                                            });
                                                        } else {
                                                            clearErrors('customer_id_card');
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* วันที่บัตรหมดอายุ. */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="date_of_expiry"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    label="วันที่บัตรหมดอายุ"
                                                    error={!!errors.date_of_expiry}
                                                    helperText={errors.date_of_expiry?.message}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* สถานภาพ Radio: โสด สมรส หย่าร้าง */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">สถานภาพ</FormLabel>
                                            <Controller
                                                name="marital_status"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="โสด" control={<Radio />} label="โสด" />
                                                        <FormControlLabel value="สมรส" control={<Radio />} label="สมรส" />
                                                        <FormControlLabel value="หย่าร้าง" control={<Radio />} label="หย่าร้าง" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.marital_status?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {/* สถานภาพที่อยู่ Radio: เจ้าของบ้าน บ้านญาติ บ้านเช่า  */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">สถานภาพที่อยู่</FormLabel>
                                            <Controller
                                                name="address_status"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="เจ้าของบ้าน" control={<Radio />} label="เจ้าของบ้าน" />
                                                        <FormControlLabel value="บ้านญาติ" control={<Radio />} label="บ้านญาติ" />
                                                        <FormControlLabel value="บ้านเช่า" control={<Radio />} label="บ้านเช่า" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.address_status?.message}</FormHelperText>
                                        </FormControl>
                                    </div>


                                    {/* จํานวนผู้อยู่อาศัย InputAdornment: คน */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="num_of_residents"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จำนวนผู้อยู่อาศัย"
                                                    error={!!errors.num_of_residents}
                                                    helperText={errors.num_of_residents?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">คน</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จํานวนบุตร InputAdornment: คน */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="num_of_children"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จำนวนบุตร"
                                                    error={!!errors.num_of_children}
                                                    helperText={errors.num_of_children?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">คน</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* บุตรที่กําลังศึกษา InputAdornment: คน */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="children_studying"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="บุตรที่กําลังศึกษา"
                                                    error={!!errors.children_studying}
                                                    helperText={errors.children_studying?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">คน</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>


                                    {/* โทรศัพท์ */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="phone_number"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.phone_number}
                                                    helperText={errors.phone_number?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>


                                    {/* Facebook */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="facebook"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Facebook"
                                                    error={!!errors.facebook}
                                                    helperText={errors.facebook?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* LINE */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="line_id"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="LINE"
                                                    error={!!errors.line_id}
                                                    helperText={errors.line_id?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ที่อยู่ปัจจุบัน</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* เลขที่: customer_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="customer_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ที่อยู่"
                                                    error={!!errors.customer_address}
                                                    helperText={errors.customer_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.sub_district}
                                                    helperText={errors.sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.district}
                                                    helperText={errors.district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.province}
                                                    helperText={errors.province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.postcode}
                                                    helperText={errors.postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ระยะเวลาอยู่อาศัย: period_of_residence, Dropdown: ปี*/}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="period_of_residence"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ระยะเวลาอยู่อาศัย"
                                                    error={!!errors.period_of_residence}
                                                    helperText={errors.period_of_residence?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>


                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ข้อมูลการประกอบอาชีพ</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* career_type, Radio: บริษัทเอกชน รับราชการ รัฐวิสาหกิจ ธุรกิจส่วนตัว รับจ้าง  */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">ประเภทอาชีพ</FormLabel>
                                            <Controller
                                                name="career_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="บริษัทเอกชน" control={<Radio />} label="บริษัทเอกชน" />
                                                        <FormControlLabel value="รับราชการ" control={<Radio />} label="รับราชการ" />
                                                        <FormControlLabel value="รัฐวิสาหกิจ" control={<Radio />} label="รัฐวิสาหกิจ" />
                                                        <FormControlLabel value="ธุรกิจส่วนตัว" control={<Radio />} label="ธุรกิจส่วนตัว" />
                                                        <FormControlLabel value="รับจ้าง" control={<Radio />} label="รับจ้าง" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.career_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {/* ลักษณะงาน: career_description */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="career_description"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ลักษณะงาน"
                                                    error={!!errors.career_description}
                                                    helperText={errors.career_description?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* สถานที่ทำงาน: career_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="career_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="สถานที่ทำงาน"
                                                    error={!!errors.career_address}
                                                    helperText={errors.career_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: career_sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="career_sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.career_sub_district}
                                                    helperText={errors.career_sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: career_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="career_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.career_district}
                                                    helperText={errors.career_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: career_province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="career_province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.career_province}
                                                    helperText={errors.career_province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: career_postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="career_postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.career_postcode}
                                                    helperText={errors.career_postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์ที่ทำงาน: career_phone */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="career_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์ที่ทำงาน"
                                                    error={!!errors.career_phone}
                                                    helperText={errors.career_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุงาน: period_of_employment */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="period_of_employment"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุงาน"
                                                    error={!!errors.period_of_employment}
                                                    helperText={errors.period_of_employment?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รายได้: career_income, InputAdornment: บาท */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="career_income"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รายได้"
                                                    error={!!errors.career_income}
                                                    helperText={errors.career_income?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รูปแบบรายได้: career_income_type, Radio: รายวัน รายเดือน */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">รูปแบบรายได้</FormLabel>
                                            <Controller
                                                name="career_income_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="รายวัน" control={<Radio />} label="รายวัน" />
                                                        <FormControlLabel value="รายเดือน" control={<Radio />} label="รายเดือน" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.career_income_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ข้อมูลคู่สมรส</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* ชื่อคู่สมรส: spouse_first_name */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_first_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อคู่สมรส"
                                                    error={!!errors.spouse_first_name}
                                                    helperText={errors.spouse_first_name?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* นามสกุลคู่สมรส: spouse_last_name */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_last_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="นามสกุลคู่สมรส"
                                                    error={!!errors.spouse_last_name}
                                                    helperText={errors.spouse_last_name?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ชื่อเล่น: spouse_nickname */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="spouse_nickname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อเล่น"
                                                    error={!!errors.spouse_nickname}
                                                    helperText={errors.spouse_nickname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุ: spouse_age */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="spouse_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุ"
                                                    error={!!errors.spouse_age}
                                                    helperText={errors.spouse_age?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์: spouse_phone */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="spouse_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.spouse_phone}
                                                    helperText={errors.spouse_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ลักษณะงาน: spouse_career */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="spouse_career"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ลักษณะงาน"
                                                    error={!!errors.spouse_career}
                                                    helperText={errors.spouse_career?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/*สถานที่ทำงาน: spouse_career_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="spouse_career_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="สถานที่ทำงาน"
                                                    error={!!errors.spouse_career_address}
                                                    helperText={errors.spouse_career_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: spouse_career_sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.spouse_career_sub_district}
                                                    helperText={errors.spouse_career_sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: spouse_career_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.spouse_career_district}
                                                    helperText={errors.spouse_career_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: spouse_career_province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.spouse_career_province}
                                                    helperText={errors.spouse_career_province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: spouse_career_postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.spouse_career_postcode}
                                                    helperText={errors.spouse_career_postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุงาน: spouse_career_period_of_employment */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_period_of_employment"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุงาน"
                                                    error={!!errors.spouse_career_period_of_employment}
                                                    helperText={errors.spouse_career_period_of_employment?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รายได้: spouse_career_income, InputAdornment: บาท */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="spouse_career_income"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รายได้"
                                                    error={!!errors.spouse_career_income}
                                                    helperText={errors.spouse_career_income?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รูปแบบรายได้: spouse_career_income_type, Radio: รายวัน รายเดือน */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">รูปแบบรายได้</FormLabel>
                                            <Controller
                                                name="spouse_career_income_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="รายวัน" control={<Radio />} label="รายวัน" />
                                                        <FormControlLabel value="รายเดือน" control={<Radio />} label="รายเดือน" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.spouse_career_income_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                </div>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลบุคคลที่สามารถติดต่อได้กรณีฉุกเฉิน</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* ชื่อ: emergency_firstname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_firstname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อ"
                                                    error={!!errors.emergency_firstname}
                                                    helperText={errors.emergency_firstname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* นามสกุล: emergency_lastname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_lastname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="นามสกุล"
                                                    error={!!errors.emergency_lastname}
                                                    helperText={errors.emergency_lastname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ชื่อเล่น: emergency_nickname */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="emergency_nickname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อเล่น"
                                                    error={!!errors.emergency_nickname}
                                                    helperText={errors.emergency_nickname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุ: emergency_age, Dropment: ปี */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="emergency_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุ"
                                                    error={!!errors.emergency_age}
                                                    helperText={errors.emergency_age?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ความสัมพันธ์: emergency_relationship */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="emergency_relationship"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ความสัมพันธ์"
                                                    error={!!errors.emergency_relationship}
                                                    helperText={errors.emergency_relationship?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>


                                    {/* ที่อยู่: emergency_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="emergency_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ที่อยู่"
                                                    error={!!errors.emergency_address}
                                                    helperText={errors.emergency_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: emergency_sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.emergency_sub_district}
                                                    helperText={errors.emergency_sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: emergency_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.emergency_district}
                                                    helperText={errors.emergency_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: emergency_province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.emergency_province}
                                                    helperText={errors.emergency_province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: emergency_postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="emergency_postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.emergency_postcode}
                                                    helperText={errors.emergency_postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์: emergency_phone */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="emergency_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.emergency_phone}
                                                    helperText={errors.emergency_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลผู้ค้ำประกัน</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* ชื่อ: guarantor_firstname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_firstname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อ"
                                                    error={!!errors.guarantor_firstname}
                                                    helperText={errors.guarantor_firstname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* นามสกุล: guarantor_lastname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_lastname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="นามสกุล"
                                                    error={!!errors.guarantor_lastname}
                                                    helperText={errors.guarantor_lastname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ชื่อเล่น: guarantor_nickname */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_nickname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อเล่น"
                                                    error={!!errors.guarantor_nickname}
                                                    helperText={errors.guarantor_nickname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุ: guarantor_age, Dropment: ปี */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุ"
                                                    error={!!errors.guarantor_age}
                                                    helperText={errors.guarantor_age?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* วัน/เดือน/ปี เกิด: guarantor_date_of_birth, DatePicker */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_date_of_birth"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    label="วัน/เดือน/ปี เกิด"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* เลขที่บัตรประชาชน: guarantor_id_card */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_id_card"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="เลขที่บัตรประชาชน"
                                                    error={!!errors.guarantor_id_card}
                                                    helperText={errors.guarantor_id_card?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 13,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* วันที่หมดอาย: guarantor_id_card_exp */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_id_card_exp"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    label="วันที่หมดอายุ"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* สถานภาพ: guarantor_marital_status, Radio: โสด สมรส หย่าร้าง */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">สถานภาพ</FormLabel>
                                            <Controller
                                                name="guarantor_marital_status"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="โสด" control={<Radio />} label="โสด" />
                                                        <FormControlLabel value="สมรส" control={<Radio />} label="สมรส" />
                                                        <FormControlLabel value="หย่าร้าง" control={<Radio />} label="หย่าร้าง" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.guarantor_marital_status?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {/* สถานภาพที่อยู่ : guarantor_address_status, Radio: เจ้าของบ้าน บ้านญาติ บ้านเช่า */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">สถานภาพที่อยู่</FormLabel>
                                            <Controller
                                                name="guarantor_address_status"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="เจ้าของบ้าน" control={<Radio />} label="เจ้าของบ้าน" />
                                                        <FormControlLabel value="บ้านญาติ" control={<Radio />} label="บ้านญาติ" />
                                                        <FormControlLabel value="บ้านเช่า" control={<Radio />} label="บ้านเช่า" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.guarantor_address_status?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {/* จํานวนผู้อยู่อาศัย: guarantor_number_of_residents, Dropment: คน */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_number_of_residents"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จํานวนผู้อยู่อาศัย"
                                                    error={!!errors.guarantor_number_of_residents}
                                                    helperText={errors.guarantor_number_of_residents?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric",
                                                    }}

                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จํานวนบุตร: guarantor_number_of_children */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_number_of_children"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จํานวนบุตร"
                                                    error={!!errors.guarantor_number_of_children}
                                                    helperText={errors.guarantor_number_of_children?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric",
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* บุตรที่กําลังศึกษา: children_currently_studying */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="children_currently_studying"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="บุตรที่กําลังศึกษา"
                                                    error={!!errors.children_currently_studying}
                                                    helperText={errors.children_currently_studying?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric",
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์: guarantor_phone */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.guarantor_phone}
                                                    helperText={errors.guarantor_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* Facebook: guarantor_facebook */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_facebook"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Facebook"
                                                    error={!!errors.guarantor_facebook}
                                                    helperText={errors.guarantor_facebook?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* Line: guarantor_line_id */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_line_id"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Line"
                                                    error={!!errors.guarantor_line_id}
                                                    helperText={errors.guarantor_line_id?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ที่อยู่ปัจจุบัน</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* ที่อยู่: guarantor_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ที่อยู่"
                                                    error={!!errors.guarantor_address}
                                                    helperText={errors.guarantor_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: guarantor_sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.guarantor_sub_district}
                                                    helperText={errors.guarantor_sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: guarantor_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.guarantor_district}
                                                    helperText={errors.guarantor_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: guarantor_province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.guarantor_province}
                                                    helperText={errors.guarantor_province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: guarantor_postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.guarantor_postcode}
                                                    helperText={errors.guarantor_postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ระยะเวลาอยู่อาศัย: guarantor_period_of_residence */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_period_of_residence"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ระยะเวลาอยู่อาศัย"
                                                    error={!!errors.guarantor_period_of_residence}
                                                    helperText={errors.guarantor_period_of_residence?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ข้อมูลการประกอบอาชีพ</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    { /* guarantor_career_type, Radio: บริษัทเอกชน รับราชการ รัฐวิสาหกิจ ธุรกิจส่วนตัว รับจ้าง  */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">ประเภทอาชีพ</FormLabel>
                                            <Controller
                                                name="guarantor_career_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="บริษัทเอกชน" control={<Radio />} label="บริษัทเอกชน" />
                                                        <FormControlLabel value="รับราชการ" control={<Radio />} label="รับราชการ" />
                                                        <FormControlLabel value="รัฐวิสาหกิจ" control={<Radio />} label="รัฐวิสาหกิจ" />
                                                        <FormControlLabel value="ธุรกิจส่วนตัว" control={<Radio />} label="ธุรกิจส่วนตัว" />
                                                        <FormControlLabel value="รับจ้าง" control={<Radio />} label="รับจ้าง" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.guarantor_career_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {/* ลักษณะงาน: guarantor_career_description */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_career_description"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ลักษณะงาน"
                                                    error={!!errors.guarantor_career_description}
                                                    helperText={errors.guarantor_career_description?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* สถานที่ทำงาน: guarantor_career_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_career_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="สถานที่ทำงาน"
                                                    error={!!errors.guarantor_career_address}
                                                    helperText={errors.guarantor_career_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ตําบล/แขวง: guarantor_career_sub_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_sub_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ตําบล/แขวง"
                                                    error={!!errors.guarantor_career_sub_district}
                                                    helperText={errors.guarantor_career_sub_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อําเภอ/เขต: guarantor_career_district */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_district"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อําเภอ/เขต"
                                                    error={!!errors.guarantor_career_district}
                                                    helperText={errors.guarantor_career_district?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* จังหวัด: guarantor_career_province */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_province"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="จังหวัด"
                                                    error={!!errors.guarantor_career_province}
                                                    helperText={errors.guarantor_career_province?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รหัสไปรษณีย์: guarantor_career_postcode */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_postcode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รหัสไปรษณีย์"
                                                    error={!!errors.guarantor_career_postcode}
                                                    helperText={errors.guarantor_career_postcode?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์: guarantor_career_phone */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.guarantor_career_phone}
                                                    helperText={errors.guarantor_career_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุงาน: guarantor_career_period_of_employment */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_career_period_of_employment"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุงาน"
                                                    error={!!errors.guarantor_career_period_of_employment}
                                                    helperText={errors.guarantor_career_period_of_employment?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รายได้: guarantor_career_income, InputAdornment: บาท */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_career_income"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รายได้"
                                                    error={!!errors.guarantor_career_income}
                                                    helperText={errors.guarantor_career_income?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รูปแบบรายได้: guarantor_career_income_type, Radio: รายวัน รายเดือน */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">รูปแบบรายได้</FormLabel>
                                            <Controller
                                                name="guarantor_career_income_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="รายวัน" control={<Radio />} label="รายวัน" />
                                                        <FormControlLabel value="รายเดือน" control={<Radio />} label="รายเดือน" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.guarantor_career_income_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                </div>

                                <h2 className='text-lg font-semibold mb-4 mt-8'>ข้อมูลค่สมรสผู้ค้ำประกัน</h2>
                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>

                                    {/* ชื่อ: guarantor_spouse_firstname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_spouse_firstname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อ"
                                                    error={!!errors.guarantor_spouse_firstname}
                                                    helperText={errors.guarantor_spouse_firstname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* นามสกุล: guarantor_spouse_lastname */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_spouse_lastname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="นามสกุล"
                                                    error={!!errors.guarantor_spouse_lastname}
                                                    helperText={errors.guarantor_spouse_lastname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* ชื่อเล่น: guarantor_spouse_nickname */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_spouse_nickname"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="ชื่อเล่น"
                                                    error={!!errors.guarantor_spouse_nickname}
                                                    helperText={errors.guarantor_spouse_nickname?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุ: guarantor_spouse_age, Dropment: ปี */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_spouse_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุ"
                                                    error={!!errors.guarantor_spouse_age}
                                                    helperText={errors.guarantor_spouse_age?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    inputProps={{
                                                        maxLength: 2,
                                                        pattern: "\\d*",
                                                        inputMode: "numeric"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* โทรศัพท์: guarantor_spouse_phone */}
                                    <div className='col-span-12 xl:col-span-4'>
                                        <Controller
                                            name="guarantor_spouse_phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="โทรศัพท์"
                                                    error={!!errors.guarantor_spouse_phone}
                                                    helperText={errors.guarantor_spouse_phone?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อาชีพ: guarantor_spouse_career */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_spouse_career"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อาชีพ"
                                                    error={!!errors.guarantor_spouse_career}
                                                    helperText={errors.guarantor_spouse_career?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* สถานที่ทำงาน: guarantor_spouse_cereer_address */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <Controller
                                            name="guarantor_spouse_cereer_address"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="สถานที่ทำงาน"
                                                    error={!!errors.guarantor_spouse_cereer_address}
                                                    helperText={errors.guarantor_spouse_cereer_address?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* อายุงาน: guarantor_spouse_poe */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_spouse_poe"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="อายุงาน"
                                                    error={!!errors.guarantor_spouse_poe}
                                                    helperText={errors.guarantor_spouse_poe?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รายได้: guarantor_spouse_income, InputAdornment: บาท */}
                                    <div className='col-span-12 xl:col-span-6'>
                                        <Controller
                                            name="guarantor_spouse_income"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="รายได้"
                                                    error={!!errors.guarantor_spouse_income}
                                                    helperText={errors.guarantor_spouse_income?.message}
                                                    fullWidth
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    {/* รูปแบบรายได้: guarantor_spouse_income_type, Radio: รายวัน รายเดือน */}
                                    <div className='col-span-12 xl:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">รูปแบบรายได้</FormLabel>
                                            <Controller
                                                name="guarantor_spouse_income_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="รายวัน" control={<Radio />} label="รายวัน" />
                                                        <FormControlLabel value="รายเดือน" control={<Radio />} label="รายเดือน" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.guarantor_spouse_income_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                </div>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลรายละเอียดรายได้และต้นทุน ของผู้กู้</h2>
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className='border-b border-gray-300 p-2 text-left'>รายละเอียด</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อเดือน</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อวัน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>รายได้</td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.career_income_type === 'รายเดือน' ?
                                                    <p className='font-noto text-right'>
                                                        {data.career_income ? formatNumberWithCommas(parseFloat(data.career_income)) : 0}
                                                    </p>
                                                    : <p className='font-noto text-right'>
                                                        {data.career_income ? formatNumberWithCommas((parseFloat(data.career_income) * 30).toFixed(2)) : 0}
                                                    </p>
                                                }
                                            </td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.career_income_type === 'รายวัน' ?
                                                    <p className='font-noto text-right'>
                                                        {data.career_income ? formatNumberWithCommas(parseFloat(data.career_income)) : 0}
                                                    </p>
                                                    : <p className='font-noto text-right'>
                                                        {data.career_income ? formatNumberWithCommas((parseFloat(data.career_income) / 30).toFixed(2)) : 0}
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>ต้นทุน</td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.career_income_type === 'รายเดือน' ?
                                                    <div className='max-w-xs mr-0 ml-auto'>
                                                        <Controller
                                                            name="cost_per_month"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="standard"
                                                                    //label="ต้นทุน"
                                                                    error={!!errors.cost_per_month}
                                                                    helperText={errors.cost_per_month?.message}
                                                                    fullWidth
                                                                    value={field.value || ''}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                            field.onChange(e.target.value);
                                                                            setData({
                                                                                ...data,
                                                                                cost_per_day: (parseFloat(e.target.value) / 30).toFixed(2),
                                                                                cost_per_month: e.target.value
                                                                            });
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        min: 0,
                                                                    }}
                                                                    sx={{
                                                                        '& input': { textAlign: 'right' }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    : <p className='font-noto text-right'>
                                                        {data.cost_per_day ? formatNumberWithCommas((parseFloat(data.cost_per_day) * 30).toFixed(2)) : 0.00}
                                                    </p>
                                                }
                                            </td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.career_income_type === 'รายวัน' ?
                                                    <div className='max-w-[100px] mr-0 ml-auto'>
                                                        <Controller
                                                            name="cost_per_day"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="standard"
                                                                    //label="ต้นทุน"
                                                                    error={!!errors.cost_per_day}
                                                                    helperText={errors.cost_per_day?.message}
                                                                    fullWidth
                                                                    value={field.value || ''}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                            field.onChange(e.target.value);
                                                                            setData({
                                                                                ...data,
                                                                                cost_per_month: (parseFloat(e.target.value) * 30).toFixed(2),
                                                                                cost_per_day: e.target.value
                                                                            });
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        min: 0,
                                                                    }}
                                                                    sx={{
                                                                        '& input': { textAlign: 'right' }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    : <p className='font-noto text-right'>
                                                        {data.cost_per_month ? formatNumberWithCommas((parseFloat(data.cost_per_month) / 30).toFixed(2)) : 0.00}
                                                    </p>
                                                }

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>กำไรขั้นต้น</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(profitPerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(profitPerDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>IFS</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(IFSPerMonth)}%</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(IFSPerDay)}%</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>ค่าใช้จ่ายแฝง</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(hiddenCostsPerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(hiddenCostsPerDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>รายได้คงเหลือ</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(remainingIncomePerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(remainingIncomePerDay)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ค่าใช้จ่ายหลักต่อเดือน ของผู้กู้</h2>
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className='border-b border-gray-300 p-2 text-left'>รายละเอียด</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อเดือน</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อวัน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(expenses.customerExpensesPerMonth).map(([key, value]) => (
                                            key !== 'id' && key !== 'expenses_type' && key !== 'purchase_request_id' &&
                                            <tr key={key}>
                                                <td className='border-b border-gray-300 p-2'>
                                                    {expenseKeyTranslations[key]}
                                                </td>
                                                <td className='border-b border-gray-300 p-2'>
                                                    <Controller
                                                        name={`customerExpensesPerMonth.${key}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                variant="standard"
                                                                //label="ต้นทุน"
                                                                error={!!errors.customerExpensesPerMonth?.[key]}
                                                                helperText={errors.customerExpensesPerMonth?.[key]?.message}
                                                                fullWidth
                                                                value={field.value || ''}
                                                                onChange={(e) => {
                                                                    if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                        field.onChange(e.target.value);
                                                                        handleExpenseChange(key, 'customerExpensesPerMonth', e.target.value);
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    min: 0,
                                                                }}
                                                                sx={{
                                                                    '& input': { textAlign: 'right' }
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </td>
                                                <td className='border-b border-gray-300 p-2'>
                                                    <p className='font-noto text-right'>
                                                        {value ? formatNumberWithCommas((parseFloat(value) / 30).toFixed(2)) : 0}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>ค่าใช้จ่ายทั้งหมด</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(totalExpenses.perMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(totalExpenses.perDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>รายได้คงเหลือสุทธิ</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(netRemainingIncome.perMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(netRemainingIncome.perDay)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลรายละเอียดรายได้และต้นทุน ของผู้ค้ำประกัน</h2>
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className='border-b border-gray-300 p-2 text-left'>รายละเอียด</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อเดือน</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อวัน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>รายได้</td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.guarantor_career_income_type === 'รายเดือน' ?
                                                    <p className='font-noto text-right'>
                                                        {data.guarantor_career_income ? formatNumberWithCommas(parseFloat(data.guarantor_career_income)) : 0}
                                                    </p>
                                                    : <p className='font-noto text-right'>
                                                        {data.career_income ? formatNumberWithCommas((parseFloat(data.guarantor_career_income) * 30).toFixed(2)) : 0}
                                                    </p>
                                                }
                                            </td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.guarantor_career_income_type === 'รายวัน' ?
                                                    <p className='font-noto text-right'>
                                                        {data.guarantor_career_income ? formatNumberWithCommas(parseFloat(data.guarantor_career_income)) : 0}
                                                    </p>
                                                    : <p className='font-noto text-right'>
                                                        {data.guarantor_career_income ? formatNumberWithCommas((parseFloat(data.guarantor_career_income) / 30).toFixed(2)) : 0}
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>ต้นทุน</td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.guarantor_career_income_type === 'รายเดือน' ?
                                                    <div className='max-w-xs mr-0 ml-auto'>
                                                        <Controller
                                                            name="guarantor_cost_per_month"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="standard"
                                                                    //label="ต้นทุน"
                                                                    error={!!errors.guarantor_cost_per_month}
                                                                    helperText={errors.guarantor_cost_per_month?.message}
                                                                    fullWidth
                                                                    value={field.value || ''}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                            field.onChange(e.target.value);
                                                                            setData({
                                                                                ...data,
                                                                                guarantor_cost_per_day: (parseFloat(e.target.value) / 30).toFixed(2),
                                                                                guarantor_cost_per_month: e.target.value
                                                                            });
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        min: 0,
                                                                    }}
                                                                    sx={{
                                                                        '& input': { textAlign: 'right' }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    : <p className='font-noto text-right'>
                                                        {data.guarantor_cost_per_day ? formatNumberWithCommas((parseFloat(data.guarantor_cost_per_day) * 30).toFixed(2)) : 0.00}
                                                    </p>
                                                }
                                            </td>
                                            <td className='border-b border-gray-300 p-2'>
                                                {data.guarantor_career_income_type === 'รายวัน' ?
                                                    <div className='max-w-[100px] mr-0 ml-auto'>
                                                        <Controller
                                                            name="guarantor_cost_per_day"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="standard"
                                                                    //label="ต้นทุน"
                                                                    error={!!errors.guarantor_cost_per_day}
                                                                    helperText={errors.guarantor_cost_per_day?.message}
                                                                    fullWidth
                                                                    value={field.value || ''}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                            field.onChange(e.target.value);
                                                                            setData({
                                                                                ...data,
                                                                                guarantor_cost_per_month: (parseFloat(e.target.value) * 30).toFixed(2),
                                                                                guarantor_cost_per_day: e.target.value
                                                                            });
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        min: 0,
                                                                    }}
                                                                    sx={{
                                                                        '& input': { textAlign: 'right' }
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    : <p className='font-noto text-right'>
                                                        {data.guarantor_cost_per_month ? formatNumberWithCommas((parseFloat(data.guarantor_cost_per_month) / 30).toFixed(2)) : 0.00}
                                                    </p>
                                                }

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>กำไรขั้นต้น</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorProfitPerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorProfitPerDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>IFS</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorIFSPerMonth)}%</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorIFSPerDay)}%</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2'>ค่าใช้จ่ายแฝง</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorHiddenCostsPerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right'>{formatNumberWithCommas(guarantorHiddenCostsPerDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>รายได้คงเหลือ</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorRemainingIncomePerMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorRemainingIncomePerDay)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ค่าใช้จ่ายหลักต่อเดือน ของผู้ค้ำประกัน</h2>
                                <table className='w-full'>
                                    <thead>
                                        <tr>
                                            <th className='border-b border-gray-300 p-2 text-left'>รายละเอียด</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อเดือน</th>
                                            <th className='border-b border-gray-300 p-2 text-right'>ต่อวัน</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(expenses.guarantorExpensesPerMonth).map(([key, value]) => (
                                            key !== 'id' && key !== 'expenses_type' && key !== 'purchase_request_id' &&
                                            <tr key={key}>
                                                <td className='border-b border-gray-300 p-2'>
                                                    {expenseKeyTranslations[key]}
                                                </td>
                                                <td className='border-b border-gray-300 p-2'>
                                                    <Controller
                                                        name={`guarantorExpensesPerMonth.${key}`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                variant="standard"
                                                                //label="ต้นทุน"
                                                                error={!!errors.guarantorExpensesPerMonth?.[key]}
                                                                helperText={errors.guarantorExpensesPerMonth?.[key]?.message}
                                                                fullWidth
                                                                value={field.value || ''}
                                                                onChange={(e) => {
                                                                    if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                        field.onChange(e.target.value);
                                                                        handleExpenseChange(key, 'guarantorExpensesPerMonth', e.target.value);
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    min: 0,
                                                                }}
                                                                sx={{
                                                                    '& input': { textAlign: 'right' }
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                </td>
                                                <td className='border-b border-gray-300 p-2'>
                                                    <p className='font-noto text-right'>
                                                        {value ? formatNumberWithCommas((parseFloat(value) / 30).toFixed(2)) : 0}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>ค่าใช้จ่ายทั้งหมด</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorTotalExpenses.perMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorTotalExpenses.perDay)}</td>
                                        </tr>
                                        <tr>
                                            <td className='border-b border-gray-300 p-2 font-bold'>รายได้คงเหลือสุทธิ</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorNetRemainingIncome.perMonth)}</td>
                                            <td className='border-b border-gray-300 p-2 text-right font-bold'>{formatNumberWithCommas(guarantorNetRemainingIncome.perDay)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='p-8 bg-white mb-4'>
                                <h2 className='text-lg font-semibold mb-4'>ข้อมูลรายละเอียดขอซื้อสินค้า</h2>

                                <div className='grid grid-cols-1 lg:grid-cols-12 gap-x-4 gap-y-6'>
                                    {/* ประเภทสินค้า: product_type, Radio: ['ทองคํารูปพรรณ', 'เครื่องใช้ไฟฟ้า', 'สินเชื่อ']; */}
                                    <div className='col-span-12 lg:col-span-12'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">ประเภทสินค้า</FormLabel>
                                            <Controller
                                                name="product_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        row
                                                        value={field.value || ''}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                            setProductType(e.target.value);
                                                        }}
                                                    >
                                                        <FormControlLabel value="ทองคํารูปพรรณ" control={<Radio />} label="ทองคํารูปพรรณ" />
                                                        <FormControlLabel value="เครื่องใช้ไฟฟ้า" control={<Radio />} label="เครื่องใช้ไฟฟ้า" />
                                                        <FormControlLabel value="สินเชื่อ" control={<Radio />} label="สินเชื่อ" />
                                                    </RadioGroup>
                                                )}
                                            />
                                            <FormHelperText>{errors.product_type?.message}</FormHelperText>
                                        </FormControl>
                                    </div>

                                    {productType === 'ทองคํารูปพรรณ' &&
                                        <>
                                            <div className='col-span-12 lg:col-span-6'>
                                                <Controller
                                                    name="pattern"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="ลาย"
                                                            error={!!errors.pattern}
                                                            helperText={errors.pattern?.message}
                                                            fullWidth
                                                            value={field.value || ''}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {/* weight */}
                                            <div className='col-span-12 lg:col-span-6'>
                                                <Controller
                                                    name="gold_weight"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="น้ำหนัก (บาท)"
                                                            error={!!errors.gold_weight}
                                                            helperText={errors.gold_weight?.message}
                                                            fullWidth
                                                            value={field.value || ''}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {/* gram */}
                                            <div className='col-span-12 lg:col-span-6'>
                                                <Controller
                                                    name="gram"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="จำนวนกรัม"
                                                            error={!!errors.gram}
                                                            helperText={errors.gram?.message}
                                                            fullWidth
                                                            value={field.value || ''}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    }

                                    {productType === 'เครื่องใช้ไฟฟ้า' &&
                                        <>
                                        </>
                                    }

                                    {productType === 'สินเชื่อ' &&
                                        <>
                                            {/* จำนวนเงินอนุมัติ: amount */}
                                            <div className='col-span-12 lg:col-span-12'>
                                                <Controller
                                                    name="amount"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="จำนวนเงินอนุมัติ"
                                                            error={!!errors.amount}
                                                            helperText={errors.amount?.message}
                                                            fullWidth
                                                            value={field.value || ''}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {/* รูปแบบการแบ่งชำระ: period, Radio: ['รายเดือน', 'รายวัน', 'รายสัปดาห์'] */}
                                            <div className='col-span-12 lg:col-span-12'>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">รูปแบบการแบ่งชำระ</FormLabel>
                                                    <Controller
                                                        name="loan_period"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                row
                                                                value={field.value || ''}
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="รายเดือน" control={<Radio />} label="รายเดือน" />
                                                                <FormControlLabel value="รายวัน" control={<Radio />} label="รายวัน" />
                                                                <FormControlLabel value="รายสัปดาห์" control={<Radio />} label="รายสัปดาห์" />
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                    <FormHelperText>{errors.loan_period?.message}</FormHelperText>
                                                </FormControl>
                                            </div>

                                            {/* ชำระงวดละ: installment_value, InputAdornment: บาท, Accepts: ตัวเลขเท่านั้น */}
                                            <div className='col-span-12 lg:col-span-12'>
                                                <Controller
                                                    name="installment_value"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            label="ชำระงวดละ"
                                                            error={!!errors.installment_value}
                                                            helperText={errors.installment_value?.message}
                                                            fullWidth
                                                            value={field.value || ''}
                                                            onChange={(e) => {
                                                                if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                                                    field.onChange(e.target.value);
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">บาท</InputAdornment>,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>


                                        </>
                                    }



                                </div>
                            </div>

                        </div>



                        <div className='col-span-1 sticky top-10'>
                            <div className='p-8 bg-white mb-4'>
                                <div className="mb-6">
                                    <Controller
                                        name="branch"
                                        control={control}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                options={branches}
                                                getOptionLabel={(option) => option.admin_name}
                                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="สาขา"
                                                        error={!!errors.branch}
                                                        helperText={errors.branch?.message}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                value={field.value || null}
                                                onChange={(event, newValue) => field.onChange(newValue)}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <Controller
                                        name="dateTimeOfRequest"
                                        control={control}
                                        defaultValue={dayjs()}
                                        render={({ field }) => (
                                            <DateTimePicker
                                                label="วันที่ เวลา"
                                                value={field.value}
                                                onChange={field.onChange}
                                                inputFormat="DD/MM/YYYY HH:mm"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        error={!!errors.dateTimeOfRequest}
                                                        helperText={errors.dateTimeOfRequest?.message}
                                                    />
                                                }
                                                maxDate={dayjs()}
                                                maxTime={dayjs(field.value).isSame(dayjs(), 'day') ? dayjs() : undefined}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </LocalizationProvider>
            </form>

        </Layout >
    );
};

export default EditPurchaseRequest;