import { NavLink } from 'react-router-dom';
export default function Notfound() {
    return (
        <div className="flex min-h-full items-center justify-center h-screen">
            <div className="flex">
                <div style={{ width: "100%" }} className="text-center error-doc">
                    <h1 className="mb-1 text-xl">ไม่พบหน้า หรือหน้านี้ถูกลบไปแล้ว</h1>
                    <NavLink to="/" className='font-bold bg-blue text-white group rounded-md border border-transparent bg-indigo-600 py-3 px-6 '>กลับหน้าหลัก</NavLink>
                </div>
            </div>
        </div>
    )
}