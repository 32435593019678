import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from "../layouts/layout"
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const MessageCenter = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const [contents, setContents] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteID, setDeleteID] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    let page = useRef(1);

    const fetchData = useCallback((value = 1) => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/get-all-message/${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            setContents(response.data.data);
            setAllPage(response.data.allPage);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }, [setIsLoading]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const initialPage = parseInt(params.get('page')) || 1;
        page.current = initialPage;
        fetchData(initialPage);
    }, [fetchData, location.search]);

    const convertToOrderID = (id, ref) => {
        switch (ref) {
            case 'web_order':
                return `GSE-${id.toString().padStart(5, '0')}`;
            case 'mcapital_orders':
                return `M-${id.toString().padStart(5, '0')}`;
            case 'gseinsure_orders':
                return `GSI-${id.toString().padStart(5, '0')}`;
            default:
                return `${id.toString().padStart(5, '0')}`;
        }
    }

    const convertTabletowebsite = (table) => {
        switch (table) {
            case 'web_order':
                return `gse-stores.com`;
            case 'mcapital_orders':
                return `mcapital.co.th`;
            case 'gseinsure_orders':
                return `gseinsurebroker.com`;
            default:
                return `${table}`;
        }
    }

    const fetchNextPage = (event, value) => {
        page.current = value;
        navigate(`?page=${value}`);
        window.scrollTo(0, 0);
        //fetchData(value);
    }

    const [singleContent, setSingleContent] = useState({});
    const [openSingleView, setOpenSingleView] = useState(false);

    const setReadMessage = (id) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/read/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: id,
            }
        }).then(function (response) {
            fetchData(page.current);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const handleDelete = (id) => {
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/delete/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: id,
            }
        }).then(function (response) {
            fetchData(page.current);
            setAlert({
                open: true,
                type: 'success',
                msg: 'ลบข้อความสำเร็จ',
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Layout>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex flex-col lg:flex-row justify-between w-full'>
                    <div className='flex flex-col lg:flex-row gap-4 lg:items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>ข้อความจากสาขา</h1>

                    </div>
                    <div className='flex gap-4 mt-4 lg:mt-0'>

                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`}>
                            <div className='w-1/5 font-bold'>จาก</div>
                            <div className='w-1/5 font-bold'>วันที่</div>
                            <div className='w-1/5 font-bold'>เลขที่คำสั่งซื้อ</div>
                            <div className='w-1/5 font-bold'>ข้อความ</div>
                            <div className={`w-1/5 text-right font-bold`}>จัดการ</div>
                        </div>
                        {contents.length > 0 && contents.map((content, index) => (
                            <div
                                className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50' : ''} ${content.is_read === 0 ? 'font-bold' : ''}`}
                                key={index}
                            >
                                <div className='w-1/5'>{content.admin_name}</div>
                                <div className='w-1/5'>
                                    <span className='pr-2'>
                                        {
                                            new Date(content.created_at).toLocaleDateString('th-TH', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })
                                        }
                                    </span>
                                    <span>
                                        {
                                            new Date(content.created_at).toLocaleTimeString('th-TH', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })
                                        }
                                    </span>
                                </div>
                                <div className='w-1/5'>{convertToOrderID(content.web_order_id, content.w_order_table)}</div>
                                <div className='w-1/5 truncate'>{content.w_message}</div>
                                <div className={`w-1/5 flex justify-end gap-2`}>

                                    <Tooltip title="ดูข้อความ" placement="top" arrow >
                                        <IconButton
                                            aria-label="edit"
                                            sx={{ backgroundColor: '#f1f7fe' }}
                                            onClick={() => {
                                                setSingleContent(content);
                                                setOpenSingleView(true);
                                                setReadMessage(content.id);
                                            }}
                                        >
                                            <RemoveRedEyeOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="ลบข้อความ" placement="top" arrow >
                                        <IconButton
                                            aria-label="edit"
                                            sx={{ backgroundColor: '#f1f7fe' }}
                                            onClick={() => {
                                                setOpenDelete(true);
                                                setDeleteID(content.id);
                                            }}
                                        >
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current}  onChange={fetchNextPage}/>
                </div>
            </div>


            <Dialog
                open={openSingleView}
                onClose={() => setOpenSingleView(false)}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>
                    รายละเอียดข้อความ
                </DialogTitle>
                <DialogContent>
                    {singleContent.w_message &&
                        <div className='flex flex-col gap-4'>

                            <div className='grid grid-cols-2 gap-4'>
                                <div className='flex flex-col gap-1'>
                                    <span className='font-bold'>จาก</span>
                                    <span>{singleContent.admin_name}</span>
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <span className='font-bold'>วันที่</span>
                                    <div>
                                        <span className='mr-2'>
                                            {
                                                new Date(singleContent.created_at).toLocaleDateString('th-TH', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                })
                                            }
                                        </span>
                                        <span>
                                            {
                                                new Date(singleContent.created_at).toLocaleTimeString('th-TH', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <span className='font-bold'>เลขที่คำสั่งซื้อ</span>
                                    <span>{convertToOrderID(singleContent.web_order_id, singleContent.w_order_table)}</span>
                                </div>
                            </div>

                            <div className='flex flex-col gap-1'>
                                <span className='font-bold'>ข้อความ</span>
                                <span>{singleContent.w_message}</span>
                            </div>

                            <button
                                className='underline text-right'
                                onClick={() => 
                                    navigate(`/order/${convertTabletowebsite(singleContent.w_order_table)}/${singleContent.web_order_id}?back=${
                                        encodeURIComponent(location.pathname + location.search)
                                    }`)
                                }
                            >
                                ไปยังคำสั่งซื้อ
                                <ArrowRightAltOutlinedIcon />
                            </button>
                        </div>
                    }
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>
                    ลบข้อความ
                </DialogTitle>
                <DialogContent>
                    <div className='flex flex-col gap-4'>
                        <div>คุณต้องการลบข้อความนี้ใช่หรือไม่</div>
                        <div className='flex justify-end gap-4'>
                            <button
                                className='px-4 py-2 rounded-lg bg-gray-300 hover:bg-gray-400'
                                onClick={() => setOpenDelete(false)}
                            >
                                ยกเลิก
                            </button>
                            <button
                                className='px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white'
                                onClick={() => {
                                    setOpenDelete(false);
                                    handleDelete(deleteID);
                                }}
                            >
                                ลบข้อความ
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>


        </Layout >
    )
}
export default MessageCenter;