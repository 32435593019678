import { useEffect } from "react";
import Layout from "../../layouts/layout";
import SatisfactionSurveyForm from "../../components/SatisfactionSurveyForm";

const AddNewSatisfactionSurvey = () => {

    useEffect(() => {
        document.title = 'บันทึกข้อมูลการ Call center ก่อนการอนุมัติออเดอร์/ความพึงพอใจหลังการส่งสินค้า';
    }, []);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <SatisfactionSurveyForm />
        </Layout>
    );
}
export default AddNewSatisfactionSurvey;