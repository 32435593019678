import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ComplaintEdit = () => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentData, setContentData] = useState([]);
    const [complaintStatus, setComplaintStatus] = useState(1);
    const [logData, setLogData] = useState([]);
    const { id } = useParams();
    const [addLog, setAddLog] = useState('');
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    useEffect(() => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/complaint/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                setContentData(response.data.data);
                setLogData(response.data.data.log);
                setComplaintStatus(response.data.data.c_status);
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        });
    }, [id, setIsLoading]);

    const handleAddLog = () => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/complaint/add`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: id,
                log: addLog
            }
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                setLogData(response.data.data);
                setAddLog('');
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'บันทึกข้อมูลสำเร็จ'
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        });
    }

    const handleUpdateStatus = () => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/complaint/update`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                id: id,
                status: complaintStatus
            }
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                setLogData(response.data.data);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'บันทึกข้อมูลสำเร็จ'
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    return (

        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex flex-col lg:flex-row justify-between w-full'>
                    <div className='flex gap-4 '>
                        <h1 className='text-base lg:text-2xl font-bold'>
                            <Link to="/complaints"><ArrowBackOutlinedIcon /></Link>
                            รายละเอียดเรื่องร้องเรียน #{id.toString().padStart(5, '0')}
                        </h1>
                    </div>
                    <div className='flex gap-4 mt-4 lg:mt-0'>
                        <Box sx={{ width: 180 }}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel>สถานะ</InputLabel>
                                <Select
                                    value={complaintStatus}
                                    onChange={(e) => setComplaintStatus(e.target.value)}
                                >
                                    <MenuItem value={1}>รอการตรวจสอบ</MenuItem>
                                    <MenuItem value={2}>กำลังดำเนินการ</MenuItem>
                                    <MenuItem value={3}>เสร็จสิ้น</MenuItem>
                                    <MenuItem value={4}>ยกเลิก</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <button
                            className='bg-indigo-800 px-4 rounded-md text-white'
                            onClick={handleUpdateStatus}
                            disabled={isLoading}
                        >
                            บันทึกข้อมูล
                        </button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow'>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-6">
                            <div className="bg-white  shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">ข้อมูลลูกค้า</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 "></p>
                                </div>
                                <div className="border-t border-gray-200  px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="p-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">ชื่อ - นามสกุล</dt>
                                            <dd className="mt-1 text-sm text-gray-900  sm:mt-0 sm:col-span-4">{contentData.c_name} {contentData.c_lastname}</dd>
                                        </div>
                                        <div className="p-4 sm:py-5 sm:grid sm:grid-cols-6 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">เบอร์โทรศัพท์</dt>
                                            <dd className="mt-1 text-sm text-gray-900  sm:mt-0 sm:col-span-4">{contentData.c_phone}</dd>
                                        </div>
                                        <div className="p-4 sm:grid sm:py-5 sm:grid-cols-6 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">อีเมล</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">{contentData.c_email ? contentData.c_email : '-'}</dd>
                                        </div>
                                        <div className="p-4 sm:grid sm:py-5 sm:grid-cols-6 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">รหัสสมาชิก</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4">{contentData.c_member_code ? contentData.c_member_code : '-'}</dd>
                                        </div>
                                        <div className="p-4 sm:grid sm:py-5 sm:grid-cols-6 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">รายละเอียด</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-4 whitespace-pre-line">{contentData.c_address}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>

                        </div>
                        <div className="col-span-12 lg:col-span-6 ">
                            <div className="bg-white  shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">การดำเนินการ</h3>
                                </div>
                                <div className="border-t border-gray-200 p-8">
                                    <ol className="relative border-l border-gray-200 dark:border-gray-700 ">
                                        {
                                            logData.map((log, index) => {
                                                return (
                                                    <li className="mb-10 ml-6" key={index}>
                                                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white">
                                                            <svg className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                            </svg>
                                                        </span>
                                                        <time className="block mb-2 text-sm font-normal leading-none text-gray-400">{log.last_update}</time>
                                                        <p className="text-base font-normal text-gray-500">{log.log_text}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>
                            </div>

                            <div className="bg-white  shadow overflow-hidden sm:rounded-lg mt-4">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">เพิ่มการดำเนินการ</h3>
                                </div>
                                <div className="border-t border-gray-200 p-8">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="col-span-12">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                                รายละเอียด
                                            </label>
                                            <div className="mt-1">
                                                <textarea
                                                    id="about"
                                                    name="about"
                                                    rows={3}
                                                    className="outline-none p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                                    value={addLog}
                                                    onChange={(e) => setAddLog(e.target.value)}
                                                />
                                            </div>
                                            <button
                                                className='bg-gray-200 px-4 rounded-md mt-4'
                                                onClick={handleAddLog}
                                                disabled={isLoading}
                                            >
                                                + เพิ่มรายละเอียด
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default ComplaintEdit;