
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link, useParams } from "react-router-dom";
import { AlertContext } from '../../contexts/AlertContext';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Switch from '@mui/material/Switch';

import UploadImages from '../../components/UploadImages';
import ProductFilterList from '../../components/ProductFilterList';
import ProductModelSearch from '../../components/ProductModelSearch';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InsertImageToEditor from '../../components/InsertImageToEditor';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

const adminRole = localStorage.getItem('admin_role');
const adminBranchId = Number(localStorage.getItem('admin_branch_id'));

export default function EditProduct() {

    const { alert, setAlert } = useContext(AlertContext);
    //const navigate = useNavigate();
    const { id } = useParams();
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [publicDate, setPublicDate] = useState(dayjs(new Date()).format('YYYY/MM/DD'));
    const [expDate, setExpDate] = useState(null);
    const [productParent, setProductParent] = useState(null);
    const [selectedItems, setSelectedItems] = useState({});
    const [productImage, setProductImage] = useState([]);
    const [defModel, setDefModel] = useState('');
    const [defImages, setDefImages] = useState([]);
    const [defaultCheckedItems, setDefaultCheckedItems] = useState({});
    const [productSlug, setProductSlug] = useState('');
    const editorRef = useRef();

    const [productData, setProductData] = useState({
        title: '',
        description: '',
        shortDescription: '',
        productPrice: '',
        productSalePrice: '',
        modelCode: '',
        modelName: '',
        brand: 'LG',
        installment_6: '',
        installment_8: '',
        installment_10: '',
        installment_12: '',
        installment_18: '',
        installment_24: '',
        use_status: 1,
    });

    const brands = [
        'LG',
        'OPPO',
        'SAMSUNG',
        'HITACHI',
        'Xiaomi',
        'Lenovo',
        'Acer',
        'Asus',
        'HP',
    ];

    useEffect(() => {
        setIsLoading(true)
        window.scrollTo(0, 0);

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/product/single/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {

            if (response.data.status === 200) {
                setIsLoading(false);
                setProductData(prev => ({
                    ...prev,
                    title: response.data.productData.product_name,
                    description: response.data.productData.product_long_desc,
                    shortDescription: response.data.productData.product_short_desc,
                    productPrice: response.data.productData.product_price,
                    productSalePrice: response.data.productData.product_sale_price,
                    modelCode: response.data.productData.model_code,
                    modelName: response.data.productData.model_name,
                    brand: response.data.productData.brand_name,
                    installment_6: response.data.productData.installment_6,
                    installment_8: response.data.productData.installment_8,
                    installment_10: response.data.productData.installment_10,
                    installment_12: response.data.productData.installment_12,
                    installment_18: response.data.productData.installment_18,
                    installment_24: response.data.productData.installment_24,
                    use_status: response.data.productData.use_status,
                }));
                setDefModel(`${response.data.productData.pr_name} - ${response.data.productData.pr_code}`);
                setProductParent({ id: response.data.productData.pr_id });
                setDefImages(response.data.productData.gallery);
                setProductImage(response.data.productData.gallery);
                setDefaultCheckedItems(response.data.productData.terms);
                setProductSlug(response.data.productData.product_slug);
            } else {
                window.location.href = '/products';
            }
        });
    }, [id, setIsLoading]);

    const handleSelectedItems = (filterName) => {
        setSelectedItems(filterName);
    };

    const onImagesChange = (newImage) => {
        setProductImage(newImage);
    }

    const publishProduct = () => {

        if (productData.title === '') {
            setAlert({ open: true, severity: 'error', message: 'กรุณากรอกชื่อสินค้า' });
            return;
        }

        if (!selectedItems.หมวดหมู่) {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกหมวดหมู่สินค้า' });
            return;
        }

        let error = false;
        if (selectedItems.หมวดหมู่) {
            Object.keys(selectedItems.หมวดหมู่).forEach((key) => {
                if (key === '6' && selectedItems.ความจำ.length === 0) {
                    error = true;
                }
            });
        }

        if (error) {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกความจำสินค้า' });
            return;
        }

        setIsLoading(true);

        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/product/update/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                publicDate: publicDate,
                expDate: expDate,
                productParent: productParent,
                trems: selectedItems,
                gallery: productImage,
                productData: productData
            }
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({ open: true, severity: 'error', message: response.data.msg });
            } else if (response.data.status === 200) {
                setAlert({ open: true, severity: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
            setIsLoading(false);
        });
    }

    const handleAlertClose = () => {
        setAlert({ open: false, severity: 'error', message: '' });
    };

    const insertImages = (newImages) => {
        if (editorRef.current) {
            const marker = editorRef.current.model.markers.get('insertMarker');
            let imageTags = '';

            const reversedImages = [...newImages].reverse(); // Create a copy and reverse it

            for (let i = 0; i < reversedImages.length; i++) {
                const imageUrl = reversedImages[i].name;
                imageTags += `<figure class="image"><img src="${process.env.REACT_APP_FRONTEND_API}/image?name=${imageUrl}"></figure>`;
            }

            const viewFragment = editorRef.current.data.processor.toView(`<div>${imageTags}</div>`);
            const modelFragment = editorRef.current.data.toModel(viewFragment);

            if (marker) {
                editorRef.current.model.insertContent(modelFragment, marker.getRange());
            } else {
                editorRef.current.model.insertContent(modelFragment, editorRef.current.model.document.selection);
            }

            if (marker) {
                editorRef.current.model.markers.remove('insertMarker');
            }
        }
    };


    const [addStock, setAddStock] = useState({
        branch_id: '',
        quantity: 0,
    });
    const [branchs, setBranchs] = useState([]);
    const [productStocks, setProductStocks] = useState([]);
    const [editStock, setEditStock] = useState({
        id: '',
        branch_id: '',
        branch_name: '',
        quantity: '',
    });
    const [changeLog, setChangeLog] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const loadStock = useCallback(() => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/stocks-product/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                setProductStocks(response.data.stocks);
                setChangeLog(response.data.stock_change_logs);
                resolve();
            }).catch(function (error) {
                console.log(error);
                reject(error);
            });
        });
    }, [id]);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/stock/branches/1`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setBranchs(response.data.branches);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        loadStock();
    }, [loadStock]);

    const handleUpdateStock = () => {
        setIsLoading(true);
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/stock/update/${editStock.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                quantity: editStock.quantity,
            }
        }).then(function (response) {
            setIsLoading(false);
            if (response.data.status === 200) {
                setEditStock({
                    id: '',
                    branch_id: '',
                    branch_name: '',
                    quantity: '',
                });
                //setProductStocks(response.data.stocks);
                loadStock();
                setAlert({ open: true, severity: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
            }
        }).catch(function (error) {
            console.log(error);
            setIsLoading(false);
        });
    };

    const removeDuplicateBranchsFromStockState = (branchs, stocks) => {
        let newBranchs = [];
        let newStocks = [];

        stocks.forEach((stock) => {
            newStocks.push(stock.branch_id);
        });

        branchs.forEach((branch) => {
            if (!newStocks.includes(branch.id)) {
                newBranchs.push(branch);
            }
        });

        return newBranchs;
    }



    const handleAddStock = async () => {

        let isMounted = true;

        if (addStock.branch_id === '') {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกสาขา' });
            return;
        }

        if (addStock.quantity < 0) {
            setAlert({ open: true, severity: 'error', message: 'กรุณากรอกจำนวนสินค้า' });
            return;
        }

        setIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/stock/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                product_id: id,
                branch_id: addStock.branch_id,
                quantity: addStock.quantity,
            }
        }).then(function (response) {
            setIsLoading(false);
            if (response.data.status === 200) {
                loadStock().then(() => {
                    setAddStock({
                        branch_id: '', // Reset branch_id here
                        quantity: 0,
                    });
                });
                setOpen(false);
                setAlert({ open: true, severity: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
            }
        }).catch(function (error) {
            console.log(error);
            if (isMounted) setIsLoading(false);
        });

        return () => {
            isMounted = false; // Set flag to false when the component unmounts
        };

    }

    const countTotalStock = (stocks) => {
        let total = 0;
        stocks.forEach((stock) => {
            total += stock.quantity;
        });
        return total;
    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between w-full mb-8'>
                <h1 className='text-base lg:text-2xl font-bold'><Link to='/product'> <ArrowBackOutlinedIcon /></Link> แก้ไขสินค้า {productData.title} </h1>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 items-start'>
                <div className='col-span-3'>
                    <div className='p-8 bg-white mb-4'>
                        <input
                            type='text'
                            name='product_title'
                            placeholder='ชื่อสินค้า'
                            className='lg:text-2xl font-bold border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                            value={productData.title}
                            onChange={(e) => setProductData(
                                prev => ({
                                    ...prev,
                                    title: e.target.value
                                })
                            )}
                        />

                        <a
                            className='mb-4 inline-block text-indigo-800 underline'
                            href={`${process.env.REACT_APP_FRONTEND_URL}/product/${productSlug}`}
                            target='_blank'
                            rel="noreferrer">
                            {`${process.env.REACT_APP_FRONTEND_URL}/product/${productSlug}`}
                        </a>

                        <div className="myEditor">
                            <InsertImageToEditor onImagesChange={insertImages} />
                            <CKEditor
                                editor={Editor}
                                data={productData.description}
                                onReady={editor => {
                                    setIsLoading(false);
                                    editorRef.current = editor;
                                }}

                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProductData(prev => ({
                                        ...prev,
                                        description: data
                                    }))
                                }}
                            />
                        </div>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <label className='font-bold mb-1 block text-lg'>ราคาปกติ (฿)</label>
                                <input
                                    type='number'
                                    placeholder='ราคาปกติ'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.productPrice}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            productPrice: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div>
                                <label className='font-bold mb-1 block text-lg'>ราคาที่ลด (฿)</label>
                                <input
                                    type='number'
                                    placeholder='ราคาที่ลด (฿)'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.productSalePrice}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            productSalePrice: e.target.value
                                        })
                                    )}
                                />
                            </div>

                            <div className='col-span-2'>
                                <label className='font-bold block text-lg -mb-3'>การผ่อนชำระ</label>
                            </div>
                            <div className='col-span-2 grid grid-cols-2 gap-x-16 p-8 border border-gray-300'>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>6 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='6 งวด'
                                        className=' text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_6 ? productData.installment_6 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_6: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>8 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='8 งวด'
                                        className=' text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_8 ? productData.installment_8 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_8: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>10 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='10 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_10 ? productData.installment_10 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_10: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>12 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='12 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_12 ? productData.installment_12 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_12: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>18 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='18 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_18 ? productData.installment_18 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_18: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>24 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='24 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_24 ? productData.installment_24 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_24: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                            </div>


                            <div>
                                <label className='font-bold mb-1 block text-lg'>Model code</label>
                                <input
                                    type='text'
                                    placeholder='Model code'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.modelCode}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            modelCode: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div>
                                <label className='font-bold mb-1 block text-lg'>Model name</label>
                                <input
                                    type='text'
                                    placeholder='Model name'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.modelName}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            modelName: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div className='col-span-2'>
                                <label className='font-bold mb-1 block text-lg'>สินค้าที่เชื่อมโยง</label>
                                <ProductModelSearch onModelSelected={setProductParent} defModel={defModel} />
                            </div>
                        </div>

                    </div>


                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>รูปสินค้า</label>
                        <UploadImages onImagesChange={onImagesChange} defImages={defImages} />
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>คำอธิบายสินค้าอย่างสั้น</label>
                        <div className="myEditor">
                            <CKEditor
                                editor={Editor}
                                data={productData.shortDescription}
                                onReady={() => {
                                    setIsLoading(false);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProductData(prev => ({
                                        ...prev,
                                        shortDescription: data
                                    }))
                                }}
                            />
                        </div>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <div className='flex justify-between items-center mb-4'>
                            <label className='font-bold mb-2 block text-lg'>สต๊อกสินค้า</label>
                            <button
                                className='bg-indigo-800 px-4 py-2 text-white'
                                onClick={handleClickOpen}
                            >
                                + เพิ่มสต๊อก
                            </button>
                        </div>

                        <div className='overflow-x-auto'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='border-y border-gray-300 p-2 text-left'>สาขา</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>สต๊อก</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>แก้ไข</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productStocks.map((item, index) => (
                                        <tr key={index} className=' hover:bg-gray-100'>
                                            <td className='border-t border-gray-300 p-2'>{item.branch_name}</td>
                                            <td className='border-t border-gray-300 p-2 text-right flex justify-end'>
                                                {item.id === editStock.id ? (
                                                    <input
                                                        type='number'
                                                        placeholder='สต๊อก'
                                                        className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline text-right max-w-xs'
                                                        //defaultValue={item.quantity}
                                                        value={editStock.quantity}
                                                        onChange={(e) => {
                                                            setEditStock({
                                                                id: item.id,
                                                                branch_id: item.branch_id,
                                                                branch_name: item.branch_name,
                                                                quantity: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                ) : (
                                                    <span>
                                                        {item.quantity}
                                                    </span>
                                                )}
                                            </td>
                                            <td className='border-t border-gray-300 p-2 text-right'>
                                                {item.id === editStock.id ? (
                                                    <>
                                                        <button
                                                            className='mr-4'
                                                            onClick={() => setEditStock({
                                                                id: '',
                                                                branch_id: '',
                                                                branch_name: '',
                                                                quantity: '',
                                                            })}
                                                        >
                                                            ยกเลิก
                                                        </button>
                                                        <button
                                                            className='bg-indigo-800 px-4 py-2 text-white'
                                                            onClick={() => handleUpdateStock()}
                                                            disabled={isLoading}
                                                        >
                                                            บันทึก
                                                        </button>
                                                    </>
                                                ) : (
                                                    adminRole === '1' || adminRole === '3' || item.admin_ids?.includes(adminBranchId) ? (
                                                        <button
                                                            onClick={() => setEditStock({
                                                                id: item.id,
                                                                branch_id: item.branch_id,
                                                                branch_name: item.branch_name,
                                                                quantity: item.quantity,
                                                            })}
                                                        >
                                                            <CreateOutlinedIcon />
                                                        </button>
                                                    ) : ''

                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className='border-t border-gray-300 p-2'>รวม</td>
                                        <td className='border-t border-gray-300 p-2 text-right'>{countTotalStock(productStocks)}</td>
                                        <td className='border-t border-gray-300 p-2'></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>ประวัติสต๊อก</label>
                        <div className='overflow-x-auto'>
                            <table className='w-full'>
                                <thead className=''>
                                    <tr>
                                        <th className='border-y border-gray-300 p-2 text-left'>วันที่</th>
                                        <th className='border-y border-gray-300 p-2 text-left'>โดย</th>
                                        <th className='border-y border-gray-300 p-2 text-left'>สาขา</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>จำนวน</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>จำนวนก่อนหน้า</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>จำนวนหลังแก้ไข</th>
                                        <th className='border-y border-gray-300 p-2 text-right'>ประเภท</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {changeLog.map((item, index) => (
                                        <tr key={index} className=' hover:bg-gray-100'>
                                            <td className='border-t border-gray-300 p-2'>{item.change_date}</td>
                                            <td className='border-t border-gray-300 p-2'>{item.admin_name}</td>
                                            <td className='border-t border-gray-300 p-2'>{item.branch_name}</td>
                                            <td className='border-t border-gray-300 p-2 text-right'>{item.change_quantity}</td>
                                            <td className='border-t border-gray-300 p-2 text-right'>{item.stock_before}</td>
                                            <td className='border-t border-gray-300 p-2 text-right'>{item.stock_after}</td>
                                            <td className='border-t border-gray-300 p-2 text-right'>{item.change_type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className='col-span-1 sticky top-10'>
                    <div className='p-8 bg-white mb-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className='mb-4'>
                                <DatePicker
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            variant="standard"
                                            fullWidth
                                        />
                                    }
                                    ampm={false}
                                    label="วันที่เผยแพร่สินค้า"
                                    inputFormat="YYYY/MM/DD"
                                    value={publicDate || dayjs(new Date()).format('YYYY/MM/DD')}
                                    onChange={(newValue) => {
                                        if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                            setPublicDate(dayjs(newValue.$d).format('YYYY/MM/DD'));
                                        } else {
                                            setPublicDate(dayjs(new Date()).format('YYYY/MM/DD'));
                                        }
                                    }}
                                />
                            </div>

                            <div className='relative mb-4'>
                                <DatePicker
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            variant="standard"
                                            fullWidth
                                            value={expDate ? dayjs(expDate).format('YYYY/MM/DD') : "ไม่กำหนด"}
                                        />
                                    }
                                    ampm={false}
                                    label="แสดงสินค้าจนถึงวันที่"
                                    inputFormat="YYYY/MM/DD"
                                    value={expDate}
                                    onChange={(newValue) => {
                                        if (newValue !== null && dayjs(newValue).isValid()) {
                                            setExpDate(newValue);
                                        } else {
                                            setExpDate(null);
                                        }
                                    }}
                                />
                            </div>
                        </LocalizationProvider>

                        <label className='font-bold mb-2 block'>สถานะสินค้า</label>
                        <div className='flex items-center mb-4'>
                            <Switch
                                checked={productData.use_status === 1 ? true : false}
                                onChange={(e) => setProductData(
                                    prev => ({
                                        ...prev,
                                        use_status: e.target.checked ? 1 : 0
                                    })
                                )}
                            />
                            <span>{productData.use_status ? 'ใช้งาน' : 'ไม่ใช้งาน'}</span>
                        </div>


                        <button
                            className='w-full bg-indigo-800 px-4 py-2 text-white'
                            disabled={isLoading}
                            onClick={() => publishProduct()}
                        >
                            แก้ไขสินค้า
                        </button>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>แบรนด์สินค้า</label>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={productData.brand}
                            className='w-full'
                            onChange={(e) => setProductData(
                                prev => ({
                                    ...prev,
                                    brand: e.target.value
                                })
                            )}
                        >
                            {brands.map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <ProductFilterList onItemsSelected={handleSelectedItems} defaultCheckedItems={defaultCheckedItems} />
                </div>

            </div>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>เพิ่มสต๊อกสินค้า</DialogTitle>
                <DialogContent>

                    <div className='mt-4'>
                        <Autocomplete
                            id="combo-box-demo"
                            options={removeDuplicateBranchsFromStockState(branchs, productStocks) || []}
                            getOptionLabel={(option) => option.branch_name}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="เลือกคลังสินค้า" />}
                            onChange={(e, value) => {
                                if (value !== null && value !== undefined && value !== '' && value.id) {
                                    setAddStock(prev => ({
                                        ...prev,
                                        branch_id: value.id
                                    }));
                                } else {
                                    setAddStock(prev => ({
                                        ...prev,
                                        branch_id: ''
                                    }));
                                }
                            }}
                            value={addStock.branch_id ? branchs.find(branch => branch.id === addStock.branch_id) : null || null}
                        />
                    </div>
                    {adminRole === '1' || adminRole === '3' ? (
                        <div className='flex justify-end mt-2'>
                            <Link to='/stock/branches?addnew=true' className='text-indigo-800 underline'>เพิ่มสาขา</Link>
                        </div>
                    ) : ''}
                    <div className='mt-4'>
                        <TextField
                            fullWidth
                            id="standard-number"
                            label="จำนวนสินค้า"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                setAddStock(prev => ({
                                    ...prev,
                                    quantity: e.target.value
                                }));
                            }}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                    <Button
                        onClick={handleAddStock}
                        disabled={isLoading}
                    >บันทึก</Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
}