import { createContext, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const AlertSnackbarContext = createContext();

export const useAlertSnackbar = () => {
    return useContext(AlertSnackbarContext);
};

export const AlertSnackbarProvider = ({ children }) => {
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });

    const showAlert = (message, severity) => {
        setAlert({ open: true, message, severity });
    };

    const hideAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(prev => ({ ...prev, open: false }));
    };

    return (
        <AlertSnackbarContext.Provider value={{ showAlert, hideAlert }}>
            {children}
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity={alert.severity} onClose={hideAlert}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </AlertSnackbarContext.Provider>
    );
};