// components/UploadImages.js

import { useEffect, useState, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
//import Snackbar from '@mui/material/Snackbar';
//import Alert from '@mui/material/Alert';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PopupGallery from '../components/PopupGallery';

function ImagePreview({ image, index, moveImage, removeImage }) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "image",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            moveImage(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "image",
        item: () => {
            return { id: image.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;
    return (
        <div className='relative aspect-square bg-gray-100' ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            <img className='aspect-square object-cover object-center' src={image.url} alt={image.name} />
            <button className="text-red-500 hover:text-red-700 text-right absolute top-0 right-0" onClick={() => removeImage(index)}> <CloseOutlinedIcon /> </button>
        </div>
    );
}

function isInsidePreviewWarp(element) {
    if (!element || !(element instanceof Element)) {
        return false;
    }

    if (element.classList.contains('preview-warp')) {
        return true;
    }

    return isInsidePreviewWarp(element.parentNode);
}

const UploadImages = ({ onImagesChange, defImages }) => {

    //const [open, setOpen] = useState(false);
    const [dragUpload, setDragUpload] = useState(false);
   //const [alertMsg, setAlertMsg] = useState('');
    //const [alertType, setAlertType] = useState('success');
    const [images, setImages] = useState([]);
    const [loading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [serverImages, setServerImages] = useState([]);
    const dragEventCounter = useRef(0);
    const dragTimeout = useRef();

    useEffect(() => {
        const dragEnterHandler = (e) => {
            if (isInsidePreviewWarp(e.target)) {
                return;
            }

            e.preventDefault();
            dragEventCounter.current++;
            if (dragTimeout.current) clearTimeout(dragTimeout.current);
            setDragUpload(true);
        }

        const dragLeaveHandler = (e) => {
            if (isInsidePreviewWarp(e.target)) {
                return;
            }

            e.preventDefault();
            dragEventCounter.current--;
            if (dragEventCounter.current === 0) {
                dragTimeout.current = setTimeout(() => {
                    setDragUpload(false);
                }, 50);
            }
        }

        const dropHandler = (e) => {
            e.preventDefault();
            if (dragTimeout.current) clearTimeout(dragTimeout.current);
            setDragUpload(false);
            dragEventCounter.current = 0;
        }

        window.addEventListener('dragenter', dragEnterHandler);
        window.addEventListener('dragleave', dragLeaveHandler);
        window.addEventListener('drop', dropHandler);

        return () => {
            window.removeEventListener('dragenter', dragEnterHandler);
            window.removeEventListener('dragleave', dragLeaveHandler);
            window.removeEventListener('drop', dropHandler);
        }
    }, []);

    useEffect(() => {
        if(defImages){
            setImages(defImages);
        }
    }, [defImages]);

    const moveImage = (fromIndex, toIndex) => {
        const newImages = [...images];
        const item = newImages[fromIndex];
        newImages.splice(fromIndex, 1);
        newImages.splice(toIndex, 0, item);
        setImages(newImages);
        if (onImagesChange) {
            onImagesChange(newImages);
        }
    }

/*
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    */

   // const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

    /*
     const handleClickUploadImage = () => {
         const fileInput = document.createElement("input");
         fileInput.setAttribute("type", "file");
         fileInput.style.display = "none";
         fileInput.addEventListener("change", handleUploadbyClickUpload);
         fileInput.click();
     }
   
 
     const handleUploadbyClickUpload = async (event) => {
         if (loading) {
             return;
         }
         const selectedFiles = event.target.files;
         const updatedImages = [...images];
         for (let i = 0; i < selectedFiles.length; i++) {
             const file = selectedFiles[i];
             if (file && types.includes(file.type)) {
                 const dataUrl = await readFileAsDataURL(file);
                 updatedImages.push({ id: updatedImages.length, url: dataUrl, name: file.name });
             } else {
                 setOpen(true)
                 setAlertType('error');
                 setAlertMsg('รูปแบบไฟล์ไม่ถูกต้อง (รองรับ png, jpg, jpeg, webp)');
             }
         }
         setImages(updatedImages);
         if (onImagesChange) {
             onImagesChange(updatedImages);
         }
     }
     */

     /*
    async function handleDrop(event) {
        if (loading) {
            return;
        }
        event.preventDefault();
        const files = event.dataTransfer.files;
        const updatedImages = [...images];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file && types.includes(file.type)) {
                const dataUrl = await readFileAsDataURL(file);
                updatedImages.push({ id: updatedImages.length, url: dataUrl, name: file.name });
            } else {
                setOpen(true)
                setAlertType('error');
                setAlertMsg('รูปแบบไฟล์ไม่ถูกต้อง (รองรับ png, jpg, jpeg, webp)');
            }
        }
        setImages(updatedImages);
        if (onImagesChange) {
            onImagesChange(updatedImages);
        }
    }
    */
   /*
    function readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }
*/
    const removeImage = index => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        if (onImagesChange) {
            onImagesChange(newImages);
        }
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>

                <div
                    className='relative py-2 text-center cursor-pointer bg-indigo-800 hover:bg-indigo-900'
                    //onDragOver={(e) => e.preventDefault()}
                    //onDrop={handleDrop}
                    //onClick={handleClickUploadImage}
                    onClick={() => {
                        setOpenDialog(true);

                    }}
                >
                    <div
                        className={`absolute h-full w-full bg-gray-100 top-0 flex justify-center items-center ${dragUpload ? '' : 'hidden'}`}
                    >
                        <p>วางไฟล์ที่นี่</p>
                    </div>
                    {loading &&
                        <div className='absolute h-full w-full bg-gray-100 top-0  flex justify-center items-center'>
                            <CircularProgress />
                        </div>
                    }

                    
                    <p className='text-center text-white'> <AttachmentOutlinedIcon /> แนบรูปสินค้า</p>
                    {/*<p className='text-xs text-center'>รูปแบบไฟล์ png, jpg, jpeg, webp</p>*/}
                </div>

                <div className='grid grid-cols-4 lg:grid-cols-8 gap-4 preview-warp mt-4'>
                    {images.map((image, i) => <ImagePreview key={`${image.name}-${i}-${image.id}`} image={image} index={i} moveImage={moveImage} removeImage={removeImage} />)}
                </div>
            </DndProvider>

            <PopupGallery
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setServerImages={setServerImages}
                serverImages={serverImages}
                setImages={setImages}
                onImagesChange={onImagesChange}
            />

            {/*<Snackbar className='z-50' open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
                </Snackbar>*/}

        </>
    );
};


export default UploadImages;
