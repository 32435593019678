import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import Avatar from '@mui/material/Avatar';

export default function Header() {

    return (
        <header>
            <div className='flex justify-between items-center px-8 py-2 w-full border-b'>
                <div>
                    <button
                        onClick={() => {
                            if (localStorage.getItem('collapsed') === 'collapsed') {
                                document.querySelector('.main-side-bar').classList.remove('collapsed');
                                localStorage.setItem('collapsed', 'false');
                            } else {
                                document.querySelector('.main-side-bar').classList.add('collapsed');
                                localStorage.setItem('collapsed', 'collapsed');
                            }
                        }}
                    >
                        <ClearAllOutlinedIcon />
                    </button>

                </div>
                <div className='flex gap-4 items-center'>
                    {localStorage.getItem('admin_name')}
                    <Avatar>{localStorage.getItem('admin_email').charAt(0)}</Avatar>
                </div>
            </div>
        </header>
    );
}