import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingProvider } from "./contexts/LoadingContext"
import PrivateRoute from "./hooks/useAuth";

import Login from "./pages/auth/login";
import Notfound from "./pages/notfound";
import Logout from "./pages/auth/logout";

import Dashboard from "./pages/dashboard";
import Product from "./pages/product/products";
import SingleProduct from "./pages/product/product";
import AddNewProduct from './pages/product/addProduct';
import EditProduct from './pages/product/editProduct';
import Orders from './pages/order/orders';
import EditOrders from './pages/order/editOrder';
import Customer from './pages/customer';
import Inquiry from "./pages/inquity";
import Complaints from "./pages/complaint/complaints";
import ComplaintEdit from "./pages/complaint/complaintEdit";
import Users from "./pages/users";
import EditOrdersOther from "./pages/order/editOrderOther";
import MessageCenter from "./pages/messageCenter";
import Branches from "./pages/stock/branches";
import SatisfactionSurvey from "./pages/SatisfactionSurvey/SatisfactionSurvey";
import AddNewSatisfactionSurvey from "./pages/SatisfactionSurvey/AddNewSatisfactionSurvey";
import EditSatisfactionSurvey from "./pages/SatisfactionSurvey/EditSatisfactionSurvey";
import PurchaseRequest from "./pages/purchaseRequest/purchaseRequest";
import EditPurchaseRequest from "./pages/purchaseRequest/editPurchaseRequest";

import BranchInquiries from "./pages/BranchInquiry/BranchInquiries";
import AddBranchInquiry from "./pages/BranchInquiry/AddBranchInquiry";
import EditBranchInquiry from "./pages/BranchInquiry/EditBranchInquiry";

import AllReport from "./pages/allReport";
import Approved from "./pages/reports/approved";
import ComplaintsReport from "./pages/reports/complaints";
import BranchInquiryReport from "./pages/reports/BranchInquiryReport";

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <Router>
          <Routes>
            <Route index element={<PrivateRoute> <Dashboard /> </PrivateRoute>} />
            <Route path="/product" element={<PrivateRoute> <Product /> </PrivateRoute>} />
            <Route path="/product/:id" element={<PrivateRoute> <SingleProduct /> </PrivateRoute>} />
            <Route path="/product/add" element={<PrivateRoute> <AddNewProduct /> </PrivateRoute>} />
            <Route path="/product/edit/:id" element={<PrivateRoute> <EditProduct /> </PrivateRoute>} />
            <Route path="/customer" element={<PrivateRoute> <Customer /> </PrivateRoute>} />
            <Route path="/inquiry" element={<PrivateRoute> <Inquiry /> </PrivateRoute>} />
            <Route path="/order" element={<PrivateRoute> <Orders /> </PrivateRoute>} />
            <Route path="/order/gse-stores.com/:id" element={<PrivateRoute> <EditOrders /> </PrivateRoute>} />
            <Route path="/order/:company/:id" element={<PrivateRoute> <EditOrdersOther /> </PrivateRoute>} />
            <Route path="/complaints" element={<PrivateRoute> <Complaints /> </PrivateRoute>} />
            <Route path="/complaint/:id" element={<PrivateRoute> <ComplaintEdit /> </PrivateRoute>} />
            <Route path="/users" element={<PrivateRoute> <Users /> </PrivateRoute>} />
            <Route path="/message-center" element={<PrivateRoute> <MessageCenter /> </PrivateRoute>} />
            <Route path="/stock/branches" element={<PrivateRoute> <Branches /> </PrivateRoute>} />
            <Route path="/satisfaction-survey" element={<PrivateRoute> <SatisfactionSurvey /> </PrivateRoute>} />
            <Route path="/satisfaction-survey/add" element={<PrivateRoute> <AddNewSatisfactionSurvey /> </PrivateRoute>} />
            <Route path="/satisfaction-survey/edit/:id" element={<PrivateRoute> <EditSatisfactionSurvey /> </PrivateRoute>} />

            <Route path="/branch-inquiry" element={<PrivateRoute> <BranchInquiries /> </PrivateRoute>} />
            <Route path="/branch-inquiry/add" element={<PrivateRoute> <AddBranchInquiry /> </PrivateRoute>} />
            <Route path="/branch-inquiry/edit/:id" element={<PrivateRoute> <EditBranchInquiry /> </PrivateRoute>} />

            <Route path="/purchase-request" element={<PrivateRoute> <PurchaseRequest /> </PrivateRoute>} />
            <Route path="/purchase-request/:id" element={<PrivateRoute> <EditPurchaseRequest /> </PrivateRoute>} />

            <Route path="/all-report" element={<PrivateRoute> <AllReport /> </PrivateRoute>} />
            <Route path="/reports/approved" element={<PrivateRoute> <Approved /> </PrivateRoute>} />
            <Route path="/reports/complaints" element={<PrivateRoute> <ComplaintsReport /> </PrivateRoute>} />
            <Route path="/reports/branch-inquiry" element={<PrivateRoute> <BranchInquiryReport /> </PrivateRoute>} />

            <Route path="/auth" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/404" element={<Notfound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Router>
      </LoadingProvider>
    </ThemeProvider>
  );
}
export default App;
