import { useEffect,  useContext} from 'react';
import { Link, useParams } from "react-router-dom";
//import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
//import Button from '@mui/material/Button';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export default function SingleProduct() {

    const { setIsLoading } = useContext(LoadingContext);
    const { id } = useParams();

    useEffect(() => {
        setIsLoading(false)
    }, [setIsLoading])

    return (
        <Layout>
            <div className='flex justify-between w-full'>
                <h1 className='text-2xl font-bold'><Link to='/product'> <ArrowBackOutlinedIcon /></Link>Product {id}</h1>
            </div>








        </Layout>
    );
}