import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";
import { AlertContext } from '../../contexts/AlertContext';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout";
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';

const Branches = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    const [admins, screenAdmins] = useState([]);
    const [data, setData] = useState([]);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { alert, setAlert } = useContext(AlertContext);
    const [hasMore, setHasMore] = useState(true);
    const page = useRef(1);
    const searchText = useRef('');
    const observer = useRef();

    const [addStock, setAddStock] = useState({
        branch_name: '',
        branch_admin: [],
    });

    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [openialog, setOpenialog] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditId(null);
        setAddStock({
            branch_name: '',
            branch_admin: [],
        });
    };

    const loadDatas = useCallback(() => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/stock/branches/${page.current}?search=${searchText.current}`,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('admin_token'),
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.status === 200) {
                setData(prevData => [...prevData, ...response.data.branches]);
                setHasMore(response.data.branches.length > 0);
                setIsLoading(false);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, [setData, setIsLoading]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadDatas = useCallback(debounce(() => loadDatas(), 500), [loadDatas]);

    const lastDataElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                page.current++;
                loadDatas();
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore, loadDatas]);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadDatas();
        // Handling "addnew" search param
        if (searchParams.get('addnew') === 'true') {
            setOpen(true);
            setSearchParams({});
        }
        // Dependencies here ensure this effect runs again only if any of these values change.
    }, [loadDatas, searchParams, setSearchParams]);


    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            screenAdmins(response.data.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const getAdminDetail = (id) => {
        return admins.find(admin => admin.id === id);
    }

    const handleAlertClose = () => {
        setAlert({ open: false, severity: 'error', message: '' });
    };

    const handleAddOrEditBranch = (addOrEdit) => {

        if (addStock.branch_name === '') {
            setAlert({ open: true, severity: 'error', message: 'กรุณากรอกชื่อคลังสินค้า' });
            return;
        }
        if (addStock.branch_admin.length === 0) {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกผู้ดูแลคลังสินค้า' });
            return;
        }


        let url = `${process.env.REACT_APP_BACKEND_URL}/stock/branch/add`;
        let method = 'post';
        if (addOrEdit === 'edit') {
            url = `${process.env.REACT_APP_BACKEND_URL}/stock/branch/update/${editId}`;
            method = 'put';
        }
        axios({
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                branch_name: addStock.branch_name,
                branch_admin: addStock.branch_admin,
            }
        }).then(function (response) {
            if (response.status === 200) {
                setAlert({ open: true, severity: 'success', message: 'บันทึกข้อมูลสำเร็จ' });
                handleClose();
                setData([]);
                page.current = 1;
                loadDatas();
            }
        }).catch(function (error) {
            console.log(error.response);
            if (error.response.data.msg) {
                setAlert({ open: true, severity: 'error', message: error.response.data.msg });
            }
        });
    }

    const handleDeleteBranch = () => {
        setIsLoading(true);
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/stock/branch/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setAlert({ open: true, severity: 'success', message: 'ลบคลังสินค้าสำเร็จ' });
                setOpenialog(false);
                setData([]);
                page.current = 1;
                loadDatas();
                setDeleteId(null);
            }
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
            setIsLoading(false);
        });
    }

    const handleSearchChange = (e) => {
        searchText.current = e.target.value;
        setData([]);
        page.current = 1;
        debouncedLoadDatas();
    }

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between w-full mb-8'>
                <div className='flex gap-4 items-center'>
                    <h1 className='text-base lg:text-2xl font-bold'> คลังสินค้า</h1>
                    <div className='relative'>
                        <input
                            type='text'
                            className='appearance-none block w-full py-2 pl-4 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:shadow-outline'
                            placeholder='ค้นหาคลังสินค้า'
                            value={searchText.current}
                            onChange={handleSearchChange}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <Button variant="contained"
                    onClick={handleClickOpen}
                >+ เพิ่มคลังสินค้า</Button>
            </div>

            <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                <div className='table-inner-wrap'>
                    <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                        <div className='w-1/5 font-bold'>ชื่อคลังสินค้า</div>
                        <div className='w-1/5 text-center font-bold'>สินค้าทั้งหมด</div>
                        <div className='w-1/5 text-right font-bold'>ผู้ดูแล</div>
                        <div className='w-1/5 text-right font-bold'>วันที่สร้าง</div>
                        <div className='w-1/5 text-right font-bold'>จัดการ</div>
                    </div>
                    {data.length > 0 ? Object.entries(data).map(([k, item], index) => (
                        <div
                            className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${k % 2 === 0 ? 'bg-gray-50/50' : ''}`}
                            key={k}
                            ref={index === data.length - 1 ? lastDataElementRef : null}
                        >
                            <div className='w-1/5'>{item.branch_name}</div>
                            <div className='w-1/5 text-center'>{item.total_products}</div>
                            <div className='w-1/5'>{
                                <AvatarGroup total={
                                    item.branch_admin.length > 5 ? 5 : item.branch_admin.length
                                }>
                                    {item.branch_admin.map((admin, index) => (
                                        <Tooltip key={index} title={<span style={{ fontSize: '14px' }}>
                                            {getAdminDetail(admin)?.admin_name}</span>} placement="top" arrow
                                        >
                                            <Avatar
                                                alt={getAdminDetail(admin)?.admin_name}
                                                sx={{ width: 34, height: 34 }}
                                            >
                                                {getAdminDetail(admin)?.admin_username?.charAt(0)}
                                            </Avatar>
                                        </Tooltip>
                                    ))}
                                </AvatarGroup>
                            }</div>
                            <div className='w-1/5 text-right'>{item.created_at}</div>

                            <div className='w-1/5 flex justify-end gap-2'>
                                <Tooltip title="แก้ไข" placement="top" arrow >
                                    <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                        setEditId(item.id);
                                        setAddStock({
                                            branch_name: item.branch_name,
                                            branch_admin: item.branch_admin
                                        });
                                        setOpen(true);
                                    }}>
                                        <EditNoteOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="ลบ" placement="top" arrow >
                                    <IconButton aria-label="delete" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                        setDeleteId(item.id);
                                        setOpenialog(true);
                                    }}>
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    )) : (
                        <div className='py-2 px-4 w-full flex items-center gap-4 text-sm'>
                            <div className='w-full text-center text-gray-500'>ไม่พบข้อมูล</div>
                        </div>
                    )}
                </div>
                {isLoading && <div className='w-full flex justify-center p-4'><CircularProgress /></div>}
                {!hasMore && <p className='text-center p-4 border-t'>No more datas to load</p>}
            </div>


            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{
                    editId ? 'แก้ไขคลังสินค้า' : 'เพิ่มคลังสินค้า'
                }</DialogTitle>
                <DialogContent>
                    <div className='mt-4'>
                        <TextField
                            fullWidth
                            id="standard-basic"
                            label="ชื่อสาขา"
                            onChange={(e) => {
                                setAddStock(prev => ({
                                    ...prev,
                                    branch_name: e.target.value
                                }));
                            }}
                            value={addStock.branch_name}
                        />
                    </div>

                    <div className='mt-4'>
                        <Autocomplete
                            multiple
                            id="combo-box-demo"
                            options={admins || []}
                            getOptionLabel={(option) => option.admin_name}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="เลือกแอดมินผู้ดูแลสาขา" />}
                            onChange={(e, value) => {
                                let ids = [];
                                value.forEach((item) => {
                                    ids.push(item.id);
                                });
                                setAddStock(prev => ({
                                    ...prev,
                                    branch_admin: ids
                                }));
                            }}
                            value={admins.length > 0 ? admins.filter((admin) => addStock.branch_admin.includes(admin.id)) : [] || []}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                    <Button disabled={isLoading} onClick={() => handleAddOrEditBranch(editId ? 'edit' : 'add')}>บันทึกข้อมูล</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openialog}
                onClose={() => setOpenialog(false)}
            >
                <DialogTitle>ลบคลังสินค้า</DialogTitle>
                <DialogContent>
                    <div>คุณต้องการลบคลังสินค้านี้หรือไม่</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenialog(false)}>ยกเลิก</Button>
                    <Button disabled={isLoading} onClick={handleDeleteBranch}>ลบคลังสินค้า</Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
}

export default Branches;