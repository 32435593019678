import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AllReport = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/reports/approved");
    }, [navigate]);

    return null;  // Return null or a loading indicator while redirecting
};

export default AllReport;
