import React, { useState } from 'react';
import { AlertContext } from './AlertContext';

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ open: false, severity: 'error', message: '' });

    return (
        <AlertContext.Provider value={{ alert, setAlert }}>
            {children}
        </AlertContext.Provider>
    );
};