import Layout from "../../layouts/layout"
import ReportLayout from "../../components/ReportLayout"
import * as XLSX from 'xlsx';

const BranchInquiryReport = () => {

    const handleExport = (serverData, title, dailyDate) => {
        // Prepare data for export
        const exportData = serverData.data.map(item => ({
            "สาขา": item["สาขา"],
            "สายบริการ": item["สายบริการ"],
            "ชื่อ": item["ชื่อ"],
            "นามสกุล": item["นามสกุล"],
            "โทรศัพท์": item["โทรศัพท์"],
            "สินค้าที่ต้องการ": item["สินค้าที่ต้องการ"],
            "วันที่สร้าง": item["วันที่สร้าง"]
        }));

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, title || 'Report');

        // Create an Excel file and trigger download
        XLSX.writeFile(workbook, `${title} ${dailyDate.startDate} - ${dailyDate.endDate}.xlsx`);
    }
    return (
        <Layout mainClass='bg-gray-100'>
            <ReportLayout
                title='งานออนไลน์สาขา'
                path='/branch-inquiry/export'
                previewTable={[
                    "สาขา", "สายบริการ", "ชื่อ", "นามสกุล", "โทรศัพท์", "สินค้าที่ต้องการ", "วันที่สร้าง"
                ]}
                handleExport={handleExport}
            />
        </Layout>
    );
}

export default BranchInquiryReport;