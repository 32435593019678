// components/PopupGallery.js
import { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function PopupGallery({ openDialog, setOpenDialog, serverImages, setServerImages, setImages, onImagesChange, getOutputImage }) {

    const allowTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    const [uploadsInProgress, setUploadsInProgress] = useState(0);
    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const myDivRef = useRef(null);
    const fetchOffset = useRef(0);
    const [dragUpload, setDragUpload] = useState(false);
    const dragEventCounter = useRef(0);
    const dragTimeout = useRef();
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });


    useEffect(() => {
        const dragEnterHandler = (e) => {
            e.preventDefault();
            dragEventCounter.current++;
            if (dragTimeout.current) clearTimeout(dragTimeout.current);
            setDragUpload(true);
        }

        const dragLeaveHandler = (e) => {
            e.preventDefault();
            dragEventCounter.current--;
            if (dragEventCounter.current === 0) {
                dragTimeout.current = setTimeout(() => {
                    setDragUpload(false);
                }, 50);
            }
        }

        const dropHandler = (e) => {
            e.preventDefault();
            if (dragTimeout.current) clearTimeout(dragTimeout.current);
            setDragUpload(false);
            dragEventCounter.current = 0;
        }

        window.addEventListener('dragenter', dragEnterHandler);
        window.addEventListener('dragleave', dragLeaveHandler);
        window.addEventListener('drop', dropHandler);

        return () => {
            window.removeEventListener('dragenter', dragEnterHandler);
            window.removeEventListener('dragleave', dragLeaveHandler);
            window.removeEventListener('drop', dropHandler);
        }
    }, []);

    function readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    const fetchImages = useCallback(() => {

        if (fetchOffset.current === null) {
            return;
        }

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/images/${fetchOffset.current}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg,
                });
            }
            if (response.data.status === 200) {
                setServerImages(prevImages => [...prevImages, ...response.data.images]);
                fetchOffset.current = response.data.next;
            }
        });
    }, [setAlert, setServerImages]);

    useEffect(() => {
        if (openDialog) {
            fetchImages();
        }
    }, [openDialog, fetchImages]);

    const handleCheckboxChange = (img, checked) => {
        if (checked) {
            setSelectedImageIds(prevIds => [...prevIds, img.id]);
        } else {
            setSelectedImageIds(prevIds => prevIds.filter(id => id !== img.id));
        }
    };

    const addToGallery = () => {
        const newImages = serverImages.filter(img => selectedImageIds.includes(img.id)).map(img => ({
            id: img.id,
            url: img.image,
            fromServer: true,
            name: img.name
        }));
        if (setImages) {
            setImages(prevImages => [...prevImages, ...newImages]);
        }
        if(onImagesChange){
            onImagesChange(prevImages => [...prevImages, ...newImages]);
        }
        if(getOutputImage){
            getOutputImage(newImages);
        }
        setSelectedImageIds([]);
        setOpenDialog(false);
    };

    const uploadMediatoServer = (name, url) => {
        setUploadsInProgress(prev => prev + 1);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/images/upload`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                name: name,
                url: url
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                setServerImages(prevImages => [response.data.images, ...prevImages]);
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg,
                });
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg,
                });
            }
            setUploadsInProgress(prev => prev - 1);
        });
    }

    const handleClickUploadImage = () => {
        const fileInput = document.createElement("input");
        fileInput.setAttribute("type", "file");
        fileInput.setAttribute("multiple", "true");
        fileInput.style.display = "none";
        fileInput.addEventListener("change", handleUploadbyClickUpload);
        fileInput.click();
    }

    const handleUploadbyClickUpload = async (event) => {
        const selectedFiles = event.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (file && allowTypes.includes(file.type)) {
                const dataUrl = await readFileAsDataURL(file);
                uploadMediatoServer(file.name, dataUrl);
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'รูปแบบไฟล์ไม่ถูกต้อง (รองรับ png, jpg, jpeg, webp)',
                });
            }
        }
    }

    const handleDrop = async (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file && allowTypes.includes(file.type)) {
                const dataUrl = await readFileAsDataURL(file);
                uploadMediatoServer(file.name, dataUrl);
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'รูปแบบไฟล์ไม่ถูกต้อง (รองรับ png, jpg, jpeg, webp)',
                });
            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    return (
        <Dialog
            fullScreen
            open={openDialog}
            onClose={() => { setOpenDialog(false); setSelectedImageIds([]); }}
        >
            <div
                ref={myDivRef}
                className='max-h-screen overflow-scroll'
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onScroll={() => {
                    const myDiv = myDivRef.current;
                    if (myDiv) {
                        if (myDiv.scrollTop + myDiv.clientHeight >= myDiv.scrollHeight) {
                            if (fetchOffset.current === null) {
                                return;
                            }
                            fetchImages();
                        }
                    }
                }}
            >
                <div className='flex justify-between items-center sticky top-0 bg-white px-8 py-2 z-40'>
                    <h4 className='text-xl font-bold'>เลือกรูปภาพสินค้า</h4>
                    <button onClick={() => { setOpenDialog(false); setSelectedImageIds([]); }}><CloseOutlinedIcon sx={{ fontSize: '2rem' }} /></button>
                </div>
                <div className={`absolute h-full w-full bg-gray-100 top-0 flex justify-center items-center z-50 ${dragUpload ? '' : 'hidden'}`}>
                    <p className='text-3xl font-bold'>วางไฟล์ที่นี่</p>
                </div>
                <div className='px-8'>
                    <div className='grid grid-cols-10 gap-4'   >
                        {Array.from({ length: uploadsInProgress }, (_, i) =>
                            <div key={i} className='aspect-square overflow-hidden'>
                                <Skeleton variant="rectangular" width={400} height={400} />
                            </div>
                        )}
                        {serverImages.length > 0 && serverImages.map((img, index) =>
                            <label htmlFor={`ga-image-${img.id}`} className='bg-gray-100 aspect-square ga-item relative' key={index}>
                                <input
                                    className='ga-check hidden absolute top-0 right-0 w-5 h-5'
                                    type='checkbox'
                                    id={`ga-image-${img.id}`}
                                    onChange={e => handleCheckboxChange(img, e.target.checked)}
                                />
                                <img className='aspect-square object-cover object-center' src={img.image} alt={img.name} />
                            </label>
                        )}
                    </div>
                </div>

                <div className='flex justify-between items-center sticky bottom-0 bg-white px-8 pt-2 z-10'>
                    <div className='font-bold'>ที่เลือกไว้ {selectedImageIds.length} รูปภาพ</div>
                    <div className='flex gap-4'>
                        <button className='bg-gray-200 px-4 py-2' onClick={handleClickUploadImage}>Upload ไฟล์รูปภาพ</button>
                        <button className='bg-indigo-800 px-4 py-2 text-white' onClick={() => addToGallery()}>ใช้รูปภาพที่เลือก</button>
                    </div>
                </div>
            </div>
            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}