import Layout from "../../layouts/layout"
import ReportLayout from "../../components/ReportLayout"
import * as XLSX from 'xlsx';

const Approved = () => {

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    const handleExport = (serverData, title, dailyDate) => {

        const data = serverData.data;
       // const maxProductCount = serverData.maxProductCount;
        const workbook = XLSX.utils.book_new();

        Object.keys(data).forEach((site) => {
            const siteData = data[site];
            const ws_data = [];

            // Set the column headers (labels) for the data
            const headers = [
                "เลขที่", "วันที่สั่งซื้อ", "เวลา", "ชื่อลูกค้า", "โทรศัพท์", "อาชีพ",
                "ที่อยู่", "ตำบล", "อำเภอ", "จังหวัด", "รหัสไปรษณีย์", "สถานะ",
                "สาขา", "ยอดรวม", "บันทึกแอดมิน", "วันที่ออกสัญญา", "เลขที่สัญญา",
                "ชื่อสินค้า", "จำนวน", "ราคาต่อหน่วย", "รวม"
            ];

            if (site === "gse-stores.com") {
                headers.push("สินค้าที่สนใจ");
            }

            // Add the headers to the sheet data
            ws_data.push(headers);

            siteData.forEach((entry) => {
                let row = [
                    entry['เลขที่'], entry['วันที่'], entry['เวลา'], entry['ชื่อลูกค้า'],
                    entry['โทรศัพท์'], entry['อาชีพ'], entry['ที่อยู่'], entry['ตำบล'],
                    entry['อำเภอ'], entry['จังหวัด'], entry['รหัสไปรษณีย์'], entry['สถานะ'],
                    entry['สาขา'], entry['ยอดรวม'], entry['บันทึกแอดมิน'], entry['วันที่ออกสัญญา'], entry['เลขที่สัญญา'],
                    entry['ชื่อสินค้า'], entry['จำนวน'], entry['ราคาต่อหน่วย'], entry['รวม']
                ];

                if (site === "gse-stores.com") {
                    row.push(entry['สินค้าที่สนใจ']);
                }

                ws_data.push(row);
            });

            const ws = XLSX.utils.aoa_to_sheet(ws_data);
            XLSX.utils.book_append_sheet(workbook, ws, site);
        });

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title} ${dailyDate.startDate} - ${dailyDate.endDate}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <Layout mainClass='bg-gray-100'>
            <ReportLayout
                title='ผลการอนุมัติ'
                path='/order/export-result'
                filterTable={[
                    'gse-stores.com',
                    'mcapital.co.th',
                    'gseinsurebroker.com'
                ]}
                activeFilterTable={'gse-stores.com'}
                previewTable={[
                    "เลขที่", "ชื่อลูกค้า", "โทรศัพท์",
                    "สาขา", "ยอดรวม", "วันที่ออกสัญญา", "เลขที่สัญญา"
                ]}
                handleExport={handleExport}
            />
        </Layout>
    );
};


export default Approved;