import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
//import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
//import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
//import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
//import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
//import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

export default function Sidebar() {
    const liClass = 'flex space-x-4 items-center py-2.5 px-7 font-normal hover:text-blue hover:stroke-blue hover:border-l-4 ease-in-out duration-100 hover:bg-gray-smoke';
    const iconColor = '#595c60';
    const handleMenuClick = () => {
        if (window.screen.width < 1024) {
            document.querySelector('.main-side-bar').classList.add('collapsed');
            localStorage.setItem('collapsed', 'collapsed');
        }
    }
    useEffect(() => {
        if (window.screen.width < 1024) {
            document.querySelector('.main-side-bar').classList.add('collapsed');
            localStorage.setItem('collapsed', 'collapsed');
        }
    }, [])

    const adminRole = localStorage.getItem('admin_role');

    const menuItems = [
        {
            title: 'แดชบอร์ด',
            icon: <DashboardOutlinedIcon sx={{ color: iconColor }} />,
            link: '/',
            roles: ['1', '2', '6']
        },
        {
            title: 'รายการสินค้า',
            icon: <Inventory2OutlinedIcon sx={{ color: iconColor }} />,
            link: '/product',
            roles: ['1', '2', '3'],
            subMenu: [
                {
                    title: 'คลังสินค้า',
                    link: '/stock/branches',
                    roles: ['1', '3']
                }
            ]
        },
        {
            title: 'คำสั่งซื้อ',
            icon: <RequestPageOutlinedIcon sx={{ color: iconColor }} />,
            link: '/order',
            roles: ['1', '2', '6']
        },
        {
            title: 'งานออนไลน์สาขา',
            icon: <RequestQuoteOutlinedIcon sx={{ color: iconColor }} />,
            link: '/branch-inquiry',
            roles: ['1', '2']
        },
        /*{
            title: 'คำขอใช้บริการ ',
            icon: <InventoryOutlinedIcon sx={{ color: iconColor }} />,
            link: '/purchase-request',
            roles: ['1', '2']
        },*/
        {
            title: 'ลูกค้า',
            icon: <PeopleAltOutlinedIcon sx={{ color: iconColor }} />,
            link: '/customer',
            roles: ['1']
        },
        {
            title: 'ผู้สนใจสินค้า',
            icon: <Person2OutlinedIcon sx={{ color: iconColor }} />,
            link: '/inquiry',
            roles: ['1']
        },
        /*{
            title: 'คำขอใช้บริการ',
            icon: <ArticleOutlinedIcon sx={{ color: iconColor }} />,
            link: '/service-requests',
            roles: ['1', '2']
        },*/
        {
            title: 'แบบสอบถามความพึงพอใจ',
            icon: <SentimentSatisfiedOutlinedIcon sx={{ color: iconColor }} />,
            link: '/satisfaction-survey',
            roles: ['1', '2', '5']
        },
        {
            title: 'เรื่องร้องเรียน',
            icon: <GradingOutlinedIcon sx={{ color: iconColor }} />,
            link: '/complaints',
            roles: ['1', '4']
        },
        {
            title: 'รายงาน',
            icon: <ShowChartOutlinedIcon sx={{ color: iconColor }} />,
            link: '/all-report',
            roles: ['1', '2', '6'],
            subMenu: [
                {
                    title: 'ผลการอนุมัติ',
                    link: '/reports/approved',
                    roles: ['1', '2', '6']
                },
                {
                    title: 'เรื่องร้องเรียน',
                    link: '/reports/complaints',
                    roles: ['1', '4']
                },
                {
                    title: 'งานออนไลน์สาขา',
                    link: '/reports/branch-inquiry',
                    roles: ['1', '2']
                }
            ]
        },
        {
            title: 'แอดมิน',
            icon: <PermIdentityOutlinedIcon sx={{ color: iconColor }} />,
            link: '/users',
            roles: ['1']
        },
        {
            title: 'ข้อความจากสาขา',
            icon: <ForumOutlinedIcon sx={{ color: iconColor }} />,
            link: '/message-center',
            roles: ['1']
        },
    ];


    return (
        <aside className={'h-screen main-side-bar flex flex-col justify-between side-nav side-nav-transparent side-nav-expand border-r border-slate-150 lg:w-64 2xl:w-80 sticky top-0 ' + localStorage.getItem('collapsed')}>
            <div className='' >
                <div className="side-nav-header border-b border-slate-150 flex justify-between lg:justify-center px-3">
                    <div className="logo px-4 py-3">
                        <div className='text-2xl font-bold text-left lg:text-center'>GSE</div>
                    </div>
                    <button
                        className='lg:hidden'
                        onClick={() => {
                            document.querySelector('.main-side-bar').classList.add('collapsed');
                            localStorage.setItem('collapsed', 'collapsed');
                        }}
                    >
                        <CloseOutlinedIcon />
                    </button>
                </div>
                <div className="side-nav-content py-4 font-bold text-gray-dark" style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'scroll' }}>
                    <ul>

                        {menuItems.map((item, index) => {
                            if (item.roles.includes(adminRole)) {
                                return (
                                    <li key={index}>
                                        <NavLink to={item.link} className={liClass} onClick={handleMenuClick}>
                                            <span className='menu-svg-warp'>
                                                {item.icon}
                                            </span>
                                            <span className='menu-link-warp'>
                                                {item.title}
                                            </span>
                                        </NavLink>
                                        {item.subMenu &&
                                            <ul className=''>
                                                {item.subMenu.map((subItem, subIndex) => {
                                                    if (subItem.roles.includes(adminRole)) {
                                                        return (
                                                            <li key={subIndex}>
                                                                <NavLink to={subItem.link} className={liClass} onClick={handleMenuClick}>
                                                                    <span className='menu-svg-warp'>
                                                                        <div className='w-6 h-6 flex justify-center items-center'>
                                                                            <div className='w-1 h-1 bg-gray-500 rounded-full'></div>
                                                                        </div>
                                                                    </span>
                                                                    <span className='menu-link-warp flex items-center'>
                                                                        <div className='w-3 h-6 flex items-center'>

                                                                        </div>
                                                                        <div className='-ml-3'>{subItem.title}</div>
                                                                    </span>
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    }
                                                    return '';
                                                })}
                                            </ul>
                                        }
                                    </li>
                                )
                            }
                            return '';
                        })};






                        {/*

                        {localStorage.getItem('admin_role') !== '3' &&
                            <li className='main-page-home'>
                                <NavLink to="/" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <DashboardOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        แดชบอร์ด
                                    </span>
                                </NavLink>
                            </li>
                        }


                        <li>
                            <NavLink to="/product" className={liClass} onClick={handleMenuClick}>
                                <span className='menu-svg-warp'>
                                    <Inventory2OutlinedIcon sx={{ color: iconColor }} />
                                </span>
                                <span className='menu-link-warp'>
                                    รายการสินค้า
                                </span>
                            </NavLink>
                        </li>
                        {localStorage.getItem('admin_role') === '1' || localStorage.getItem('admin_role') === '3' ?
                            <li>
                                <NavLink to="/stock/branches" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <div className='w-6 h-6 flex justify-center items-center'>

                                        </div>
                                    </span>
                                    <span className='menu-link-warp flex items-center'>
                                        <div className='w-3 h-6 flex items-center'>
                                            <div className='w-1 h-1 bg-gray-500 rounded-full'></div>
                                        </div>
                                        คลังสินค้า
                                    </span>
                                </NavLink>
                            </li>

                            : ''
                        }

                        {localStorage.getItem('admin_role') !== '3' &&
                            <li>
                                <NavLink to="/order" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <RequestPageOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        คำสั่งซื้อ
                                    </span>
                                </NavLink>
                            </li>
                        }
                        {localStorage.getItem('admin_role') === '1' &&
                            <li>
                                <NavLink to="/customer" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <PeopleAltOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        ลูกค้า
                                    </span>
                                </NavLink>
                            </li>
                        }
                        {localStorage.getItem('admin_role') === '1' &&
                            <li>
                                <NavLink to="/inquiry" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <Person2OutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        ผู้สนใจสินค้า
                                    </span>
                                </NavLink>
                            </li>
                        }
                        {localStorage.getItem('admin_role') === '1' &&
                            <li>
                                <NavLink to="/complaints" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <GradingOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        เรื่องร้องเรียน
                                    </span>
                                </NavLink>
                            </li>
                        }
                        {localStorage.getItem('admin_role') === '1' &&
                            <li>
                                <NavLink to="/users" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <PermIdentityOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        แอดมิน
                                    </span>
                                </NavLink>
                            </li>
                        }
                        {localStorage.getItem('admin_role') === '1' &&
                            <li>
                                <NavLink to="/message-center" className={liClass} onClick={handleMenuClick}>
                                    <span className='menu-svg-warp'>
                                        <ForumOutlinedIcon sx={{ color: iconColor }} />
                                    </span>
                                    <span className='menu-link-warp'>
                                        ข้อความจากสาขา
                                    </span>
                                </NavLink>
                            </li>
                        }
*/}
                    </ul>



                </div>
            </div>


            <div>
                <ul className='mb-2'>
                    <li className='main-page-home'>
                        <NavLink to="/logout" className={liClass}>
                            <span className='menu-svg-warp'>
                                <LogoutOutlinedIcon sx={{ color: iconColor }} />
                            </span>
                            <span className='menu-link-warp'>
                                ออกจากระบบ
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </aside>
    )
}