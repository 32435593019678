import { useEffect } from "react";
import Layout from "../../layouts/layout";
import BranchInquiry from "../../components/BranchInquiry";
import { useParams } from "react-router-dom";

const EditBranchInquiry = () => {
    const { id } = useParams();

    useEffect(() => {
        document.title = 'งานออนไลน์สาขา';
    }, []);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <BranchInquiry id={id} />
        </Layout>
    );
};

export default EditBranchInquiry;