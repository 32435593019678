import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import {
    Autocomplete, TextField, Button, Drawer,
    CircularProgress
} from "@mui/material";
import { useDropzone } from 'react-dropzone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import api from '../services/apiService';

const BulkImportSatisfactionSurvey = ({ showAlert, fetchSatisfactionSurveys }) => {
    const [data, setData] = useState([]);
    const [loadingState, setLoadingState] = useState({});
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [fileName, setFileName] = useState('');
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [errors, setErrors] = useState({
        branch: null
    });

    const admin_branch_id = parseInt(localStorage.getItem('admin_branch_id'));
    const admin_role = localStorage.getItem('admin_role');

    useEffect(() => {
        setLoading(true);
        api.get('/branch-admin/get-all')
            .then(response => {
                setBranches(response.data.data);
                if (admin_role === '2' && admin_branch_id) {
                    //setValue('branch', response.data.data.find(branch => branch.id === admin_branch_id));
                    setSelectedBranch(response.data.data.find(branch => branch.id === admin_branch_id));
                }
            }).catch(error => {
                console.error('Error fetching branches:', error);
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleFileUpload = async (files) => {
        const file = files[0];
        const fileType = file.name.split('.').pop();

        if (fileType !== 'xlsx' && fileType !== 'xls') {
            showAlert('Invalid file type. Please upload only Excel files.', 'error')
            return;
        }

        setFileName(file.name);

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        let jsonData = [];

        const lastSheetName = workbook.SheetNames[workbook.SheetNames.length - 1];
        const worksheet = workbook.Sheets[lastSheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 0 });

        sheetData.forEach(row => {

            if (row.length >= 3) {

                if (isNaN(row[0])) {
                    return;
                }

                // Clean the full name string by replacing multiple spaces with a single space
                const fullNameCleaned = row[3].replace(/\s+/g, ' ').trim();
                // Extract the name parts
                const nameParts = fullNameCleaned.split(' ');

                // Assume the first two parts are the first name, and the rest are the last name
                const firstName = nameParts.slice(0, 1).join(' ');
                const lastName = nameParts.slice(1, 2).join(' ');

                const addressParts = row[5].split('โทรศัพท์มือถือ');
                const address = addressParts[0].trim();
                const phoneNumber = addressParts.length > 1 ? addressParts[1].replace(/[-()]/g, '').trim() : '';

                jsonData.push({
                    contractNumber: row[0],
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    address: address
                });
            }
        });

        setData(jsonData);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileUpload,
        maxFiles: 1,
    });

    const updateServer = async () => {

        if (!selectedBranch) {
            setErrors(prev => ({ ...prev, branch: 'Please select a branch' }));
            showAlert('Please select a branch', 'error');
            return;
        }


        for (let i = 0; i < data.length; i++) {
            const line = data[i];
            setLoadingState(prev => ({ ...prev, [i]: 'loading' }));

            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/satisfaction-survey/import`, {
                    branch_id: selectedBranch.id,
                    contract_number: line.contractNumber,
                    first_name: line.firstName,
                    last_name: line.lastName,
                    phone_number: line.phoneNumber,
                    address: line.address,
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    setLoadingState(prev => ({ ...prev, [i]: 'success' }));
                }
            } catch (error) {
                setLoadingState(prev => ({ ...prev, [i]: { error: error.response.data.msg } }));
            }
        }

        fetchSatisfactionSurveys();
        showAlert('Data imported successfully', 'success');

    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => {
                    setOpenDrawer(true);
                }}
            >
                Import from Excel
            </Button>

            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={() => { }}
            >

                <div className='flex flex-col justify-between items-center p-4'>

                    <div className='flex justify-between w-full px-4'>
                        <h2 className='text-lg font-bold'>Bulk Import</h2>
                        <button onClick={() => setOpenDrawer(false)}><CloseOutlinedIcon /></button>
                    </div>

                    <div className='container mx-auto p-4' style={{
                        width: '1360px',
                        maxWidth: '100%',
                    }}>
                        <div {...getRootProps()} className='flex justify-center items-center' style={{ border: '1px dashed #ccc', padding: '10px', borderRadius: '5px', height: '100px' }}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>{fileName || "Drag 'n' drop some files here, or click to select files"}</p>
                            }
                        </div>

                        <div className='mt-4'>
                            <Autocomplete
                                options={branches}
                                getOptionLabel={(option) => option.admin_name}
                                isOptionEqualToValue={(option, value) => option.id === value?.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="สาขา"
                                        error={!!errors.branch}
                                        helperText={errors.branch}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}

                                    />
                                )}
                                disabled={admin_role === '2'}
                                value={selectedBranch}
                                onChange={(event, newValue) => {
                                    setSelectedBranch(newValue);
                                    if (newValue) {
                                        setErrors(prev => ({ ...prev, branch: null }));
                                    }
                                }}
                            />
                        </div>

                        <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                            <table className='table-auto w-full'>
                                <thead>
                                    <tr>
                                        <td className='py-2 px-4 border-b font-bold'></td>
                                        <td className='py-2 px-4 border-b font-bold'>เลขที่สัญญา</td>
                                        <td className='py-2 px-4 border-b font-bold'>ชื่อ</td>
                                        <td className='py-2 px-4 border-b font-bold'>นามสกุล</td>
                                        <td className='py-2 px-4 border-b font-bold'>หมายเลขโทรศัพท์</td>
                                        <td className='py-2 px-4 border-b font-bold'>ที่อยู่</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((line, index) => (
                                        <tr key={index} className='hover:bg-gray-100'>
                                            <td className='py-2 px-4 border-b'>
                                                {loadingState[index] === 'loading' && <CircularProgress size={20} />}
                                                {loadingState[index] === 'success' &&
                                                    <Tooltip title="Success" placement="top" arrow >
                                                        <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
                                                    </Tooltip>
                                                }
                                                {loadingState[index]?.error &&
                                                    <Tooltip title={loadingState[index].error} placement="top" arrow >
                                                        <ErrorOutlineOutlinedIcon style={{ color: 'red' }} />
                                                    </Tooltip>
                                                }
                                            </td>
                                            <td className='py-2 px-4 border-b'>{line.contractNumber}</td>
                                            <td className='py-2 px-4 border-b'>{line.firstName}</td>
                                            <td className='py-2 px-4 border-b'>{line.lastName}</td>
                                            <td className='py-2 px-4 border-b'>{line.phoneNumber}</td>
                                            <td className='py-2 px-4 border-b truncate'>
                                                <div className='max-w-[300px] truncate'>{line.address}</div>
                                            </td>
                                            <td className='py-2 px-4 border-b text-right'>{line.stock}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='flex justify-end w-full mt-4 gap-4 sticky bottom-0 py-2 px-4 bg-white'>
                        <Button
                            onClick={() => setData([])}
                        >
                            ล้างข้อมูล
                        </Button>
                        <Button
                            onClick={() => setOpenDrawer(false)}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={updateServer}
                        >
                            บันทึกข้อมูล
                        </Button>
                    </div>

                </div>

            </Drawer>
        </>
    );
};

export default BulkImportSatisfactionSurvey;