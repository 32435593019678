import { useContext, useEffect, useState, useCallback, useRef } from "react";
import Layout from "../../layouts/layout";
import LoadingContext from '../../contexts/LoadingContext';
import { useAlertSnackbar } from '../../contexts/AlertSnackbarContext';
import { useNavigate } from "react-router-dom";
import api from '../../services/apiService';
import {
    Button, IconButton, Tooltip, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ExportSatisfactionSurvey from "../../components/ExportSatisfactionSurvey";
import { debounce } from 'lodash';
import BulkImportSatisfactionSurvey from "../../components/BulkImportSatisfactionSurvey";

const SatisfactionSurvey = () => {

    const navigate = useNavigate();
    const { setIsLoading } = useContext(LoadingContext);
    const { showAlert } = useAlertSnackbar();
    const [satisfactionSurveys, setSatisfactionSurveys] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [deleteId, setDeleteId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const initialLoad = useRef(true);

    const fetchSatisfactionSurveys = async (searchText = '', pageNumber = 1) => {
        setIsLoading(true);
        try {
            const response = await api.get(`/satisfaction-survey/get-all/${pageNumber}`, {
                params: {
                    page: pageNumber,
                    perPage: 20,
                    search: searchText,
                    sort: 'id',
                    direction: 'asc',
                },
            });
            setSatisfactionSurveys(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error('Error fetching satisfaction surveys:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchSatisfactionSurveys = useCallback(debounce((searchText, pageNumber) => {
        fetchSatisfactionSurveys(searchText, pageNumber);
    }, 300), []);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            fetchSatisfactionSurveys(search, page);
        } else {
            debouncedFetchSatisfactionSurveys(search, page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, debouncedFetchSatisfactionSurveys]);

    useEffect(() => {
        fetchSatisfactionSurveys(search, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'บันทึกข้อมูลการ Call center ก่อนการอนุมัติออเดอร์/ความพึงพอใจหลังการส่งสินค้า';
    }, []);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const fetchNextPage = (e, value) => {
        setPage(value);
    };

    const requestDelete = async (id) => {
        try {
            setIsLoading(true);
            await api.delete(`/satisfaction-survey/delete/${id}`);
            setSatisfactionSurveys((prev) => prev.filter((item) => item.id !== id));
            showAlert('ลบข้อมูลเรียบร้อยแล้ว', 'success');
        } catch (error) {
            console.error('Error deleting satisfaction survey:', error);
            showAlert('เกิดข้อผิดพลาดในการลบข้อมูล', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleOpenDelete = (id) => {
        setDeleteId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setDeleteId(null);
    };

    const statusToColor = (status) => {
        switch (status) {
            case 'pending':
                return 'bg-yellow-400';
            case 'success':
                return 'bg-green-400';
            default:
                return 'bg-gray-400';
        }
    }

    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4 items-center'>
                        <div className='relative'>
                            <input
                                type='text'
                                className='appearance-none block w-full py-2 pl-4 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:shadow-outline'
                                placeholder='ค้นหา...'
                                value={search}
                                onChange={handleSearchChange}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end items-center gap-2">
                        <BulkImportSatisfactionSurvey showAlert={showAlert} fetchSatisfactionSurveys={fetchSatisfactionSurveys} />
                        <ExportSatisfactionSurvey />
                        <Button
                            variant="contained"
                            onClick={() => navigate('/satisfaction-survey/add')}
                        >
                            + เพิ่มข้อมูล
                        </Button>
                    </div>

                </div>

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-1/6 font-bold'>ลูกค้า</div>
                            <div className='w-1/6 text-left font-bold'>เลขที่สัญญา</div>
                            <div className='w-1/6 text-left font-bold'>หมายเลขโทรศัพท์</div>
                            <div className='w-1/6 text-left font-bold'>สาขา</div>
                            <div className='w-1/6 text-right font-bold'>วันที่</div>
                            <div className='w-1/6 text-right font-bold'>สถานะ</div>
                            <div className='w-1/6 text-right font-bold'>จัดการ</div>
                        </div>
                        {satisfactionSurveys.length > 0 ? Object.entries(satisfactionSurveys).map(([k, item]) => (
                            <div
                                className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${k % 2 === 0 ? 'bg-gray-50/50' : ''}`}
                                key={k}
                            >
                                <div className='w-1/6'>{item.survey_firstname} {item.survey_lastname}</div>
                                <div className='w-1/6'>{item.survey_contract_number}</div>
                                <div className='w-1/6'>{item.survey_phone}</div>
                                <div className='w-1/6'>{item.admin_name}</div>
                                <div className='w-1/6 text-right'>{
                                    new Date(item.survey_date).toLocaleDateString('th-TH', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })
                                }</div>
                                <div className='w-1/6 text-right flex gap-2 justify-end items-center'>
                                    <div className={`rounded-full h-4 w-4 inline-block ${statusToColor(item.survey_status)}`}>
                                    </div>
                                    <span className=" capitalize">{item.survey_status}</span>
                                </div>
                                <div className='w-1/6 text-right flex justify-end gap-2'>
                                    <Tooltip title="ดู / แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => navigate(`/satisfaction-survey/edit/${item.id}`)}>
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => handleOpenDelete(item.id)}>
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) : (
                            <div className='py-2 px-4 w-full text-center'>ไม่พบข้อมูล</div>
                        )}
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page} / {totalPages}</div>
                    <Pagination count={totalPages} page={page} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ข้อมูลจะถูกลบ และไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลนี้ใช่หรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>ยกเลิก</Button>
                    <Button onClick={() => {
                        requestDelete(deleteId);
                        handleCloseDelete();
                    }} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

        </Layout>
    );
}

export default SatisfactionSurvey;
