import { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function ProductFilterList(props) {

    const { defaultCheckedItems, onItemsSelected } = props;
    const [filters, setFilters] = useState({});
    const [newItems, setNewItems] = useState({});
    const [showInputs, setShowInputs] = useState({});
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ open: false, message: '' });
    const [selectedItems, setSelectedItems] = useState({});

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/getfilters`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            const fetchedFilters = response.data;

            if (defaultCheckedItems) {
                // Modify the fetched filters according to defaultCheckedItems.
                for (const [filterName, items] of Object.entries(defaultCheckedItems)) {
                    if (fetchedFilters[filterName]) {
                        for (const [itemId, isChecked] of Object.entries(items)) {
                            const itemIndex = fetchedFilters[filterName].findIndex(item => item.id === parseInt(itemId));
                            if (itemIndex !== -1) {
                                fetchedFilters[filterName][itemIndex].checked = isChecked;
                            }
                        }
                    }
                }
                setSelectedItems(defaultCheckedItems);
            }

            setFilters(fetchedFilters);

            setLoading(false);
        });
    }, [defaultCheckedItems]);


    /*
    useEffect(() => {
        if (productData) {
            // Set the initial state of the filters with the product data.
            // Assume productData has the necessary data you need for the filters.
            setFilters(productData);
        }
    }, [productData]);
    */


    const handleCheckboxChange = (filterName, itemId) => {
        setFilters(prevFilters => {
            const newFilters = {
                ...prevFilters,
                [filterName]: prevFilters[filterName].map(item =>
                    item.id === itemId ? { ...item, checked: !item.checked } : item
                )
            };

            // Construct the new selectedItems object.
            const newSelectedItems = { ...selectedItems };
            newFilters[filterName].forEach(item => {
                if (item.checked) {
                    newSelectedItems[filterName] = {
                        ...newSelectedItems[filterName],
                        [item.id]: item.checked,
                    };
                } else if (newSelectedItems[filterName] && newSelectedItems[filterName][item.id]) {
                    delete newSelectedItems[filterName][item.id];
                }
            });

            setSelectedItems(newSelectedItems);
            return newFilters;
        });
    };

    useEffect(() => {
        if (typeof onItemsSelected === 'function') {
            onItemsSelected(selectedItems);
        }
    }, [selectedItems, onItemsSelected]);


    const handleAddItem = (filterName) => {
        if (newItems[filterName] === '') {
            setAlert({ open: true, message: 'กรุณากรอกชื่อ' });
            return;
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/filter/add`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: { filterName, itemName: newItems[filterName] }
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    setFilters(prevFilters => ({
                        ...prevFilters,
                        [filterName]: [...prevFilters[filterName], { id: response.data.id, name: newItems[filterName] }]
                    }));
                } else {
                    setAlert({ open: true, message: response.data.msg });
                }
            })
            .catch(function (error) {
                setAlert({ open: true, message: error.message });
            });

        setNewItems(prevItems => ({ ...prevItems, [filterName]: '' }));
        setShowInputs(prevInputs => ({ ...prevInputs, [filterName]: false }));
    };

    const handleNewItemChange = (filterName, event) => {
        setNewItems(prevItems => ({ ...prevItems, [filterName]: event.target.value }));
    };

    const handleAddButtonClick = (filterName) => {
        setShowInputs(prevInputs => ({ ...prevInputs, [filterName]: true }));
    };

    const handleAlertClose = () => {
        setAlert({ open: false, message: '' });
    };

    const renderNestedList = (items, parentId = 0, depth = 0) => {
        return items
            .filter(item => item.parent === parentId)
            .map((item, index) => (
                <div key={`${item.id}-${depth}-${index}`} style={{ marginLeft: `${depth * 20}px` }}>
                    <label className='flex gap-2  mb-1'>
                        <input
                            type="checkbox"
                            checked={!!item.checked}
                            onChange={() => handleCheckboxChange('หมวดหมู่', item.id)}
                        />
                        {item.name}
                    </label>
                    {renderNestedList(items, item.id, depth + 1)}
                </div>
            ));
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                Object.entries(filters).map(([filterName, items]) => (
                    <div className='p-8 bg-white mb-4' key={filterName}>
                        <div key={filterName}>
                            <label className='font-bold mb-2 block border-b text-lg'>{filterName}</label>
                            <div className='h-48 overflow-scroll'>
                                {filterName === 'หมวดหมู่' ? renderNestedList(items) : items.map((item, index) => (
                                    <div key={index + item.id}>
                                        <label className='flex gap-2 mb-1'>
                                            <input
                                                type="checkbox"
                                                checked={!!item.checked}
                                                onChange={() => handleCheckboxChange(filterName, item.id)}
                                            />
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button className='text-indigo-800 font-bold' onClick={() => handleAddButtonClick(filterName)}>+ เพิ่ม{filterName}ใหม่</button>
                            {showInputs[filterName] && (
                                <div className='flex'>
                                    <input
                                        className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 appearance-none focus:outline-none focus:shadow-outline'
                                        type="text"
                                        value={newItems[filterName] || ''}
                                        onChange={(event) => handleNewItemChange(filterName, event)}
                                    />
                                    <button className='border border-gray-300 text-gray-900 p-2.5 px-4 border-l-0' onClick={() => handleAddItem(filterName)}>เพิ่ม</button>
                                </div>
                            )}
                        </div>
                    </div>
                ))
            )}
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    );
}
