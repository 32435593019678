// src/services/apiService.js
import axios from 'axios';

// Create an Axios instance with default configuration
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // Base URL for all requests
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        //'Authorization': 'Bearer ' + localStorage.getItem('token') // Default Authorization header with token from localStorage
    },
    // You can add more default configurations here
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('admin_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    return response;
}, (error) => {

    // Global error handling strategy
    if (error.response.status === 401) {
        // Unauthorized error
        
        
        //localStorage.removeItem('token');
        //localStorage.removeItem('userData');
        //window.location.href = '/login';
        
    }
    return Promise.reject(error);
});

export default api;
