import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from "../layouts/layout"
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AutoFixNormalOutlinedIcon from '@mui/icons-material/AutoFixNormalOutlined';

const Users = () => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [deleteId, setDeleteId] = useState(null);
    let page = useRef(1);
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [openDialog, setOpenialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [userDetail, setUserDetail] = useState({
        id: '',
        name: '',
        email: '',
        password: '',
        role: '',
    });
    const resetUserDetail = () => {
        setUserDetail({
            id: '',
            name: '',
            email: '',
            password: '',
            role: '',
        });
    }

    const fetchcontents = useCallback((value = 1, search = null) => {
        setIsLoading(true)
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/get-all/${value}`;
        if (search) {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/get-all/${value}?search=${search}`;
        }
        axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.all);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const converAdminRole = (role) => {
        switch (role) {
            case 1:
                return { roleName: 'แอดมินกลาง', roleColor: 'bg-indigo-800', roleTextColor: 'text-white' };
            case 2:
                return { roleName: 'แอดมินสาขา', roleColor: 'bg-gray-500', roleTextColor: 'text-white' };
            case 3:
                return { roleName: 'สินค้า และสต็อกสินค้า', roleColor: 'bg-green-800', roleTextColor: 'text-white' };
            case 4:
                return { roleName: 'เรื่องร้องเรียน', roleColor: 'bg-red-800', roleTextColor: 'text-white' };
            case 5:
                return { roleName: 'แบบสอบถามความพึงพอใจ', roleColor: 'bg-yellow-800', roleTextColor: 'text-white' };
            case 6:
                return { roleName: 'gseinsurebroker.com', roleColor: 'bg-blue-800', roleTextColor: 'text-white' };
            default:
                return { roleName: 'แอดมิน', roleColor: 'bg-indigo-800', roleTextColor: 'text-white' };
        }
    }

    const handleDelete = (id) => {
        setIsLoading(true);

        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/admin/${id}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                fetchcontents();
                setOpenialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลเรียบร้อยแล้ว'
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            let errorMessage = error.response && error.response.data.msg ? error.response.data.msg : error.toString();
            setAlert({
                open: true,
                type: 'error',
                msg: errorMessage
            });
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const validateInput = (bypass) => {
        if (userDetail.name === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกชื่อแอดมิน'
            });
            return false;
        }
        if (userDetail.email === '' || !emailRegex.test(userDetail.email)) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'รูปแบบอีเมลไม่ถูกต้อง'
            });
            return false;
        }
        if (userDetail.password === '' && bypass === 'add') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกรหัสผ่าน'
            });
            return false;
        }
        if (userDetail.role === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณาเลือกสิทธิการใช้งาน'
            });
            return false;
        }
        return true;
    }

    const handleAddnewOrUpdate = (addOrUpdate) => {
        if (!validateInput(addOrUpdate)) {
            return;
        }
        let url = `${process.env.REACT_APP_BACKEND_URL}/admin/add`;
        let method = 'post';
        if (addOrUpdate === 'update') {
            url = `${process.env.REACT_APP_BACKEND_URL}/admin/update`;
            method = 'put';
        }
        setIsLoading(true);

        axios({
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: JSON.stringify(userDetail)
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                fetchcontents();
                setOpenAddDialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'บันทึกข้อมูลสำเร็จ'
                });
                resetUserDetail();
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        }).catch(function (error) {
            setIsLoading(false);
            let errorMessage = error.response && error.response.data.msg ? error.response.data.msg : error.toString();
            setAlert({
                open: true,
                type: 'error',
                msg: errorMessage
            });
        });
    }

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchcontents(1, searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            fetchcontents();
            return;
        }
        debouncedFetchContents(value);
    }


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4 items-center'>
                        <h1 className='text-2xl font-bold hidden lg:block'>แอดมิน</h1>
                        <input
                            type='search'
                            className='border border-gray-300 rounded-md p-2 outline-none'
                            placeholder='ค้นหา'
                            onChange={handleDebonceSearch}
                        />
                    </div>
                    <div className='flex gap-4'>
                        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>+ เพิ่มแอดมิน</Button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-2/12  font-bold'>ชื่อแอดมิน</div>
                            <div className='w-4/12  font-bold'>อีเมล</div>
                            <div className='w-2/12  font-bold'>ถูกสร้างเมื่อ</div>
                            <div className='w-2/12 text-right font-bold'>สิทธิการใช้งาน</div>
                            <div className='w-2/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-2/12 ' onClick={() => {
                                    setUserDetail({
                                        id: parseInt(content.id),
                                        name: content.admin_name,
                                        email: content.admin_username,
                                        password: '',
                                        role: content.admin_role,
                                    });
                                    setOpenAddDialog(true);
                                }}>{content.admin_name}</div>
                                <div className='w-4/12 '>{content.admin_username}</div>
                                <div className='w-2/12 '>
                                    {
                                        new Date(content.create_at).toLocaleDateString('th-TH', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })
                                    }
                                </div>
                                <div className='w-2/12 text-right'>
                                    <span className={`px-2 py-1 rounded-md ${converAdminRole(content.admin_role).roleColor} ${converAdminRole(content.admin_role).roleTextColor}`}>
                                        {converAdminRole(content.admin_role).roleName}
                                    </span>
                                </div>
                                <div className='w-2/12 flex justify-end gap-2'>
                                    <Tooltip title="แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            setUserDetail({
                                                id: parseInt(content.id),
                                                name: content.admin_name,
                                                email: content.admin_username,
                                                password: '',
                                                role: content.admin_role,
                                            });
                                            setOpenAddDialog(true);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            setDeleteId(content.id);
                                            setOpenialog(true);
                                        }}>
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                open={openAddDialog}
                onClose={() => { setOpenAddDialog(false); resetUserDetail(); }}
            >
                <DialogTitle>
                    {userDetail.id === '' ? 'เพิ่มแอดมิน' : 'แก้ไขแอดมิน'}
                </DialogTitle>
                <DialogContent>
                    <div className='flex flex-col gap-4 w-96 max-w-full' >
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>ชื่อแอดมิน</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded-md p-2 outline-none'
                                value={userDetail.name} onChange={(e) => setUserDetail({ ...userDetail, name: e.target.value })}
                                placeholder='กรอกชื่อแอดมิน'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>อีเมลสำหรับเข้าสู่ระบบ</label>
                            <input
                                type='text'
                                className='border border-gray-300 rounded-md p-2 outline-none'
                                value={userDetail.email} onChange={(e) => setUserDetail({ ...userDetail, email: e.target.value })}
                                placeholder='กรอกอีเมลสำหรับเข้าสู่ระบบ'
                            />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>รหัสผ่าน</label>
                            <div className='relative'>
                                <input
                                    type='text'
                                    className='border border-gray-300 rounded-md p-2 w-full outline-none'
                                    value={userDetail.password} onChange={(e) => setUserDetail({ ...userDetail, password: e.target.value })}
                                    placeholder={userDetail.id === '' ? 'กรอกรหัสผ่าน' : 'ปล่อยว่างไว้หากไม่ต้องการเปลี่ยนรหัสผ่าน'}
                                />
                                <div className='absolute top-0 right-0'>
                                    <Tooltip title="สุ่มรหัสผ่าน" placement="top" arrow >
                                        <IconButton aria-label="random" onClick={() => {
                                            let randomPassword = Math.random().toString(36).slice(-8);
                                            setUserDetail({ ...userDetail, password: randomPassword });
                                        }}>
                                            <AutoFixNormalOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <label className='font-bold'>สิทธิการใช้งาน</label>
                            <select className='border border-gray-300 rounded-md p-2' value={userDetail.role} onChange={(e) => setUserDetail({ ...userDetail, role: e.target.value })}>
                                <option value=''>เลือกสิทธิการใช้งาน</option>
                                <option value='1'>แอดมินกลาง</option>
                                <option value='2'>แอดมินสาขา</option>
                                <option value='3'>สินค้า และสต็อกสินค้า</option>
                                <option value='4'>เรื่องร้องเรียน</option>
                                <option value='5'>แบบสอบถามความพึงพอใจ</option>
                                <option value='6'>gseinsurebroker.com</option>
                            </select>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenAddDialog(false); resetUserDetail(); }}>ยกเลิก</Button>
                    <Button
                        disabled={isLoading}
                        onClick={() => {
                            if (userDetail.id === '') {
                                handleAddnewOrUpdate('add');
                            } else {
                                handleAddnewOrUpdate('update');
                            }
                        }}
                    >
                        ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog}
                onClose={() => { setOpenialog(false); }}
            >
                <DialogTitle>
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    เมื่อลบข้อมูลแล้วจะไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลใช่หรือไม่?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenialog(false); }}>ยกเลิก</Button>
                    <Button onClick={() => handleDelete(deleteId)}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>


            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default Users;