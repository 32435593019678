import { useState, useEffect, useContext, useRef } from 'react';
import api from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import LoadingContext from '../contexts/LoadingContext';
import { useAlertSnackbar } from '../contexts/AlertSnackbarContext';
import {
    Autocomplete, TextField, Button, FormControl, FormLabel,
    FormControlLabel, FormHelperText, RadioGroup, Radio, CircularProgress
} from "@mui/material";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const BranchInquiry = ({ id }) => {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { showAlert } = useAlertSnackbar();
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);

    const admin_branch_id = parseInt(localStorage.getItem('admin_branch_id'));
    const admin_role = localStorage.getItem('admin_role');

    const source = [
        { label: 'ทองคำรูปพรรณ', value: 'ทองคำรูปพรรณ' },
        { label: 'เครื่องใช้ไฟฟ้า', value: 'เครื่องใช้ไฟฟ้า' },
        { label: 'สินเชื่อนาโนไฟแนนซ์', value: 'สินเชื่อนาโนไฟแนนซ์' },
        { label: 'ประกันภัยรถยนต์', value: 'ประกันภัยรถยนต์' },
    ];

    useEffect(() => {
        setLoading(true);
        api.get('/branch-admin/get-all')
            .then(response => {
                setBranches(response.data.data);
                if (admin_role === '2' && admin_branch_id && !id) {
                    setValue('inquiry_branch', response.data.data.find(branch => branch.id === admin_branch_id));
                }
            }).catch(error => {
                console.error('Error fetching branches:', error);
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const validationSchema = yup.object().shape({
        inquiry_branch: yup.object().shape({
            id: yup.string().required('กรุณาเลือกสาขา'),
            admin_name: yup.string().required('กรุณาเลือกสาขา'),
            admin_username: yup.string().required('กรุณาเลือกสาขา'),
            admin_role: yup.string().required('กรุณาเลือกสาขา'),
        }).required('กรุณาเลือกสาขา'),
        service_line: yup.string().required('กรุณากรอกสายบริการ'),
        firstname: yup.string().required('กรุณากรอกชื่อ'),
        lastname: yup.string().required('กรุณากรอกนามสกุล'),
        phone_number: yup.string().required('กรุณากรอกหมายเลขโทรศัพท์'),
        inquiry_product: yup.string().required('กรุณาเลือกสินค้าที่ต้องการ'),
    });

    const handleFormSubmit = async (data) => {

        setIsLoading(true);
        try {
            if (id) {
                await api.put(`/branch-inquiry/update/${id}`, data);
                showAlert('อัปเดตข้อมูลเรียบร้อยแล้ว', 'success');
            } else {
                await api.post('/branch-inquiry/create', data);
                showAlert('บันทึกข้อมูลเรียบร้อยแล้ว', 'success');
                navigate('/branch-inquiry');
            }
        } catch (error) {
            showAlert(error.response?.data?.message || 'Error adding item', 'error');
        } finally {
            setIsLoading(false);
        }
    };


    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            inquiry_branch: null,
            service_line: '',
            firstname: '',
            lastname: '',
            phone_number: '',
            inquiry_product: '',
        },
    });

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            api.get(`/branch-inquiry/single/${id}`)
                .then(response => {
                    const data = response.data.data;
                    setValue('inquiry_branch', data.branch);
                    setValue('service_line', data.service_line);
                    setValue('firstname', data.firstname);
                    setValue('lastname', data.lastname);
                    setValue('phone_number', data.phone_number);
                    setValue('inquiry_product', data.inquiry_product);
                 
                }).catch(error => {
                    console.error('Error fetching satisfaction survey:', error);
                    showAlert('Error fetching satisfaction survey', 'error');
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = handleSubmit(handleFormSubmit, (errors) => {
        if (errors) {
            const firstError = Object.keys(errors)[0];
            //console.log('firstError:', firstError);
            const errorElement = formRef.current.querySelector(`[name=${firstError}]`);
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                //showAlert(errors[firstError].message, 'error');
            } else {
                formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    });

    return (
        <div className="max-w-xl w-full mx-auto">

            <button
                className='flex gap-2 items-center mb-3 text-black'
                onClick={() => navigate('/branch-inquiry')}
            >
                <ArrowBackOutlinedIcon />
                <span>Back</span>
            </button>

            <div className="bg-white rounded-lg p-6">
                <form onSubmit={(event) => { event.preventDefault(); onSubmit(event); }} ref={formRef}>

                    <div className="mb-6">
                        <Controller
                            name="inquiry_branch"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={branches}
                                    getOptionLabel={(option) => option.admin_name}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="สาขา"
                                            error={!!errors.inquiry_branch}
                                            helperText={errors.inquiry_branch?.message}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    value={field.value || null}
                                    onChange={(event, newValue) => field.onChange(newValue)}
                                />
                            )}
                        />
                    </div>

                    <div className="mb-6">
                        <Controller
                            name="service_line"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="สายบริการ"
                                    error={!!errors.service_line}
                                    helperText={errors.service_line?.message}
                                    fullWidth
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <Controller
                            name="firstname"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="ชื่อ"
                                    error={!!errors.firstname}
                                    helperText={errors.firstname?.message}
                                    fullWidth
                                    value={field.value || ''}
                                />
                            )}
                        />
                        <Controller
                            name="lastname"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="นามสกุล"
                                    error={!!errors.lastname}
                                    helperText={errors.lastname?.message}
                                    fullWidth
                                    value={field.value || ''}
                                />
                            )}
                        />
                    </div>
                    <div className='mb-6'>
                        <Controller
                            name="phone_number"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="หมายเลขโทรศัพท์"
                                    error={!!errors.phone_number}
                                    helperText={errors.phone_number?.message}
                                    fullWidth
                                    value={field.value || ''}
                                    inputProps={{
                                        maxLength: 10,
                                        pattern: "\\d*",
                                        inputMode: "numeric"
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const onlyNums = value.replace(/[^0-9]/g, '');
                                        field.onChange(onlyNums);
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className='mb-6'>
                        <Controller
                            name="inquiry_product"
                            control={control}
                            render={({ field }) => (
                                <FormControl error={!!errors.inquiry_product}>
                                    <FormLabel component="legend">สินค้าที่ต้องการ</FormLabel>
                                    <RadioGroup
                                        {...field}
                                        row
                                        aria-label="inquiry_product"
                                        name="inquiry_product"
                                        value={field.value || ''}
                                        onChange={(event) => field.onChange(event.target.value)}
                                    >
                                        {source.map((item, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={item.value}
                                                control={<Radio />}
                                                label={item.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                    <FormHelperText>{errors.inquiry_product?.message}</FormHelperText>
                                </FormControl>
                            )}
                        />
                    </div>

                    <div className="mb-4">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            fullWidth
                            size='large'
                            disableElevation
                        >
                            บันทึกข้อมูล
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BranchInquiry;