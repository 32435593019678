import { useState, useContext } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField
} from "@mui/material";
import LoadingContext from '../contexts/LoadingContext';
import api from '../services/apiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import * as XLSX from 'xlsx';

const ExportSatisfactionSurvey = () => {

    const adminRole = parseInt(localStorage.getItem('admin_role'));
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const [openExport, setOpenExport] = useState(false);

    const handleExport = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/satisfaction-survey/export', {
                params: {
                    start: startDate.format('YYYY-MM-DD'),
                    end: endDate.format('YYYY-MM-DD'),
                },
            });

            const data = response.data.data;
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Satisfaction Survey');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Satisfaction_Survey_${startDate.format('YYYY-MM-DD')}_to_${endDate.format('YYYY-MM-DD')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        } catch (error) {
            console.error('Error exporting satisfaction survey:', error);
        } finally {
            setIsLoading(false);
            setOpenExport(false);
        }
    }

    return (
        <>
            {[1,2,5].includes(adminRole) &&
                <Button
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                        setOpenExport(true);
                    }}
                >
                    Export as Excel
                </Button>
            }

            <Dialog
                open={openExport}
                onClose={() => setOpenExport(false)}
            >
                <DialogTitle>Export as Excel</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the date range to export.
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        <div className="flex justify-between gap-3 mt-8">
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenExport(false)}>Cancel</Button>
                    <Button
                        onClick={handleExport}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Exporting...' : 'Export'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ExportSatisfactionSurvey;