import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import tambonData from '../components/province/tambon.json';
import amphureData from '../components/province/amphure.json';
import provinceData from '../components/province/province.json';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';
//import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
//import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const MultiLevelSelect = ({ customerAddress, setCustomerAddress, inputError, setInputError }) => {

    const [tambonInput, setTambonInput] = useState('');
    const [amphureInput, setAmphureInput] = useState('');
    const [provinceInput, setProvinceInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedTambon, setSelectedTambon] = useState(null);
    //const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        /*
        if (customerAddress.sub_district) {
            const foundTambon = tambonData.find(
                (tambon) => tambon.name_th === customerAddress.sub_district
            );
            setTambonInput(foundTambon.name_th);
            setSelectedTambon(foundTambon);
        }
        */
        if (selectedTambon) {
            const foundAmphure = amphureData.find(
                (amphure) => amphure.id === selectedTambon.amphure_id
            );
            setAmphureInput(foundAmphure.name_th);
            setCustomerAddress(prev => ({
                ...prev,
                district: foundAmphure.name_th
            }))

            const foundProvince = provinceData.find(
                (province) => province.id === foundAmphure.province_id
            );
            setProvinceInput(foundProvince.name_th);
            setCustomerAddress(prev => ({
                ...prev,
                province: foundProvince.name_th
            }))

            setInputError(prev => ({
                ...prev,
                district: false,
                province: false
            }))

        } else {
            setAmphureInput('');
            setProvinceInput('');
        }
    }, [selectedTambon, setCustomerAddress, setInputError]);

    const onTambonChange = (event, { newValue }) => {
        setTambonInput(newValue);
        setCustomerAddress(prev => ({
            ...prev,
            sub_district: newValue
        }))
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getTambonSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (event, { suggestion }) => {
        setSelectedTambon(suggestion);
        if (suggestion.length < 1) {
            setInputError(prev => ({
                ...prev,
                sub_district: true
            }))
        } else {
            setInputError(prev => ({
                ...prev,
                sub_district: false
            }))
        }
    };

    const getTambonSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength < 3
            ? []
            : tambonData.filter(
                (tambon) => tambon.name_th.toLowerCase().slice(0, inputLength) === inputValue
            ).map((tambon) => {
                const amphure = amphureData.find((a) => a.id === parseInt(tambon.amphure_id, 10));
                if (!amphure) {
                    return tambon;
                }

                const province = provinceData.find((p) => p.id === parseInt(amphure.province_id, 10));
                if (!province) {
                    return {
                        ...tambon,
                        amphure_name_th: amphure.name_th,
                    };
                }

                return {
                    ...tambon,
                    province_name_th: province.name_th,
                    amphure_name_th: amphure.name_th,
                };
            });
    };

    const getSuggestionValue = (suggestion) => suggestion.name_th;

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.name_th}{' '}
            <span className="text-gray-500 text-sm">
                ({suggestion.amphure_name_th}, {suggestion.province_name_th})
            </span>
        </div>
    );

    const inputProps = {
        placeholder: 'ตำบล / แขวง',
        value: tambonInput,
        onChange: onTambonChange,
    };

    const theme = {
        container: 'relative',
        input: 'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
        inputFocused: 'outline-none',
        suggestionsContainer: 'absolute z-10 mt-1 w-full bg-white shadow rounded max-h-64 overflow-y-auto',
        suggestionsList: 'm-0 p-0 list-none',
        suggestion: 'cursor-pointer px-4 py-2 hover:bg-gray-200',
        suggestionHighlighted: 'bg-gray-200',
    };

    return (
        <div className="w-full ">


            <div className="flex gap-4">
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="customer-name" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        ชื่อจริง: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="customer-name"
                        placeholder='ชื่อจริง'
                        type="text"
                        autoComplete="off"
                        value={customerAddress.fname}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                fname: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    fname: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    fname: false
                                }))
                            }
                        }}
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                    />
                    {inputError.fname ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกชื่อ</div> : ''}
                </div>
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="lastname-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        นามสกุล: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="lastname-input"
                        autoComplete="off"
                        placeholder='นามสกุล'
                        type="text"
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        value={customerAddress.lname}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                lname: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    lname: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    lname: false
                                }))
                            }
                        }}
                    />
                    {inputError.lname ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกนามสกุล</div> : ''}
                </div>
            </div>



            <div className="flex gap-4">
                <div className="mb-4 w-full">
                    <label htmlFor="company" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        อาชีพ
                    </label>
                    <input
                        id="company"
                        autoComplete="off"
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        placeholder="อาชีพ"
                        value={customerAddress.company !== '' ? customerAddress.company : ''}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                company: e.target.value
                            }))
                        }}
                    />
                    {inputError.company ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกอาชีพ</div> : ''}
                </div>
            </div>

            <div className="flex gap-4">
                <div className="mb-4 w-full">
                    <label htmlFor="address" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        ที่อยู่จัดส่งสินค้า: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <textarea
                        id="address"
                        autoComplete="off"
                        rows="2"
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800" 
                        placeholder="ที่อยู่สำหรับจัดส่งสินค้า"
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                address: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    address: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    address: false
                                }))
                            }
                        }}
                        value={customerAddress.address !== '' ? customerAddress.address : ''}
                    >
                    </textarea>
                    {inputError.address ? <div className='text-red-700 border-t border-red-500 -mt-1.5'>กรุณากรอกที่อยู่</div> : ''}
                </div>
            </div>

            <div className="flex gap-4">
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="tambon-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        ตำบล / แขวง:  <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <Autosuggest
                        id="tambon-input"
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            ...inputProps,
                            className: 'appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800',
                            value: tambonInput || customerAddress.sub_district,
                        }}
                        theme={theme}
                        onSuggestionSelected={handleSuggestionSelected}
                    />
                    {inputError.sub_district ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกตำบล / แขวง</div> : ''}
                </div>
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="amphure-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        อำเภอ / เขต: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="amphure-input"
                        placeholder='อำเภอ / เขต'
                        type="text"
                        value={amphureInput || customerAddress.district}
                        //readOnly
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                district: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    district: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    district: false
                                }))
                            }
                        }}
                    />
                    {inputError.district ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกอำเภอ / เขต</div> : ''}
                </div>

            </div>
            <div className="flex gap-4">
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="province-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        จังหวัด: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="province-input"
                        placeholder='จังหวัด'
                        type="text"
                        value={provinceInput || customerAddress.province}
                        //readOnly
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                province: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    province: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    province: false
                                }))
                            }
                        }}
                    />
                    {inputError.province ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกจังหวัด</div> : ''}
                </div>
                <div className="mb-4 w-full lg:w-1/2">
                    <label htmlFor="post-code-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        รหัสไปรษณีย์: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="post-code-input"
                        placeholder='รหัสไปรษณีย์'
                        type="text"
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        value={customerAddress.post_code}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                post_code: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    post_code: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    post_code: false
                                }))
                            }
                        }}
                    />
                    {inputError.post_code ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกรหัสไปรษณีย์</div> : ''}
                </div>
            </div>
            <div className="flex gap-4">
                <div className="mb-4 w-full">
                    <label htmlFor="customer-phone" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        หมายเลขโทรศัพท์: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="customer-phone"
                        placeholder='หมายเลขโทรศัพท์'
                        type="tel"
                        autoComplete="off"
                        value={customerAddress.phone}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                phone: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    phone: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    phone: false
                                }))
                            }
                        }}
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                    />
                    {inputError.phone ? <div className='text-red-700 border-t border-red-500'>กรุณากรอกหมายเลขโทรศัพท์</div> : ''}
                </div>
            </div>

            {/*
            <div className="flex gap-4">
                <div className="mb-4 w-full ">
                    <label htmlFor="email-input" className="block text-gray-500 text-xs mb-2 pl-2.5">
                        อีเมล: <abbr className="required" title="ต้องการ">*</abbr>
                    </label>
                    <input
                        id="email-input"
                        autoComplete="off"
                        placeholder='อีเมล'
                        type="email"
                        className="appearance-none border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-800"
                        value={customerAddress.email}
                        onChange={(e) => {
                            setCustomerAddress(prev => ({
                                ...prev,
                                email: e.target.value
                            }))
                            let val = e.target.value;
                            if (val.length < 1) {
                                setInputError(prev => ({
                                    ...prev,
                                    email: true
                                }))
                            } else {
                                setInputError(prev => ({
                                    ...prev,
                                    email: false
                                }))
                            }
                        }}
                    />
                    {inputError.email ? <div className='text-red-700 border-t border-red-500'>รูปแบบอีเมลไม่ถูกต้อง</div> : ''}
                </div>
            </div>

            <div className="flex gap-4">
                <div className='mb-4 w-full'>
                    <div className="relative border-b focus:border-indigo-800 py-2 ">
                        <label className="block text-gray-500 text-xs mb-2 pl-2.5">
                            ตั้งรหัสผ่านเข้าสู่ระบบ: <abbr className="required" title="ต้องการ">*</abbr>
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder='รหัสผ่าน ความยาว 8 ตัวอักษรขึ้นไป'
                            className="appearance-none w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline px-3 "
                            value={customerAddress.password}
                            onChange={(e) => {
                                setCustomerAddress(prev => ({
                                    ...prev,
                                    password: e.target.value
                                }))
                                let val = e.target.value;
                                if (val.length < 1) {
                                    setInputError(prev => ({
                                        ...prev,
                                        password: true
                                    }))
                                } else {
                                    setInputError(prev => ({
                                        ...prev,
                                        password: false
                                    }))
                                }
                            }}
                        />
                        <span
                            className={`absolute right-0 bottom-1 mt-5 mr-3 cursor-pointer`}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? (
                                <RemoveRedEyeOutlinedIcon />
                            ) : (
                                <VisibilityOffOutlinedIcon />
                            )}
                        </span>
                    </div>
                    {inputError.password ? <div className='text-red-700 border-t border-red-500'>รหัสผ่านต้องมีความยาวไม่ต่ำกว่า 8 ตัวอักษร</div> : ''}
                </div>
            </div>
                            */}
        </div>
    );
};

export default MultiLevelSelect;
