import { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { round } from 'lodash';

const BulkUpdateProductByModel = ({ setAlert, categories }) => {
    const [data, setData] = useState([]);
    const [loadingState, setLoadingState] = useState({});
    const [openDrawer, setOpenDrawer] = useState(false);
    const [fileName, setFileName] = useState('');
    //const modelOptions = ["Samsung Mobile"];
    const [allModelCodes, setAllModelCodes] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState({});
    const [addModelName, setAddModelName] = useState('');
    const [addCategory, setAddCategory] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const brands = [
        {
            name: 'Samsung Mobile',
            id: 1,
            type: 'mobile',
        },
        {
            name: 'Samsung Appliance',
            id: 1,
            type: 'appliance',
        },
        {
            name: 'Samsung Air Conditioner',
            id: 1,
            type: 'air_conditioner',
        },
        {
            name: 'LG Mobile',
            id: 2,
            type: 'mobile',
        },
        {
            name: 'LG Appliance',
            id: 2,
            type: 'appliance',
        },
        {
            name: 'Hitachi Appliance',
            id: 4,
            type: 'appliance',
        }
    ];

    const handleFileUpload = async (files) => {
        const file = files[0];
        const fileType = file.name.split('.').pop();

        if (fileType !== 'xlsx' && fileType !== 'xls') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'Invalid file type. Please upload an Excel file.',
            });
            return;
        }

        setFileName(file.name);

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        let jsonData = [];

        // Get the last sheet name
        const lastSheetName = workbook.SheetNames[workbook.SheetNames.length - 1];
        const worksheet = workbook.Sheets[lastSheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 3 });
        let allModelCodes = [];

        sheetData.forEach(row => {
            if (row.length >= 3) {
                const modelCode = row[0];
                const price = row[2];
                const installment_6 = row[3];
                const installment_8 = row[4];
                const installment_10 = row[5];
                const installment_12 = row[6];
                const installment_18 = row[7];
                const installment_24 = row[8];

                if (!modelCode || !price) {
                    return;
                }

                const existingIndex = jsonData.findIndex(data => data.modelCode === modelCode);

                if (existingIndex !== -1) {
                    jsonData[existingIndex].price = price;
                } else {
                    jsonData.push({
                        modelCode,
                        price,
                        installment_6,
                        installment_8,
                        installment_10,
                        installment_12,
                        installment_18,
                        installment_24
                    });
                }

                allModelCodes.push(modelCode);
                setAllModelCodes(allModelCodes);
            }
        });

        setData(jsonData);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileUpload,
        maxFiles: 1,
    });

    const updatedProductsThatHaveToHide = async () => {
        if(!selectedBrand.id || !selectedBrand.type) {
            return;
        }
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bulk-products/auto-change-status-to-0`, {
            data: allModelCodes,
            brand: selectedBrand.id,
            type: selectedBrand.type,
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log(response);
    };

    const updateServer = async () => {
        for (let i = 0; i < data.length; i++) {
            const line = data[i];
            setLoadingState(prev => ({ ...prev, [i]: 'loading' }));

            try {
                const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/product/quick-update-by-model`, {
                    modelCode: line.modelCode,
                    price: line.price,
                    installment_6: line.installment_6,
                    installment_8: line.installment_8,
                    installment_10: line.installment_10,
                    installment_12: line.installment_12,
                    installment_18: line.installment_18,
                    installment_24: line.installment_24
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    setLoadingState(prev => ({ ...prev, [i]: 'success' }));
                }
            } catch (error) {
                setLoadingState(prev => ({ ...prev, [i]: { error: error.response.data.msg } }));
            }
        }

        updatedProductsThatHaveToHide();
    };

    const handleClickModelOption = (option) => {
        if (option === 'Samsung Mobile') {
            let allModelCodes = [];
            setData(prev => prev.map(line => {
                const { modelCode } = line;
                // Using regex to match the model code pattern
                const match = modelCode.match(/SM-\w+/);
                if (match) {
                    // match[0] contains the matched string which is the model code we want
                    line.modelCode = match[0];
                    allModelCodes.push(match[0]);
                }
                return line;
            }));

            setAllModelCodes(allModelCodes);
            //console.log(allModelCodes);
            
        } else if (option === 'Oppo Mobile') {

        }
    }

    const handleAddnewProduct = async () => {
        setOpenDialog(false);
        setLoading(false);
        //setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/xxxyyy?key=123456.xxxyyy`, {
                model: addModelName,
                cat: addCategory,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    'Content-Type': 'application/json'
                },
                timeout: 3000000
            });

            if (response.status === 200) {
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'Product added successfully.',
                });
                setAddModelName('');
                setAddCategory('');
            }
        } catch (error) {
           console.log(error);
        }
    };

    return (
        <>
            <button
                className='underline rounded-md hidden lg:block whitespace-nowrap'
                onClick={() => {
                    setOpenDrawer(true);
                }}
            >
                Update by Model
            </button>

            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={() => { }}
            >

                <div className='flex flex-col justify-between items-center p-4'>

                    <div className='flex justify-between w-full px-4'>
                        <h2 className='text-lg font-bold'>Bulk Update Product</h2>
                        <button onClick={() => setOpenDrawer(false)}><CloseOutlinedIcon /></button>
                    </div>

                    <div className='container mx-auto p-4' style={{
                        width: '1360px',
                        maxWidth: '100%',
                    }}>
                        {/*<p className='text-sm text-gray-500 text-center mb-2'>
                            Upload an Excel file to update product prices. <a className=' underline' href='/samsung1.xlsx' target='_blank' rel='noreferrer'>โหลดไฟล์ตัวอย่างที่นี่</a>
                        </p>*/}
                        {/*<div className='flex justify-center gap-4 mb-4'>
                            {modelOptions.map((option, index) => (
                                <button
                                    key={index}
                                    className='border rounded-md px-4 py-2'
                                    onClick={() => handleClickModelOption(option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>*/}

                        <div {...getRootProps()} className='flex justify-center items-center' style={{ border: '1px dashed #ccc', padding: '10px', borderRadius: '5px', height: '100px' }}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>{fileName || "Drag 'n' drop some files here, or click to select files"}</p>
                            }
                        </div>

                        {data.length > 0 &&
                            <div className='w-full mt-4'>
                                <FormControl fullWidth>
                                    <InputLabel>รูปแบบสินค้า</InputLabel>
                                    <Select
                                        value={selectedBrand.name || ''}
                                        label="รูปแบบสินค้า"
                                        onChange={(event) => {
                                            const selectedBrandName = event.target.value;
                                            const brand = brands.find(brand => brand.name === selectedBrandName);
                                            setSelectedBrand(brand);
                                            if (selectedBrandName === 'Samsung Mobile') {
                                                handleClickModelOption(selectedBrandName);
                                            }
                                        }}
                                    >
                                        {brands.map((brand, index) => (
                                            <MenuItem key={index} value={brand.name}>{brand.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        }

                        <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                            <table className='table-auto w-full'>
                                <thead>
                                    <tr>
                                        <td className='py-2 px-4 border-b font-bold'>Model</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>ราคา</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>6 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>8 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>10 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>12 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>18 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>24 งวด</td>
                                        <td className='py-2 px-4 border-b font-bold'></td>
                                        <td className='py-2 px-4 border-b font-bold'></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((line, index) => (
                                        <tr key={index} className='hover:bg-gray-100'>
                                            <td className='py-2 px-4 border-b truncate'>
                                                <div className=' max-w-[200px] truncate'>
                                                    {line.modelCode}
                                                </div>
                                            </td>
                                            <td className='py-2 px-4 border-b text-right'>{line.price}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_6 ? round(line.installment_6, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_8 ? round(line.installment_8, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_10 ? round(line.installment_10, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_12 ? round(line.installment_12, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_18 ? round(line.installment_18, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.installment_24 ? round(line.installment_24, 2) : ''}</td>
                                            <td className='py-2 px-4 border-b'>
                                                {loadingState[index] === 'loading' && <CircularProgress />}
                                                {loadingState[index] === 'success' &&
                                                    <Tooltip title="Success" placement="top" arrow >
                                                        <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
                                                    </Tooltip>
                                                }
                                                {loadingState[index]?.error &&
                                                    <Tooltip title={loadingState[index].error} placement="top" arrow >
                                                        <ErrorOutlineOutlinedIcon style={{ color: 'red' }} />
                                                    </Tooltip>
                                                }
                                            </td>
                                            <td className='py-2 px-4 border-b font-bold'>
                                                {loadingState[index]?.error &&
                                                    <Tooltip title={`เพิ่มสินค้า`} placement="top" arrow >
                                                        <button
                                                            onClick={() => {
                                                                setAddModelName(line.modelCode);
                                                                setOpenDialog(true);
                                                                setLoadingState(prev => ({ ...prev, [index]: null }));
                                                            }}
                                                        >
                                                            <AddOutlinedIcon />
                                                        </button>
                                                    </Tooltip>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='flex justify-end w-full mt-4 gap-4 sticky bottom-0 py-2 px-4 bg-white'>
                        <Button
                            onClick={() => setOpenDrawer(false)}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={updateServer}
                        >
                            บันทึกช้อมูล
                        </Button>
                    </div>

                </div>

            </Drawer>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle>เลือกหมวดหมู่สินค้า</DialogTitle>
                <DialogContent>


                    <InputLabel>หมวดหมู่สินค้า</InputLabel>
                    <select
                        className='border border-gray-300 rounded-lg py-1.5 px-4 text-sm focus:outline-none focus:shadow-outline'
                        value={addCategory || ''}
                        label="หมวดหมู่สินค้า"
                        onChange={(event) => {
                            setAddCategory(event.target.value);
                            console.log(event.target.value);
                        }}
                    >
                        {categories.map((category, index) => (
                            <optgroup key={index} label={category.name}>
                                <option value={category.id}>{category.name}</option>
                                {category.subcategories.map((subcategory) => (
                                    <option key={subcategory.id} value={subcategory.id}> -- {subcategory.name}</option>
                                ))}
                            </optgroup>
                        ))}
                    </select>


                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAddnewProduct}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'เพิ่มสินค้า'}
                    </Button>
                    <Button onClick={() => setOpenDialog(false)}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
export default BulkUpdateProductByModel;