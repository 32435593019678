import { useEffect } from 'react';
import axios from 'axios';

export default function Footer() {

    useEffect(() => {

        if (!localStorage.getItem('version')) {
            localStorage.clear();
            window.location.href = '/auth'
        }

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/check-version`)
            .then((response) => {
                if (response.data.version !== localStorage.getItem('version')) {
                    localStorage.clear();
                    window.location.href = '/auth'
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <footer></footer>
    );
}