import { useState } from 'react';
import PopupGallery from '../components/PopupGallery';

export default function InsertImageToEditor({ onImagesChange }) {

    const [openDialog, setOpenDialog] = useState(false);
    const [serverImages, setServerImages] = useState([]);

    const clickInsert = () => {
        setOpenDialog(true);
    }

    return (
        <>
            <button className='bg-gray-200 px-4 py-2 mb-2' onClick={clickInsert}>แทรกรูปภาพ</button>
            <PopupGallery
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setServerImages={setServerImages}
                serverImages={serverImages}
                getOutputImage={onImagesChange}
            />
        </>
    );
}