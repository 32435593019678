import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import Layout from "../layouts/layout"
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MultiLevelSelect from '../components/MultiLevelSelect';
import * as XLSX from 'xlsx';

export default function Customer() {

    const { setIsLoading } = useContext(LoadingContext);
    const [productList, setProductList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    let page = useRef(1);

    const fetchProducts = useCallback((value = 1) => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/customer/get-customer/${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {

            }
            if (response.data.status === 200) {
                setProductList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.all);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchProducts(value);
    }

    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleClickOpenDelete = (id) => {
        setDeleteId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setDeleteId(null);
        setOpenDelete(false);
    };

    const requestDelete = (id) => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/customer/${id}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
            if (response.data.status === 200) {
                fetchProducts(page.current);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
            }
        });
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };


    const [customerAddress, setCustomerAddress] = useState({
        fname: '',
        lname: '',
        company: '',
        address: '',
        sub_district: '',
        district: '',
        province: '',
        post_code: '',
        phone: '',
    });
    const [inputError, setInputError] = useState({
        fname: false,
        lname: false,
        address: false,
        sub_district: false,
        district: false,
        province: false,
        post_code: false,
        phone: false,
        email: false,
        password: false,
    });

    const validateForm = () => {

        let hasErrors = false;
        let updatedInputErrors = { ...inputError };
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

        for (const key in customerAddress) {
            if (key === 'email') {
                setCustomerAddress(prev => ({
                    ...prev,
                    email: localStorage.getItem('accountEmail')
                }))
                continue;
            }
            if (key === 'email' && !emailRegex.test(customerAddress[key])) {
                updatedInputErrors[key] = true;
                hasErrors = true;
            } else if (inputError.hasOwnProperty(key) && customerAddress[key].trim() === "") {
                if (key === 'email') {
                    continue;
                }
                if (key === 'password') {
                    continue;
                }
                updatedInputErrors[key] = true;
                hasErrors = true;
            } else if (inputError.hasOwnProperty(key)) {
                updatedInputErrors[key] = false;
            }
        }

        setInputError(updatedInputErrors);

        if (hasErrors) {
            window.scrollTo(0, 0);
        }

        return !hasErrors;
    };


    const fetchSingleCustomer = (id) => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/customer/get-single/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({
                    open: false,
                    type: 'error',
                    msg: response.data.msg
                });
            }
            if (response.data.status === 200) {
                let res = response.data.data;
                setCustomerAddress({
                    fname: res.c_name || '',
                    lname: res.c_lastname || '',
                    company: res.c_company || '',
                    address: res.c_address || '',
                    sub_district: res.c_sub_district || '',
                    district: res.c_district || '',
                    province: res.c_province || '',
                    post_code: res.c_post_code || '',
                    phone: res.c_phone || '',
                });
                setIsLoading(false);
            }
        });
    }

    const [openEdit, setOpenEdit] = useState(false);
    const [editId, setEditId] = useState(null);
    const handleClickOpenEdit = (id) => {
        setEditId(id);
        fetchSingleCustomer(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setEditId(null);
        setOpenEdit(false);
        setCustomerAddress({
            fname: '',
            lname: '',
            company: '',
            address: '',
            sub_district: '',
            district: '',
            province: '',
            post_code: '',
            phone: '',
        });
    };

    const requestEdit = (id) => {
        if (!validateForm()) {
            return;
        }
        setIsLoading(true)
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/customer/${id}/update`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                address: customerAddress,
            }
        }).then(function (response) {
            setIsLoading(false)
            if (response.data.status === 500) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
            if (response.data.status === 200) {
                handleCloseEdit();
                fetchProducts(page.current);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'บันทึกข้อมูลสำเร็จ'
                });
            }
        });
    }

    async function fetchData() {
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/customer/export`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`
                }
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching data:", error);
            return null;
        }
    }

    function exportToExcel(data) {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "exported_data.xlsx");
    }

    const handleExport = async () => {
        const response = await fetchData();
        if (response && response.status === 200 && Array.isArray(response.data)) {
            exportToExcel(response.data);
        } else {
            console.error("Unexpected data format received from the API.");
        }
    };


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='lg:flex gap-4 hidden'>
                        <h1 className='text-2xl font-bold hidden lg:block'>รายการสมาชิก</h1>
                    </div>
                    <div className='flex gap-4'>
                        <button className='bg-indigo-800 px-4 py-2 rounded-md text-white' onClick={handleExport}>Export as Excel</button>
                        <button className='bg-gray-200 px-4 py-2 rounded-md' onClick={() => fetchProducts()}>Reload</button>
                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`} >
                            <div className='w-2/12  font-bold'>อีเมล</div>
                            <div className='w-4/12  font-bold'>ชื่อลูกค้า</div>
                            <div className='w-2/12  font-bold'>หมายเลขโทรศัพท์</div>
                            <div className='w-2/12 text-right font-bold'>เป็นสมาชิกเมื่อ</div>
                            <div className='w-2/12 text-right font-bold'>จัดการ</div>
                        </div>
                        {productList.length > 0 && productList.map((product, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='w-2/12 '>{product.c_email}</div>
                                <div className='w-4/12 '>{product.c_name} {product.c_lastname}</div>
                                <div className='w-2/12 '>{product.c_phone}</div>
                                <div className='w-2/12 text-right'>{product.create_at}</div>
                                <div className='w-2/12 flex justify-end gap-2'>
                                    <Tooltip title="ดู / แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => handleClickOpenEdit(product.id)}>
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow>
                                        <IconButton aria-label="delete" onClick={() => handleClickOpenDelete(product.id)} sx={{ backgroundColor: '#f1f7fe' }}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                    <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>

            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="alert-dialog-title-2"
                aria-describedby="alert-dialog-description-2"
            >
                <DialogTitle id="alert-dialog-title">
                    แก้ไขข้อมูลสมาชิก
                </DialogTitle>
                <DialogContent>
                    <MultiLevelSelect
                        customerAddress={customerAddress}
                        setCustomerAddress={setCustomerAddress}
                        inputError={inputError}
                        setInputError={setInputError}
                        disableNote={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>ยกเลิก</Button>
                    <Button onClick={() => {
                        requestEdit(editId);
                    }} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ลูกค้า และคำสั่งซื้อที่เกี่ยวข้องกับลูกค้ารายการนี้ จะถูกลบ และไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลหรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>ยกเลิก</Button>
                    <Button onClick={() => {
                        requestDelete(deleteId);
                        handleCloseDelete();
                    }} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>

    )
}