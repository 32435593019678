import { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';

const BulkUpdateProduct = ({ setAlert, handleExport }) => {
    const [data, setData] = useState([]);
    const [loadingState, setLoadingState] = useState({});
    const [openDrawer, setOpenDrawer] = useState(false);
    const [fileName, setFileName] = useState('');

    const handleFileUpload = async (files) => {
        const file = files[0]; // Select the first file from the dropped files
        const fileType = file.name.split('.').pop();

        // Validate file type
        if (fileType !== 'xlsx' && fileType !== 'xls') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'Invalid file type. Please upload an Excel file.',
            });
            return;
        }

        setFileName(file.name);

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(jsonData);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileUpload,
        maxFiles: 1,
    });

    const updateServer = async () => {
        for (let i = 0; i < data.length; i++) {
            const line = data[i];
            setLoadingState(prev => ({ ...prev, [i]: 'loading' }));

            try {
                const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/product/quick-update`, {
                    id: line.id,
                    price: line.price,
                    salePrice: line.sale ? line.sale : 0
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    setLoadingState(prev => ({ ...prev, [i]: 'success' }));
                }
            } catch (error) {
                setLoadingState(prev => ({ ...prev, [i]: { error: error.response.data.msg } }));
            }
        }
    };

    return (
        <>
            <button
                className='underline rounded-md hidden lg:block whitespace-nowrap'
                onClick={() => setOpenDrawer(true)}
            >
                Update Prices
            </button>

            <Drawer
                anchor='left'
                open={openDrawer}
                onClose={() => { }}
            >

                <div className='flex flex-col justify-between items-center p-4'>

                    <div className='flex justify-between w-full px-4'>
                        <h2 className='text-lg font-bold'>Bulk Update Product</h2>
                        <button onClick={() => setOpenDrawer(false)}><CloseOutlinedIcon /></button>
                    </div>

                    <div className='container mx-auto p-4' style={{
                        width: '1280px',
                        maxWidth: '100%',
                    }}>
                        <p className='text-sm text-gray-500 text-center mb-2'>
                            Upload an Excel file to update product prices.
                            Download the template <button onClick={handleExport} className='text-blue-500'>here</button>.
                        </p>
                        <div {...getRootProps()} className='flex justify-center items-center' style={{ border: '1px dashed #ccc', padding: '10px', borderRadius: '5px', height: '100px' }}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>{fileName || "Drag 'n' drop some files here, or click to select files"}</p>
                            }
                        </div>

                        <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                            <table className='table-auto w-full'>
                                <thead>
                                    <tr>
                                        <td className='py-2 px-4 border-b font-bold'>#</td>
                                        <td className='py-2 px-4 border-b font-bold'>ชื่อสินค้า</td>
                                        <td className='py-2 px-4 border-b font-bold'>แบรนด์</td>
                                        <td className='py-2 px-4 border-b font-bold'>หมวดหมู่</td>
                                        <td className='py-2 px-4 border-b font-bold'>Model Code</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>ราคาปกติ</td>
                                        <td className='py-2 px-4 border-b font-bold text-right'>ราคาที่ลด</td>
                                        <td className='py-2 px-4 border-b font-bold'></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((line, index) => (
                                        <tr key={index}>
                                            <td className='py-2 px-4 border-b'>{line.id}</td>
                                            <td className='py-2 px-4 border-b'>{line.name}</td>
                                            <td className='py-2 px-4 border-b'>{line.brand}</td>
                                            <td className='py-2 px-4 border-b truncate'>{line.catName}</td>
                                            <td className='py-2 px-4 border-b truncate'>{line.modelCode}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.price}</td>
                                            <td className='py-2 px-4 border-b text-right'>{line.sale}</td>
                                            <td className='py-2 px-4 border-b'>
                                                {loadingState[index] === 'loading' && <CircularProgress />}
                                                {loadingState[index] === 'success' &&
                                                    <Tooltip title="Success" placement="top" arrow >
                                                        <CheckCircleOutlineOutlinedIcon style={{ color: 'green' }} />
                                                    </Tooltip>
                                                }
                                                {loadingState[index]?.error &&
                                                    <Tooltip title={loadingState[index].error} placement="top" arrow >
                                                        <ErrorOutlineOutlinedIcon style={{ color: 'red' }} />
                                                    </Tooltip>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='flex justify-end w-full mt-4 gap-4 sticky bottom-0 py-2 px-4 bg-white'>
                        <Button
                            onClick={() => setOpenDrawer(false)}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={updateServer}
                        >
                            บันทึกช้อมูล
                        </Button>
                    </div>

                </div>

            </Drawer>
        </>
    );

}
export default BulkUpdateProduct;