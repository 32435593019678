import { useEffect } from "react";
import Layout from "../../layouts/layout";
import BranchInquiry from "../../components/BranchInquiry";

const AddBranchInquiry = () => {
    useEffect(() => {
        document.title = 'งานออนไลน์สาขา';
    }, []);

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <BranchInquiry />
        </Layout>
    );
};


export default AddBranchInquiry;