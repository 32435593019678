import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Complaints = () => {

    const { setIsLoading } = useContext(LoadingContext);
    const [contentList, setContentList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [deleteId, setDeleteId] = useState(null);
    let page = useRef(1);
    const navigate = useNavigate();
    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });
    const [openDialog, setOpenialog] = useState(false);


    const fetchcontents = useCallback((value = 1) => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry/complaints/${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.status === 200) {
                setContentList(response.data.data);
                setIsLoading(false);
                setAllPage(response.data.all);
            }
        });
    }, [setIsLoading]);

    useEffect(() => {
        fetchcontents();
    }, [fetchcontents]);

    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchcontents(value);
    }

    const convertStatus = (status) => {
        switch (status) {
            case 1:
                return { status: 'รอการตรวจสอบ', bgColor: 'bg-yellow-400', textColor: 'text-black' };
            case 2:
                return { status: 'กำลังดำเนินการ', bgColor: 'bg-blue-400', textColor: 'text-white' };
            case 3:
                return { status: 'เสร็จสิ้น', bgColor: 'bg-green-400', textColor: 'text-white' };
            case 4:
                return { status: 'ยกเลิก', bgColor: 'bg-red-400', textColor: 'text-white' };
            default:
                return { status: 'ยังไม่ได้ระบุ', bgColor: 'bg-gray-400', textColor: 'text-white' };
        }
    }

    const handleDelete = (id) => {
        setIsLoading(true);
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/inquiry-single/complaint/delete/complaints/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            if (response.status === 200) {
                fetchcontents();
                setOpenialog(false);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลเรียบร้อยแล้ว'
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex justify-between w-full'>
                    <div className='flex gap-4'>
                        <h1 className='text-2xl font-bold'>เรื่องร้องเรียน</h1>
                    </div>
                    <div className='flex gap-4'>

                    </div>
                </div>

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full grid grid-cols-5 gap-4 table-row-head`} >
                            <div className='font-bold'>ชื่อลูกค้า</div>
                            <div className='font-bold'>หมายเลขโทรศัพท์</div>
                            <div className='font-bold'>วันที่</div>
                            <div className='font-bold'>สถานะ</div>
                            <div className='text-right font-bold'>จัดการ</div>
                        </div>
                        {contentList.length > 0 ? contentList.map((content, index) => (
                            <div className={`table-row-tr py-2 px-4 border-b w-full grid grid-cols-5 items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>
                                <div className='' onClick={() => {
                                    setIsLoading(true);
                                    navigate(`/complaint/${content.id}`);
                                }}>{content.c_name} {content.c_lastname}</div>
                                <div className=''>{content.c_phone}</div>
                                <div className=''>
                                    {
                                        new Date(content.create_at).toLocaleDateString('th-TH', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })
                                    }
                                </div>
                                <div className=''>
                                    <span className={`px-2 py-1 rounded-md ${convertStatus(content.c_status).bgColor} ${convertStatus(content.c_status).textColor}`}>
                                        {convertStatus(content.c_status).status}
                                    </span>
                                </div>
                                <div className='flex justify-end gap-2'>
                                    <Tooltip title="จัดการ" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            setIsLoading(true);
                                            navigate(`/complaint/${content.id}`);
                                        }}>
                                            <EditNoteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" arrow >
                                        <IconButton aria-label="delete" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => {
                                            setDeleteId(content.id);
                                            setOpenialog(true);
                                        }}>
                                            <DeleteForeverOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )) :
                            <div className='py-2 px-4 border-b w-full flex items-center gap-4 text-sm'>
                                <div className='w-full text-center'>ไม่มีข้อมูล</div>
                            </div>
                        }
                    </div>
                </div>
                {contentList.length > 0 &&
                    <div className='sticky bottom-0 w-full bg-white p-2 flex justify-between items-center'>
                        <div className='ml-4'>หน้าที่ {page.current} / {allPage}</div>
                        <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                    </div>
                }
            </div>
            <Dialog
                open={openDialog}
                onClose={() => setOpenialog(false)}
                aria-labelledby="alert-dialog-title-2"
                aria-describedby="alert-dialog-description-2"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    เมื่อลบข้อมูลแล้วจะไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลใช่หรือไม่?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenialog(false)}>ยกเลิก</Button>
                    <Button onClick={() => handleDelete(deleteId)}>ยืนยัน</Button>
                </DialogActions>
            </Dialog>


            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default Complaints;