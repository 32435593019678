import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../layouts/layout"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
/*
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
*/
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
);

//const colorList = ["#3730a3", "#facc15"];
/*
const statusOption = [
    {
        value: 1,
        label: 'รอแจ้งชำระเงิน',
        color: '#ff0000',
    },
    {
        value: 2,
        label: 'กำลังตรวจสอบการชำระเงิน',
        color: '#ffa500',
    },
    {
        value: 3,
        label: 'กำลังเตรียมสินค้าเพื่อจัดส่ง',
        color: '#ffff00',
    },
    {
        value: 4,
        label: 'สินค้าอยู่ระหว่างจัดส่ง',
        color: '#008000',
    },
    {
        value: 5,
        label: 'จัดส่งสินค้าแล้ว',
        color: '#00ffff',
    },
    {
        value: 6,
        label: 'ยกเลิก',
        color: '#0000ff',
    },
    {
        value: 7,
        label: 'คืนเงิน',
        color: '#4b0082',
    },
    {
        value: 8,
        label: 'กำลังดำเนินการ',
        color: '#facc15',
    },
    {
        value: 9,
        label: 'ดำเนินการเสร็จสิ้น',
        color: '#3730a3',
    },
];

const getStatusLabel = (status) => {
    const statusObj = statusOption.find(option => option.value === status);
    return statusObj ? statusObj.label : 'Unknown status';
};

const statusColorMap = statusOption.reduce((acc, status) => {
    acc[status.value] = status.color;
    return acc;
}, {});
*/


export default function Dashboard() {

    const [chartData, setChartData] = useState(null);

    const [topRowAllsale, setTopRowAllsale] = useState({ sale: 0, order: 0 });
    const [topRowApprove, setTopRowApprove] = useState({ sale: 0, order: 0 });
    const [topRowReject, setTopRowReject] = useState({ sale: 0, order: 0 });
    const [topRowInquiry, setTopRowInquiry] = useState(0);


    //const [bestSellerProduct, setBestSellerProduct] = useState(null);
    //const [dailySales, setDailySales] = useState(null);
    const [dailyDate, setDailyDate] = useState({
        startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
        endDate: new Date().toISOString().slice(0, 10)
    });
    //const [customerReport, setCustomerReport] = useState(null);
    //const [chartData2, setChartData2] = useState(null);
    //const [inQuiryData, setInQuiryData] = useState([]);
    const [approveData, setApproveData] = useState(null);
    const [unApproveData, setUnApproveData] = useState(null);
    const [whyUnApproveData, setWhyUnApproveData] = useState(null);
    //const [inquiryList, setInquiryList] = useState([]);

    const addNumberFormat = (number) => {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    useEffect(() => {
        const admin_role = localStorage.getItem('admin_role');
        if (admin_role === '3') {
            window.location.href = '/product';
        }
    }, []);


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BACKEND_URL}/report-v2/top-3-row/${dailyDate.startDate}/${dailyDate.endDate}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                if (response.data) {
                    setTopRowAllsale({
                        order: response.data.all.count ? response.data.all.count : 0,
                        sale: response.data.all.total ? response.data.all.total : 0,
                    });
                    setTopRowApprove({
                        order: response.data.approve.count ? response.data.approve.count : 0,
                        sale: response.data.approve.total ? response.data.approve.total : 0,
                    });
                    setTopRowReject({
                        order: response.data.reject.count ? response.data.reject.count : 0,
                        sale: response.data.reject.total ? response.data.reject.total : 0,
                    });
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();

    }, [dailyDate]);

    /*
    useEffect(() => {
  
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/report/${dailyDate.startDate}/${dailyDate.endDate}/8,9/daily`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
        },
      }).then(function (response) {
        if (response.data) {
          const datasets = Object.keys(response.data).map(status => {
            const statusLabel = getStatusLabel(Number(status));
            const statusColor = statusColorMap[Number(status)];
            if (response.data[status]) {
              const data = response.data[status].map(item => item.total_sales ? item.total_sales : 0);
              return {
                label: statusLabel,
                data: data,
                fill: false,
                backgroundColor: statusColor,
                borderColor: statusColor,
              };
            } else {
              return null;
            }
          }).filter(Boolean);
  
          const labels = response.data[Object.keys(response.data)[0]].map(item => item.date);
  
          setDailySales({
            labels: labels,
            datasets: datasets,
          });
        }
      });
  
    }, [dailyDate]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report/${dailyDate.startDate}/${dailyDate.endDate}/customer`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
          });
  
          if (response.data) {
            setCustomerReport(response.data);
            const labels = response.data.sales_per_customer.map(item => `${item.c_name} ${item.c_lastname}`);
            const data = response.data.sales_per_customer.map(item => item.total_sales ? item.total_sales : 0);
            setChartData2({
              labels: labels,
              datasets: [
                {
                  label: 'Sales per Customer',
                  data: data,
                  backgroundColor: colorList,
                },
              ],
            });
          }
        } catch (error) {
          console.error('Error fetching data', error);
        }
      };
  
      fetchData();
  
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/report/${dailyDate.startDate}/${dailyDate.endDate}/8,9/best-selling-products`,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('admin_token'),
          'Content-Type': 'application/json'
        },
      }).then(function (response) {
        setBestSellerProduct(response.data);
      });
    }, [dailyDate]);
    */

    //Inquiry Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-v2/inquiry/${dailyDate.startDate}/${dailyDate.endDate}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });

                if (response.data) {

                    const totalSum = response.data.reduce((acc, item) => acc + item.count, 0);
                    setTopRowInquiry(totalSum);


                    const labels = [...new Set(response.data.map(item => item.date))];

                    const dataByRef = response.data.reduce((acc, item) => {
                        if (!acc[item.ref]) acc[item.ref] = [];
                        acc[item.ref].push(item.count);
                        return acc;
                    }, {});

                    setChartData({
                        labels,
                        datasets: [
                            {
                                label: 'gse-stores.com',
                                data: dataByRef['gse-stores.com'],
                                backgroundColor: '#facc15',
                                borderColor: '#facc15',
                            },
                            {
                                label: 'gseinsurebroker.com',
                                data: dataByRef['gseinsurebroker.com'],
                                backgroundColor: '#e80b81',
                                borderColor: '#e80b81',
                            },
                            {
                                label: 'mcapital.co.th',
                                data: dataByRef['mcapital.co.th'],
                                backgroundColor: '#019C49',
                                borderColor: '#019C49',
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();

    }, [dailyDate]);


    //Approve Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-v2/approve/${dailyDate.startDate}/${dailyDate.endDate}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });
                if (response.data && Array.isArray(response.data)) {
                    const labels = response.data.map(item => item.ref);
                    const countData = response.data.map(item => item.count);
                    const sumData = response.data.map(item => item.sum ? parseFloat(item.sum) : 0);

                    setApproveData({
                        labels,
                        datasets: [
                            {
                                label: 'จำนวนที่อนุมัติ',
                                data: countData,
                                backgroundColor: '#facc15',
                                borderColor: '#facc15',
                                yAxisID: 'y-axis-1',
                            },
                            {
                                label: 'ยอดเงินที่อนุมัติ',
                                data: sumData,
                                backgroundColor: '#3730a3',
                                borderColor: '#3730a3',
                                yAxisID: 'y-axis-2',
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [dailyDate]);


    //UnApprove Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-v2/not-approve/${dailyDate.startDate}/${dailyDate.endDate}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });
                if (response.data && Array.isArray(response.data)) {
                    const labels = response.data.map(item => item.ref);
                    const countData = response.data.map(item => item.count);
                    const sumData = response.data.map(item => item.sum ? parseFloat(item.sum) : 0);

                    setUnApproveData({
                        labels,
                        datasets: [
                            {
                                label: 'จำนวนที่ไม่อนุมัติ',
                                data: countData,
                                backgroundColor: '#facc15',
                                borderColor: '#facc15',
                                yAxisID: 'y-axis-1',
                            },
                            {
                                label: 'ยอดเงินที่ไม่อนุมัติ',
                                data: sumData,
                                backgroundColor: '#3730a3',
                                borderColor: '#3730a3',
                                yAxisID: 'y-axis-2',
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [dailyDate]);

    //Why UnApprove Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-v2/why-not-approve/${dailyDate.startDate}/${dailyDate.endDate}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });
                if (response.data && Array.isArray(response.data)) {
                    const labels = response.data.map(item => item.status_text);
                    const data = response.data.map(item => item.Count);

                    setWhyUnApproveData({
                        labels,
                        datasets: [
                            {
                                data,
                                backgroundColor: [
                                    '#3730A3',
                                    '#9D05AB',
                                    '#facc15',
                                ],
                                hoverBackgroundColor: [
                                    '#FF6384',
                                    '#9D05AB',
                                    '#facc15',
                                ],
                            },
                        ],
                    });
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [dailyDate]);

    //Inquiry List
    /*
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-v2/list-of-inquiry/${dailyDate.startDate}/${dailyDate.endDate}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                });
                if (response.data) {
                    setInquiryList(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
    }, [dailyDate]);
    */


    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>

            {/* Head */}
            <div className='mb-5 flex-col lg:flex-row flex justify-between items-center'>
                <h1 className='text-2xl font-bold hidden lg:block'>Dashboard</h1>
                <div className='flex gap-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            className='mr-2'
                            label="วันที่เริ่มต้น"
                            value={dailyDate.startDate}
                            onChange={(newValue) => {
                                setDailyDate({
                                    ...dailyDate,
                                    startDate: newValue.format("YYYY-MM-DD"),
                                });
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} size="small" />}
                        />
                        <DatePicker
                            label="วันที่สิ้นสุด"
                            value={dailyDate.endDate}
                            onChange={(newValue) => {
                                setDailyDate({
                                    ...dailyDate,
                                    endDate: newValue.format("YYYY-MM-DD"),
                                });
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} size="small" />}
                        />
                    </LocalizationProvider>


                </div>
            </div>
            {/* End Head */}

            {/* Top Row */}

            <div className="grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-5 mb-5">
                <Card className='custom-card-bg relative' sx={{ backgroundColor: '#3730a3', padding: '1em' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, color: '#fff' }} gutterBottom>
                            ยอดคำสั่งซื้อ {topRowAllsale.order} รายการ
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ color: '#fff' }} >
                            {addNumberFormat(topRowAllsale.sale)} บาท
                        </Typography>
                    </CardContent>

                </Card>


                <Card className='custom-card-bg-2 relative' sx={{ backgroundColor: '#facc15', padding: '1em' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ยอดอนุมัติ {topRowApprove.order} รายการ
                        </Typography>
                        <Typography variant="h5" component="div">
                            {addNumberFormat(topRowApprove.sale)} บาท
                        </Typography>
                    </CardContent>
                </Card>

                <Card className='relative' sx={{ padding: '1em' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            ยอดไม่อนุมัติ {topRowReject.order} รายการ
                        </Typography>
                        <Typography variant="h5" component="div">
                            {addNumberFormat(topRowReject.sale)} บาท
                        </Typography>
                    </CardContent>
                </Card>


                <Card className='relative' sx={{ padding: '1em' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            จำนวนผู้สนใจ
                        </Typography>
                        <Typography variant="h5" component="div">
                            {topRowInquiry} ราย
                        </Typography>
                    </CardContent>
                </Card>

            </div>

            {/* End Top Row */}

            <div className='grid grid-col-1 lg:grid-cols-12 gap-5 mb-5'>
                <div className='col-span-6'>
                    <Card >
                        <CardContent>
                            <h3 className="font-bold text-center">ยอดผู้สนใจ</h3>
                            {chartData && <Line
                                options={{
                                    interaction: {
                                        intersect: false,
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                }
                                            }
                                        },
                                    },
                                }}
                                data={chartData}
                            />}
                        </CardContent>
                    </Card>
                </div>
                <div className='col-span-6'>
                    <Card >
                        <CardContent>
                            <h3 className="font-bold text-center">ยอดอนุมัติ</h3>
                            {approveData && <Bar
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                    },
                                    scales: {
                                        /*
                                        y: {
                                            beginAtZero: true,
                                        },
                                        'y-axis-1': {
                                            type: 'linear',
                                            position: 'left',
                                        },
                                        */
                                        'y-axis-2': {
                                            type: 'linear',
                                            position: 'right',
                                        },

                                    },
                                }}
                                data={approveData}
                            />}
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className='grid grid-col-1 lg:grid-cols-12 gap-5 pb-8'>
                <div className='lg:col-span-6'>
                    <Card >
                        <CardContent>
                            <h3 className="font-bold text-center">ยอดไม่อนุมัติ</h3>
                            {unApproveData && <Bar
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                font: {
                                                    family: 'Noto Sans Thai',
                                                },
                                            },
                                        },
                                    },
                                    scales: {
                                        /*
                                        y: {
                                            beginAtZero: true,
                                        },
                                        'y-axis-1': {
                                            type: 'linear',
                                            position: 'left',
                                        },
                                        */
                                        'y-axis-2': {
                                            type: 'linear',
                                            position: 'right',
                                        },

                                    },
                                }}
                                data={unApproveData}
                            />}
                        </CardContent>
                    </Card>
                </div>
                <div className='lg:col-span-6'>
                    <Card className='h-full'>
                        <CardContent>
                            <h3 className="font-bold text-center">เหตุผลที่ไม่อนุมัติ</h3>
                            <div className='max-w-full text-center'>
                                {whyUnApproveData &&
                                    <div className='w-full mx-auto' style={{ maxWidth: '373px' }}>
                                        <Pie
                                            className='mx-auto block max-w-full'
                                            data={whyUnApproveData} options={{
                                                responsive: true, plugins: {
                                                    legend: {
                                                        position: 'top',
                                                        labels: {
                                                            font: {
                                                                family: 'Noto Sans Thai',
                                                            },
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>

            {/*<div className='grid grid-col-1 lg:grid-cols-12 gap-5 pb-8'>
                <div className='lg:col-span-6'>
                    <Card >
                        <CardContent>
                            <h3 className="font-bold text-center">รายการผู้สนใจ</h3>
                            <div className='max-w-full'>
                                {inquiryList.length > 0 ?
                                    <table className='w-full'>
                                        <thead>
                                            <tr>
                                                <th className='border-b border-gray-300 px-2 py-1 text-left'>วันที่</th>
                                                <th className='border-b border-gray-300 px-2 py-1 text-left'>ชื่อผู้สนใจ</th>
                                                <th className='border-b border-gray-300 px-2 py-1 text-right'>จากเว็บไซต์</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inquiryList.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='border-b border-gray-300 px-2 py-1'>
                                                        {new Date(item.date).toLocaleDateString('th-TH', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </td>
                                                    <td className='border-b border-gray-300 px-2 py-1'>{item.customer}</td>
                                                    <td className='border-b border-gray-300 px-2 py-1 text-right'>{item.ref}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='text-center'>ไม่มีข้อมูล</div>
                                }
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>*/}
        </Layout>
    )
}