import { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import LoadingContext from '../contexts/LoadingContext';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

const OrderMessage = ({ orderId, orderTable, setAlert }) => {

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [message, setMessage] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [singleMessage, setSingleMessage] = useState({
        id: null,
        w_message: '',
        w_message_type: 0,
    });

    const resetSingleMessage = () => {
        setSingleMessage({
            id: null,
            w_message: '',
            w_message_type: 0,
        });
    }
    const adminName = localStorage.getItem('admin_name');

    const fetchMessage = useCallback(() => {
        setIsLoading(true);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/get-message-by-order/${orderId}/${orderTable}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            setMessage(response.data);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }, [orderId, setIsLoading, orderTable]);

    useEffect(() => {
        fetchMessage();
    }, [fetchMessage]);

    const handleAddnewMessage = () => {

        if (singleMessage.w_message === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกข้อความ'
            });
            return;
        }

        setIsLoading(true);
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/add/${orderId}/${orderTable}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                w_message: singleMessage.w_message,
                w_message_type: singleMessage.w_message_type,
            }
        }).then(function (response) {
            setIsLoading(false);
            fetchMessage();
            setOpen(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'บันทึกสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.msg) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.msg
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'บันทึกไม่สำเร็จ'
                });
            }
        });
    }

    const handleEditMessage = () => {

        if (singleMessage.w_message === '') {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกข้อความ'
            });
            return;
        }

        setIsLoading(true);
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/edit/${orderId}/${orderTable}/${singleMessage.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                w_message: singleMessage.w_message,
                w_message_type: singleMessage.w_message_type,
            }
        }).then(function (response) {
            setIsLoading(false);
            fetchMessage();
            setOpen(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'บันทึกสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.msg) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.msg
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'บันทึกไม่สำเร็จ'
                });
            }
        });
    }

    const handleDeleteMessage = () => {
        setIsLoading(true);
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/message-center/delete/${deleteId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            fetchMessage();
            setOpenDelete(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'ลบสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response.data.msg) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: error.response.data.msg
                });
            } else {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'ลบไม่สำเร็จ'
                });
            }
        });
    }

    return (
        <>
            <div className='p-8 bg-white mb-4'>
                <div className='flex justify-between items-center border-b mb-4'>
                    <h3 className='text-xl font-semibold'>บันทึกสำหรับแอดมิน</h3>
                    <IconButton onClick={() => {
                        resetSingleMessage();
                        setOpen(true)
                    }}>
                        <AddOutlinedIcon />
                    </IconButton>
                </div>

                <div className="relative border-l border-gray-200">
                    {message.map((item, index) => (
                        <div key={index} className={`mb-4 ml-4`}>
                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <div className='bg-slate-50 p-2'>
                                <div className='flex justify-between items-start'>
                                    <div className="">{
                                        new Date(item.created_at).toLocaleDateString('th-TH', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }) + " " + new Date(item.created_at).toLocaleTimeString('th-TH', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })
                                    }</div>
                                    <div className='flex justify-end gap-4'>
                                        <button
                                            onClick={() => {
                                                setSingleMessage({
                                                    id: item.id,
                                                    w_message: item.w_message,
                                                    w_message_type: item.w_message_type,
                                                });
                                                setOpen(true)
                                            }}
                                        >
                                            <EditOutlinedIcon />
                                        </button>
                                        {item.admin_name === adminName &&
                                            <button
                                                onClick={() => {
                                                    setDeleteId(item.id);
                                                    setOpenDelete(true);
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon />
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="text-xs mb-1">บันทึกโดย: {item.admin_name === adminName ? 'ฉัน' : item.admin_name}</div>
                                <div className='text-black whitespace-pre-line'>{item.w_message}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth="sm"
                className='z-40'
            >
                <DialogTitle>
                    {singleMessage.id ? 'แก้ไขบันทึกสำหรับแอดมิน' : 'เพิ่มบันทึกสำหรับแอดมิน'}
                </DialogTitle>
                <DialogContent>

                    <TextareaAutosize
                        rows={4}
                        className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                        placeholder="เขียนบันทึกช่วยจำ"
                        value={singleMessage.w_message}
                        onChange={(e) => setSingleMessage({ ...singleMessage, w_message: e.target.value })}
                        fullWidth
                        style={{ minHeight: '100px' }}

                    />

                    <FormControlLabel
                        control={<Checkbox
                            checked={singleMessage.w_message_type === 1 ? true : false}
                            onChange={(e) => setSingleMessage({ ...singleMessage, w_message_type: e.target.checked ? 1 : 0 })}
                        />}
                        label="แจ้งกลับส่วนกลาง"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
                    <Button onClick={() => {
                        if (singleMessage.id) {
                            handleEditMessage();
                        } else {
                            handleAddnewMessage();
                        }
                    }
                    } autoFocus disabled={isLoading}>
                        บันทึก
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                    setDeleteId(null);
                }}
                fullWidth
                maxWidth="sm"
                className='z-40'
            >
                <DialogTitle>
                    ยืนยันการลบ
                </DialogTitle>
                <DialogContent>
                    คุณต้องการลบบันทึกนี้ใช่หรือไม่
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDelete(false);
                        setDeleteId(null);
                    }}>ยกเลิก</Button>
                    <Button onClick={() => handleDeleteMessage()} autoFocus disabled={isLoading}>
                        ลบ
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
export default OrderMessage;

