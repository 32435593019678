import Layout from "../../layouts/layout"
import ReportLayout from "../../components/ReportLayout"
import * as XLSX from 'xlsx';

const ComplaintsReport = () => {

    const handleExport = (serverData, title, dailyDate) => {
        // Prepare data for export
        const exportData = serverData.data.map(item => ({
            "ชื่อลูกค้า": item["ชื่อลูกค้า"],
            "หมายเลขโทรศัพท์": item["หมายเลขโทรศัพท์"],
            "อีเมล": item["อีเมล"],
            "รายละเอียด": item["รายละเอียด"],
            "รหัสสมาชิก": item["รหัสสมาชิก"],
            "วันที่สร้าง": item["วันที่สร้าง"],
            "วันที่อัพเดท": item["วันที่อัพเดท"],
            "สถานะ": item["สถานะ"],
            "การดำเนินการ": item["การดำเนินการ"]
                .map(action => `${action["รายละเอียด"]} (${action["วันที่"]})`)
                .join(", ")
        }));

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, title || 'Report');

        // Create an Excel file and trigger download
        XLSX.writeFile(workbook, `${title} ${dailyDate.startDate} - ${dailyDate.endDate}.xlsx`);
    };

    return (
        <Layout mainClass='bg-gray-100'>
            <ReportLayout
                title='เรื่องร้องเรียน'
                path='/complaints/export'
                previewTable={[
                    "ชื่อลูกค้า", "หมายเลขโทรศัพท์", "วันที่สร้าง", "สถานะ"
                ]}
                handleExport={handleExport}
            />
        </Layout>
    );
};

export default ComplaintsReport;