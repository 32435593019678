import { useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import GoogleMapIframe from '../../components/GoogleMapIframe';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import TextareaAutosize from '@mui/base/TextareaAutosize';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Autocomplete, TextField } from '@mui/material';
import OrderMessage from '../../components/OrderMessage';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ApproveProducts from '../../components/ApproveProducts';

export default function EditOrders() {

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [orderData, setOrderData] = useState(null);
    const [orderStatus, setOrderStatus] = useState(8);
    const [adminNote, setAdminNote] = useState('');
    const [productData, setProductData] = useState([]);
    const [openDialog, setOpenialog] = useState(false);
    const [orderTotal, setOrderTotal] = useState(0);
    const [whyNotApprove, setWhyNotApprove] = useState(1);
    const [allAdmin, setAllAdmin] = useState([{
        id: 1,
        admin_name: 'ไม่ระบุ'
    }]);
    const [assignTo, setAssignTo] = useState(1);
    //const [contractDate, setContractDate] = useState(null);
    //const [contractNumber, setContractNumber] = useState('');
    /*
    const [errors, setErrors] = useState({
        dateOfMeeting: null,
        contractNumber: null,
    });
    */
    
    const [dialogAction, setDialogAction] = useState('product');

    const [approveDetail, setApproveDetail] = useState(null);
    const [approveDetailError, setApproveDetailError] = useState([]);

    const handleOrderStatusChange = (event) => {
        setOrderStatus(event.target.value);
        if (event.target.value === 12) {
            setWhyNotApprove(1)
        }
    };

    useEffect(() => {
        setIsLoading(true)
        window.scrollTo(0, 0);

        const fetchAllAdmin = async () => {
            if (localStorage.getItem('admin_role') === '1') {
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                    },
                }).then(function (response) {
                    if (response.data.status === 200) {
                        setAllAdmin(prevAllAdmin => [
                            ...prevAllAdmin,
                            ...response.data.data
                        ]);
                    }
                }).catch(function (error) {
                    setAlert({
                        open: true,
                        type: 'error',
                        msg: 'ไม่สามารถเรียกข้อมูล Admin ได้'
                    });
                });
            }
        };

        const fetchOrderDetail = async () => {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/order/get-order-detail/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {

                if (response.data.status === 200) {
                    setIsLoading(false);
                    setOrderData(response.data);
                    setAssignTo(response.data.orderDetail.assign_to);
                    setWhyNotApprove(response.data.orderDetail.why_not_approve);
                    setOrderTotal(response.data.orderDetail.grand_total);
                    setOrderStatus(response.data.orderDetail.order_status);
                    let adminOrderNote = response.data.orderDetail.order_admin_remark;
                    if (adminOrderNote === null) {
                        adminOrderNote = '';
                    }
                    setAdminNote(adminOrderNote);
                    //setContractDate(response.data.orderDetail.contract_date);
                    //setContractNumber(response.data.orderDetail.contract_number ? response.data.orderDetail.contract_number : '');

                    if (response.data.approveProduct.length > 0) {
                        let newApproveDetail = [];
                        response.data.approveProduct.forEach((item, index) => {
                            newApproveDetail.push({
                                contractDate: item.contract_date ? dayjs(item.contract_date) : null,
                                contractNumber: item.contract_number ? item.contract_number : '',
                                productId: item.product_id ? item.product_id : null,
                                productName: item.product_name ? item.product_name : '',
                                productQty: item.product_qty ? item.product_qty : 1,
                                unitPrice: item.unit_price ? item.unit_price : 0,
                                totalPrice: item.total_price ? item.total_price : 0,
                            });
                        });
                        setApproveDetail(newApproveDetail);
                    }

                } else {
                    window.location.href = '/order';
                }
            });
        }


        fetchAllAdmin();
        fetchOrderDetail();

    }, [id, setIsLoading]);

    /*
    useEffect(() => {
        if (localStorage.getItem('admin_role') === '1') {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                if (response.data.status === 200) {
                    setAllAdmin(prevAllAdmin => [
                        ...prevAllAdmin,
                        ...response.data.data
                    ]);
                }
            }).catch(function (error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'ไม่สามารถเรียกข้อมูล Admin ได้'
                });
            });
        }
    }, []);
    */

    const statusOption = [
        {
            value: 8,
            label: 'รอการติดต่อกลับภายใน 24 ชั่วโมง',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
        {
            value: 10,
            label: 'กำลังดำเนินการ/รอการพิจารณา',
            color: 'text-white',
            bg: 'bg-amber-500',
        },
        {
            value: 11,
            label: 'อนุมัติ',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
        {
            value: 12,
            label: 'ไม่อนุมัติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 9,
            label: 'ดำเนินการเสร็จสิ้น',
            color: 'text-white',
            bg: 'bg-green-500',
        },
    ];

    const whyNotApproveOption = [
        {
            value: 1,
            label: 'ติด NPL',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'สภาพการเดินบัญชีไม่ปกติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 3,
            label: 'เป็นลูกค้าปัจจุบัน',
            color: 'text-white',
            bg: 'bg-red-600',
        },
    ];

    const convertToPill = (text, color, bg) => {
        return <span className={`px-4 py-1 text-xs whitespace-nowrap rounded-full ${color} ${bg}`}>{text}</span>
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const addNumberFormat = (number) => {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const handleUpdateOrder = async () => {

        if (approveDetail !== null && approveDetail.length > 0) {
            const errors = approveDetail.map((item, index) => {
                const itemErrors = {};

                if (item.contractDate === null || !dayjs(item.contractDate).isValid()) {
                    itemErrors.contractDate = "Invalid date";
                }
                if (item.contractNumber === '' || item.contractNumber === null) {
                    itemErrors.contractNumber = "Contract number is required";
                }
                if (item.productName === '' || item.productName === null) {
                    itemErrors.productName = "Product name is required";
                }
                if (item.productQty === null || parseInt(item.productQty) <= 0 || item.productQty === '') {
                    itemErrors.productQty = "Invalid product quantity";
                }
                if (item.unitPrice === null || parseFloat(item.unitPrice) <= 0 || item.unitPrice === '') {
                    itemErrors.unitPrice = "Invalid unit price";
                }

                // Always return an object with the index, even if there are no errors
                return { index, errors: Object.keys(itemErrors).length > 0 ? itemErrors : null };
            });

            setApproveDetailError(errors.map(error => error.errors));

            // Check if there are any errors
            const hasErrors = errors.some(error => error.errors !== null);
            if (hasErrors) {
                return;
            }
        }

        setIsLoading(true);
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/order-v2/${id}/update`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                order_status: orderStatus,
                order_total: orderTotal,
                order_products: orderData.orderProduct,
                order_admin_remark: adminNote,
                order_why_not_approve: whyNotApprove,
                assign_to: assignTo,
                //contract_date: contractDate !== null ? dayjs(contractDate).format('YYYY-MM-DD') : null,
                //contract_number: contractNumber,
                approveDetail: approveDetail,
            }
        }).then(function (response) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'แก้ไขสถานะคำสั่งซื้อสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'เกิดข้อผิดพลาด'
            });
        });
    }

    const fetchProducts = (keyword) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/search-for-order?keyword=${keyword}`,
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 200) {
                setProductData(response.data.product);
            } else {
                setProductData([]);
            }
        }).catch(function (error) {
            setProductData([]);
        });
    }

    function debounce(func, delay = 300) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const debouncedFetchContents = debounce((searchValue) => {
        fetchProducts(searchValue);
    }, 500);

    const handleDebonceSearch = (e) => {
        let value = e.target.value;
        if (value === '') {
            setProductData([]);
            return;
        }
        debouncedFetchContents(value);
    }

    const handlePriceChange = (price) => {
        setOrderTotal(parseInt(orderTotal) + parseInt(price));
    }

    const handleBackbuttonClick = () => {
        const params = new URLSearchParams(location.search);
        if (params.get('back')) {
            navigate(params.get('back'));
        } else {
            navigate('/order');
        }
    }

    const onAppreveProductChange = (data) => {
       // console.log(data);
        setApproveDetail(data);
    };

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex flex-col lg:flex-row items-center gap-4 w-full mb-8'>
                <h1 className='text-2xl font-bold flex gap-2 lg:items-center'>
                    <button
                        onClick={() => handleBackbuttonClick()
                        }>
                        <ArrowBackOutlinedIcon />
                    </button>
                    แก้ไขคำสั่งซื้อ #{orderData !== null ? orderData.orderID : ''}
                </h1>
                {convertToPill(
                    statusOption.find((element) => element.value === orderStatus).label,
                    statusOption.find((element) => element.value === orderStatus).color,
                    statusOption.find((element) => element.value === orderStatus).bg,
                )}
            </div>

            {orderData !== null ?
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 items-start'>
                    <div className='col-span-3'>
                        <div className='p-8 bg-white mb-4'>
                            <h3 className='text-xl font-semibold mb-4'>ข้อมูลการสั่งซื้อ</h3>
                            <div className='grid grid-cols-2 lg:grid-cols-4 gap-4'>
                                <div>
                                    <label className='text-gray-500 text-xs'>ลูกค้า:</label>
                                    <p className='text-md'>{orderData.orderDetail.c_name} {orderData.orderDetail.c_lastname}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>อีเมล:</label>
                                    <p className='text-md'>{orderData.orderDetail.c_email}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>วัน - เวลา:</label>
                                    <p className='text-md'>{orderData.createdDate} {orderData.createdTime}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>ยอดรวม:</label>
                                    <p className='text-md'>{addNumberFormat(orderTotal)} บาท</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>ไอพีแอดเดรสลูกค้า:</label>
                                    <p className='text-md'>{orderData.orderDetail.c_ip}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>เบอร์โทร</label>
                                    <p className='text-md'>{orderData.orderDetail.c_phone}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>อาชีพ</label>
                                    <p className='text-md'>{orderData.orderDetail.c_company}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>ที่อยู่</label>
                                    <p className='text-md'>
                                        {orderData.orderDetail.c_address}
                                        <br />
                                        แขวง{orderData.orderDetail.c_sub_district} เขต{orderData.orderDetail.c_district}
                                        <br />
                                        {orderData.orderDetail.c_province} {orderData.orderDetail.c_post_code}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='p-8 bg-white mb-4'>
                            <h3 className='text-xl font-semibold mb-4'>สินค้า</h3>
                            <div className='hidden lg:flex pb-2 border-b'>
                                <p className='lg:w-16 mr-5'></p>
                                <p className='lg:w-3/12'>สินค้า</p>
                                <p className='lg:w-2/12 text-center'>จำนวน</p>
                                <p className='lg:w-3/12 text-right'>ราคาต่อชิ้น</p>
                                <p className='lg:w-3/12 text-right'>รวม</p>
                                <p className='lg:w-1/12 text-right'></p>
                            </div>
                            {orderData.orderProduct.map((item, index) => (
                                <div key={index} className='flex items-center py-2 border-b'>
                                    <div className='w-16 h-16 bg-gray-200 rounded-lg mr-4 p-2'>
                                        <a
                                            href={`${process.env.REACT_APP_FRONTEND_URL}/product/${item.product_slug}`}
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={`${process.env.REACT_APP_FRONTEND_API}/image?name=${item.main_image_full}&width=350`}
                                                alt={item.product_name}
                                                className='w-full h-full object-cover rounded-lg'
                                            />
                                        </a>
                                    </div>
                                    <div className='flex-1 gap-4 lg:flex lg:justify-between items-center'>
                                        <p className='text-sm lg:w-3/12'>
                                            <input
                                                type='text'
                                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                                                placeholder="ชื่อสินค้า"
                                                value={item.product_name}
                                                onChange={(e) => {
                                                    let newOrderProduct = [...orderData.orderProduct];
                                                    newOrderProduct[index].product_name = e.target.value;
                                                    setOrderData({
                                                        ...orderData,
                                                        orderProduct: newOrderProduct
                                                    })
                                                }}
                                            />
                                        </p>
                                        <p className='text-sm lg:w-2/12 lg:text-center'>
                                            <span className='lg:hidden'>จำนวน</span>
                                            <input
                                                type='number'
                                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none text-center'
                                                placeholder="จำนวน"
                                                value={item.order_qty}
                                                onChange={(e) => {
                                                    let newOrderProduct = [...orderData.orderProduct];
                                                    newOrderProduct[index].order_qty = e.target.value;
                                                    setOrderData({
                                                        ...orderData,
                                                        orderProduct: newOrderProduct
                                                    })
                                                    setOrderTotal(newOrderProduct.reduce((a, b) => a + (b['product_price'] * b['order_qty'] || 0), 0));
                                                }}
                                            />
                                        </p>
                                        <p className='text-sm lg:w-3/12 lg:text-right'>
                                            <span className='lg:hidden'>ราคาต่อชิ้น</span>
                                            <input
                                                type='number'
                                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none text-right'
                                                placeholder="ราคาต่อชิ้น"
                                                value={item.product_price}
                                                onChange={(e) => {
                                                    let newOrderProduct = [...orderData.orderProduct];
                                                    newOrderProduct[index].product_price = e.target.value;
                                                    setOrderData({
                                                        ...orderData,
                                                        orderProduct: newOrderProduct
                                                    })
                                                    setOrderTotal(newOrderProduct.reduce((a, b) => a + (b['product_price'] * b['order_qty'] || 0), 0));
                                                }}
                                            />
                                        </p>
                                        <p className='text-sm lg:w-3/12 font-semibold lg:text-right'>
                                            <span className='lg:hidden'>รวม</span>
                                            {addNumberFormat(item.product_price * item.order_qty)}
                                        </p>
                                        <p className='text-sm lg:w-1/12 text-right'>
                                            <button
                                                className=''
                                                onClick={() => {
                                                    let newOrderProduct = [...orderData.orderProduct];
                                                    newOrderProduct.splice(index, 1);
                                                    setOrderData({
                                                        ...orderData,
                                                        orderProduct: newOrderProduct
                                                    })
                                                }}
                                            >
                                                <CloseOutlinedIcon />
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            ))}
                            {/** Open Dialog button */}
                            <div className='flex justify-end mt-4'>
                                <button
                                    className='bg-indigo-800 px-4 py-2 text-white'
                                    onClick={() => {
                                        setOpenialog(true);
                                        setDialogAction('product');
                                    }}
                                >
                                    เพิ่มสินค้า
                                </button>
                            </div>
                        </div>

                        {orderStatus === 11 &&
                            <ApproveProducts
                                allowSearch={true}
                                onInputChange={onAppreveProductChange}
                                approveDetailError={approveDetailError}
                                setApproveDetailError={setApproveDetailError}
                                initiateData={approveDetail}
                            />
                        }

                        <div className='p-8 bg-white mb-4' >
                            <h3 className='text-xl font-semibold mb-4'>แผนที่</h3>
                            <GoogleMapIframe lat={orderData.orderDetail.c_lat} lng={orderData.orderDetail.c_lng} />
                        </div>
                    </div>
                    <div className='col-span-1 sticky top-10'>
                        <div className='p-8 bg-white mb-4'>

                            <h3 className='text-xl font-semibold mb-4'>สถานะคำสั่งซื้อ</h3>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={orderStatus}
                                        onChange={handleOrderStatusChange}
                                        disabled={isLoading}
                                        variant="standard"
                                    >
                                        {statusOption.map((key, index) => (
                                            <MenuItem key={index} value={key.value}>{key.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {orderStatus === 12 &&
                                <Box sx={{ minWidth: 120, mt: 2 }}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={whyNotApprove}
                                            onChange={(e) => setWhyNotApprove(e.target.value)}
                                            disabled={isLoading}
                                            variant="standard"
                                        >
                                            {whyNotApproveOption.map((key, index) => (
                                                <MenuItem key={index} value={key.value}>{key.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                            <button
                                className='mt-4 w-full bg-indigo-800 px-4 py-2 text-white'
                                disabled={isLoading}
                                onClick={() => handleUpdateOrder()}
                            >
                                บันทึกข้อมูล
                            </button>
                        </div>
                        {orderData.orderDetail.interesting_in &&
                            <div className='p-8 bg-white mb-4'>
                                <h3 className='text-xl font-semibold mb-4'>สินค้าที่สนใจ</h3>
                                <p>{orderData.orderDetail.interesting_in === 'gold' ? 'ทอง' : 'เครื่องใช้ไฟฟ้า'}</p>
                            </div>
                        }
                        {allAdmin.length > 0 && localStorage.getItem('admin_role') === '1' &&
                            <div className='p-8 bg-white mb-4'>
                                <h3 className='text-xl font-semibold mb-4'>สาขาที่ดูแล</h3>
                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    options={allAdmin || []}
                                    getOptionLabel={(option) => option.admin_name}
                                    renderInput={(params) => <TextField {...params} label="เลือกสาขาที่ต้องการมอบหมาย" variant="outlined" />}
                                    onChange={(event, newValue) => {
                                        setAssignTo(newValue ? newValue.id : null);
                                    }}
                                    value={allAdmin.find((item) => item.id === assignTo) || null}
                                />
                                {/*<FormControl fullWidth>
                                    <Select
                                        value={assignTo}
                                        onChange={(e) => setAssignTo(e.target.value)}
                                        disabled={isLoading}
                                        variant="standard"
                                    >
                                        <MenuItem value={1}>ไม่ระบุ</MenuItem>
                                        {allAdmin.map((key, index) => (
                                            <MenuItem key={index} value={key.id}>{key.admin_name}</MenuItem>
                                        ))}
                                    </Select>
                                        </FormControl>*/}
                            </div>
                        }

                        <OrderMessage
                            orderId={id}
                            orderTable='web_order'
                            setAlert={setAlert}
                        />

                        {orderData.orderDetail.order_note &&
                            <div className='p-8 bg-white mb-4'>
                                <h3 className='text-xl font-semibold mt-8 mb-4'>บันทึกเพิ่มเติมจากลูกค้า</h3>
                                <p>{orderData.orderDetail.order_note}</p>
                            </div>
                        }
                    </div>
                </div>
                : ''}

            <Dialog
                open={openDialog}
                onClose={() => { setOpenialog(false); }}
                fullWidth={true}
            >
                <DialogTitle>
                    ค้นหาสินค้า
                </DialogTitle>
                <DialogContent>
                    <div className='mb-4'>
                        <input
                            type='text'
                            className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                            placeholder="ค้นหาสินค้า"
                            onChange={(e) => handleDebonceSearch(e)}
                        />
                    </div>
                    <div className='max-h-96 overflow-y-auto'>

                        {productData.map((item, index) => (
                            <div
                                key={index} className='flex items-center py-2 border-b cursor-pointer'
                                onClick={() => {

                                    if (dialogAction === 'product') {

                                        setOrderData({
                                            ...orderData,
                                            orderProduct: [
                                                ...orderData.orderProduct,
                                                {
                                                    product_id: item.id,
                                                    product_name: item.name,
                                                    product_slug: item.slug,
                                                    product_price: item.price,
                                                    order_qty: 1,
                                                    main_image_full: item.imageName,
                                                    order_id: id,
                                                }
                                            ]
                                        })
                                        setOpenialog(false);
                                        handlePriceChange(item.price);
                                    } else {
                                        setApproveDetail([...approveDetail,
                                        {
                                            contractDate: dayjs(),
                                            contractNumber: '',
                                            productId: item.id,
                                            productName: item.name,
                                            productQty: 1,
                                            unitPrice: item.price,
                                            totalPrice: item.price,
                                        }]);
                                        setOpenialog(false);
                                    }
                                }}
                            >
                                <div>
                                    <strong>{item.brand} {item.name}</strong><br />
                                    <small>
                                        Model: {item.modelName ? item.modelName : 'ไม่ระบุ'},
                                        ราคา: {addNumberFormat(item.sale !== '0.00' ? item.sale : item.price)},
                                        สต๊อก: {item.stock ? item.stock : 'ไม่กำหนด'}
                                    </small>
                                </div>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenialog(false); }}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>



            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}