const GoogleMapIframe = ({ lat, lng }) => {
  const location = `${lat},${lng}`;
  const mapSrc = `https://maps.google.com/maps?q=${location}&t=&z=13&ie=UTF8&iwloc=&output=embed`;

  return (
    <div className="relative" style={{
        minHeight: '450px'
    }}>
      <iframe 
        className="absolute w-full border-0" 
        src={mapSrc} 
        allowFullScreen 
        title="Google Map"
        style={{
            height: '450px'
        }}
      />
    </div>
  );
}

export default GoogleMapIframe;
