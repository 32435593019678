import { useState, useEffect, useContext, useRef } from 'react';
import api from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import LoadingContext from '../contexts/LoadingContext';
import { useAlertSnackbar } from '../contexts/AlertSnackbarContext';
import {
    Autocomplete, TextField, Button, FormControl, FormLabel,
    FormControlLabel, FormHelperText, RadioGroup, Radio, CircularProgress, Checkbox, FormGroup
} from "@mui/material";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';

const SatisfactionSurveyForm = ({ id }) => {

    const formRef = useRef(null);
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { showAlert } = useAlertSnackbar();
    const [branches, setBranches] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const productType = [
        { label: 'ทองคำรูปพรรณ', value: 'gold' },
        { label: 'เครื่องใช้ไฟฟ้า', value: 'appliance' },
    ];
    const [selectedProductType, setSelectedProductType] = useState('gold');

    const admin_branch_id = parseInt(localStorage.getItem('admin_branch_id'));
    const admin_role = localStorage.getItem('admin_role');

    const source = [
        { label: 'ผู้ซื้อ', value: 'ผู้ซื้อ' },
        { label: 'ผู้ค้ำ', value: 'ผู้ค้ำ' },
    ];

    /*
    const typeOfProducts = [
        { label: 'ทองคำรูปพรรณ', value: 'ทองคำรูปพรรณ' },
        { label: 'เครื่องใช้ไฟฟ้า', value: 'เครื่องใช้ไฟฟ้า' },
    ];
    */

    const paInsurances = ['1', '2', '3', '4', '5', '6', '7'];

    const productQuality = [
        { label: 'ดี: ใช้งานดีมาก ไม่มีปัญหา', value: '3' },
        { label: 'ปานกลาง:  ใช้งานดี มีปัญหาบ้างแก้ไขปัญหาจบ', value: '2' },
        { label: 'ปรับปรุง:  การใช้งานแย่ ไม่เหมือนการโฆษณา', value: '1' },
    ];

    const productsAccordingToSpecifications = [
        { label: 'ดี: สินค้าที่ได้รับตรงกับที่ลูกค้าสั่ง', value: '3' },
        { label: 'ปานกลาง: สินค้าที่ได้รับไม่ตรงกับลูกค้าสั่งแต่มีการชี้แจง', value: '2' },
        { label: 'ปรับปรุง: สินค้าที่ได้รับไม่ตรงตามที่ลูกค้าสั่งและไม่มีการชี้แจง', value: '1' },
    ];

    const dressAndSpeak = [
        { label: 'ดี: แต่งกายสุภาพ บุคลิกดี พูดจาสุภาพ มีกาลเทศะ', value: '3' },
        { label: 'ปานกลาง:  แต่งกายดี พูดจาดี', value: '2' },
        { label: 'ปรับปรุง: แต่งกายไม่สุภาพ พูดจาไม่สุภาพ พูดไม่มีกาลเทศะ', value: '1' },
    ];

    const delivery = [
        { label: 'ดี: ส่งตามเวลาที่นัดหมายกับลูกค้า', value: '3' },
        { label: 'ปานกลาง: ส่งไม่ตรงเวลาที่นัดหมายแต่มีการโทรแจ้งลูกค้า', value: '2' },
        { label: 'ปรับปรุง: ส่งไม่ตรงเวลาที่นัดหมายและไม่โทรแจ้งลูกค้า', value: '1' },
    ];

    const buyerQuestions = [
        {
            label: 'อาชีพ สถานที่ประกอบการค้า ระยะเวลาการประกอบการค้า รายได้',
            fieldName: 'ss_cereer',
        },
        {
            label: 'สอบถามข้อมูลคู่สมรสผู้ซื้อ',
            fieldName: 'ss_spouse',
        },
        {
            label: 'สอบถามข้อมูลบุคคลที่ 3 / ความสัมพันธ์ / เบอร์โทรศัพท์',
            fieldName: 'ss_third_person',
        },
        {
            label: 'ชื่อคู่สมรส / ข้อมูลบุคคลที่ 3 / ความสัมพันธ์',
            fieldName: 'ss_spouse_name',
        }
    ];

    const guarantorQuestions = [
        {
            label: 'อาชีพ สถานที่ประกอบการค้า ระยะเวลาการประกอบการค้า รายได้',
            fieldName: 'ss_guarantor_career',
        },
        {
            label: 'สอบถามข้อมูลคู่สมรสผู้ซื้อ',
            fieldName: 'ss_guarantor_spouse',
        },
        {
            label: 'สอบถามข้อมูลบุคคลที่ 3 / ความสัมพันธ์ / เบอร์โทรศัพท์',
            fieldName: 'ss_guarantor_third_person_name',
        },
        {
            label: 'ชื่อคู่สมรส / ข้อมูลบุคคลที่ 3 / ความสัมพันธ์',
            fieldName: 'ss_guarantor_spouse_name',
        }
    ];

    const goldQuestions = [
        {
            label: 'น้ำหนักทองคำรูปพรรณที่ได้รับการอนุมัติ',
            fieldName: 'product_gold_weight',
        },
        {
            label: 'ยอดเงินที่ต้องชำระในวันรับสินค้า',
            fieldName: 'product_gold_price',
        }
    ];

    const applianceQuestions = [
        {
            label: 'ประเภทสินค้า / ยี่ห้อ / รุ่น',
            fieldName: 'product_appliance_type',
        },
        {
            label: 'ยอดชำระวันรับสินค้า (ค่างวด + ค่าดำเนินการ)',
            fieldName: 'product_appliance_price',
        }
    ];

    const paQuestions = [
        {
            label: 'เงื่อนไขการแถมประกันอุบัติเหตุ PA แผน 1',
            fieldName: 'pa_condition',
        },
        {
            label: 'แจ้งแผนการคุ้มครอง เงื่อนไขการคุ้มครอง',
            fieldName: 'pa_protection_condition',
        },
        {
            label: 'แจ้งยอดชำระค่าประกัน PA (กรณีลูกค้าต้องการซื้อกรมธรรม์เพิ่ม / ลูกค้าที่มีอายุ 61 ปีขึ้นไปต้องจ่ายต่างค่าเบี้ย',
            fieldName: 'pa_price',
        }
    ];

    useEffect(() => {
        setLoading(true);
        api.get('/branch-admin/get-all')
            .then(response => {
                setBranches(response.data.data);
                if (admin_role === '2' && admin_branch_id && !id) {
                    setValue('branch', response.data.data.find(branch => branch.id === admin_branch_id));
                }
            }).catch(error => {
                console.error('Error fetching branches:', error);
            }).finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const validationSchema = yup.object().shape({

        branch: yup.object().shape({
            id: yup.string().required('Branch is required'),
            admin_name: yup.string().required('Branch is required'),
            admin_username: yup.string().required('Branch is required'),
            admin_role: yup.string().required('Branch is required'),
        }),
        satisfaction_date: yup
            .date()
            .test('isFuture', 'Date and time cannot be in the future', value => {
                return dayjs().isAfter(dayjs(value));
            })
            .test('isValidDateRange', 'Date is not within a valid range', value => {
                const minDate = dayjs('2000-01-01');
                return dayjs(value).isAfter(minDate);
            })
            .required('กรุณาระบุวันที่'),
        contract_number: yup.string().required('กรุณาระบุเลขที่สัญญา'),
        firstname: yup.string().required('กรุณาระบุชื่อ'),
        lastname: yup.string().required('กรุณาระบุนามสกุล'),
        phone: yup.string().min(10, 'หมายเลขโทรศัพท์ต้องมีอย่างน้อย 10 ตัวอักษร').required('กรุณาระบุหมายเลขโทรศัพท์'),

        source: yup.string().required('กรุณาระบุข้อมูลจาก'),
        address: yup.string().required('กรุณาระบุที่อยู่ปัจจุบัน'),
        //product_type: yup.string().required('กรุณาระบุประเภทสินค้าที่ซื้อ'),
        //pa_insurance: yup.string().required('กรุณาระบุแผนประกัน PA'),
        product_quality: yup.string().required('กรุณาระบุคุณภาพสินค้า'),
        products_according_to_specifications: yup.string().required('กรุณาระบุสินค้าตรงตามสเปค'),
        dress_and_speak: yup.string().required('กรุณาระบุแต่งกายและพูดจา'),
        delivery: yup.string().required('กรุณาระบุจัดส่งตรงเวลาและสถานที่'),
        survey_contract_date: yup.date().nullable(),
        survey_before_call: yup.date().nullable(),

        ss_guarantor_name: yup.string().required('กรุณาระบุชื่อผู้ค้ำ'),
        ss_guarantor_lastname: yup.string().required('กรุณาระบุนามสกุลผู้ค้ำ'),
        ss_guarantor_phone: yup.string().min(10, 'หมายเลขโทรศัพท์ต้องมีอย่างน้อย 10 ตัวอักษร').required('กรุณาระบุหมายเลขโทรศัพท์ผู้ค้ำประกัน'),

        product_type: yup.string().required('กรุณาระบุประเภทสินค้าที่ซื้อ'),

        /*
        product_gold_weight: yup.string().when('product_type', {
            is: 'gold',
            then: () => yup.string().required('กรุณาระบุน้ำหนักทอง'),
        }),
        product_gold_price: yup.string().when('product_type', {
            is: 'gold',
            then: () => yup.string().required('กรุณาระบุราคาทอง'),
        }),
        product_appliance_type: yup.string().when('product_type', {
            is: 'appliance',
            then: () => yup.string().required('กรุณาระบุชื่อสินค้า'),
        }),
        product_appliance_brand: yup.string().when('product_type', {
            is: 'appliance',
            then: () => yup.string().required('กรุณาระบุยี่ห้อสินค้า'),
        }),
        product_appliance_model: yup.string().when('product_type', {
            is: 'appliance',
            then: () => yup.string().required('กรุณาระบุรุ่นสินค้า'),
        }),
        product_appliance_price: yup.string().when('product_type', {
            is: 'appliance',
            then: () => yup.string().required('กรุณาระบุราคาสินค้า'),
        }),
        */

    });

    const handleFormSubmit = async (data) => {

        const score = calculateScore(data);
        const maxScore = 12;
        const percentage = calculatePercentage(score, maxScore);

        const formData = {
            ...data,
            totalScore: score,
            percentage,
        };

        setIsLoading(true);
        try {
            if (id) {
                await api.put(`/satisfaction-survey/update/${id}`, formData);
                showAlert('อัปเดตข้อมูลเรียบร้อยแล้ว', 'success');
            } else {
                await api.post('/satisfaction-survey/create', formData);
                showAlert('บันทึกข้อมูลเรียบร้อยแล้ว', 'success');
                navigate('/satisfaction-survey');
            }
        } catch (error) {
            showAlert(error.response?.data?.message || 'Error adding item', 'error');
        } finally {
            setIsLoading(false);
        }
    };


    const { handleSubmit, control, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            satisfaction_date: dayjs(),
            survey_before_call: dayjs(),
            survey_contract_date: dayjs(),
            product_quality: '',
            products_according_to_specifications: '',
            dress_and_speak: '',
            delivery: '',
        },
    });

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            api.get(`/satisfaction-survey/single/${id}`)
                .then(response => {
                    const data = response.data.data;
                    setValue('branch', data.branch);
                    setValue('satisfaction_date', dayjs(data.survey_date));
                    setValue('contract_number', data.survey_contract_number);
                    setValue('firstname', data.survey_firstname);
                    setValue('lastname', data.survey_lastname);
                    setValue('phone', data.survey_phone);
                    setValue('source', data.survey_source);
                    setValue('address', data.survey_address);
                    //setValue('product_type', data.survey_product_type);
                    setValue('pa_insurance', data.survey_pa_insurance?.toString());
                    setValue('product_quality', data.survey_product_quality);
                    setValue('products_according_to_specifications', data.survey_products_according_to_specifications);
                    setValue('dress_and_speak', data.survey_dress_and_speak);
                    setValue('delivery', data.survey_delivery);
                    setValue('remark', data.survey_remark);
                    setValue('survey_before_call', data.survey_before_call ? dayjs(data.survey_before_call) : null);
                    setValue('survey_contract_date', data.survey_contract_date ? dayjs(data.survey_contract_date) : null);

                    setValue('ss_cereer', data.ss_cereer === 1 ? true : false);
                    setValue('ss_spouse', data.ss_spouse === 1 ? true : false);
                    setValue('ss_third_person', data.ss_third_person === 1 ? true : false);
                    setValue('ss_spouse_name', data.ss_spouse_name === 1 ? true : false);

                    setValue('ss_guarantor_career', data.ss_guarantor_career === 1 ? true : false);
                    setValue('ss_guarantor_spouse', data.ss_guarantor_spouse === 1 ? true : false);
                    setValue('ss_guarantor_third_person_name', data.ss_guarantor_third_person_name === 1 ? true : false);
                    setValue('ss_guarantor_spouse_name', data.ss_guarantor_spouse_name === 1 ? true : false);


                    setSelectedProductType(data.product_type);
                    setValue('product_type', data.product_type || null);
                    if (data.product_type && data.product_type === 'gold') {
                        setValue('product_gold_weight', data.product_gold_weight === 1 ? true : false);
                        setValue('product_gold_price', data.product_gold_price === 1 ? true : false);
                    } else if (data.product_type && data.product_type === 'appliance') {
                        setValue('product_appliance_type', data.product_appliance_type === 1 ? true : false);
                        setValue('product_appliance_price', data.product_appliance_price === 1 ? true : false);
                    }

                    setValue('pa_condition', data.pa_condition === 1 ? true : false);
                    setValue('pa_protection_condition', data.pa_protection_condition === 1 ? true : false);
                    setValue('pa_price', data.pa_price === 1 ? true : false);

                    setValue('ss_guarantor_name', data.ss_guarantor_name || '');
                    setValue('ss_guarantor_lastname', data.ss_guarantor_lastname || '');
                    setValue('ss_guarantor_phone', data.ss_guarantor_phone || '');

                }).catch(error => {
                    console.error('Error fetching satisfaction survey:', error);
                    showAlert('Error fetching satisfaction survey', 'error');
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = handleSubmit(handleFormSubmit, (errors) => {
        if (errors) {
            const firstError = Object.keys(errors)[0];
            //console.log('firstError:', firstError);
            const errorElement = formRef.current.querySelector(`[name=${firstError}]`);
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                //showAlert(errors[firstError].message, 'error');
            } else {
                formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    });

    const calculateScore = (data) => {
        let score = 0;
        score += parseInt(data.product_quality) || 0;
        score += parseInt(data.products_according_to_specifications) || 0;
        score += parseInt(data.dress_and_speak) || 0;
        score += parseInt(data.delivery) || 0;
        return score;
    };

    const calculatePercentage = (score, maxScore) => {
        return (score / maxScore) * 100;
    };

    const watchedFields = watch(['product_quality', 'products_according_to_specifications', 'dress_and_speak', 'delivery']);

    useEffect(() => {
        const scoreData = {
            product_quality: watchedFields[0],
            products_according_to_specifications: watchedFields[1],
            dress_and_speak: watchedFields[2],
            delivery: watchedFields[3],
        };

        const score = calculateScore(scoreData);
        const maxScore = 12;
        const percentage = calculatePercentage(score, maxScore);

        setTotalScore(score);
        setPercentage(percentage);
    }, [watchedFields]);

    // Debug errors
    /*
    useEffect(() => {
        console.log('errors:', errors);
    }, [errors]);
    */

    return (
        <div className="max-w-xl w-full mx-auto">

            <button
                className='flex gap-2 items-center mb-3 text-black'
                onClick={() => navigate('/satisfaction-survey')}
            >
                <ArrowBackOutlinedIcon />
                <span>Back</span>
            </button>

            <div className="bg-white rounded-lg p-6">
                <form onSubmit={(event) => { event.preventDefault(); onSubmit(event); }} ref={formRef}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        <div className="mb-6">
                            <Controller
                                name="branch"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={branches}
                                        getOptionLabel={(option) => option.admin_name}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="สาขา"
                                                error={!!errors.branch}
                                                helperText={errors.branch?.message}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                        value={field.value || null}
                                        onChange={(event, newValue) => field.onChange(newValue)}
                                    />
                                )}
                            />
                        </div>

                        {/* วันที่ออกสัญญา */}
                        <div className='mb-6'>
                            <Controller
                                name="survey_contract_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="วันที่ออกสัญญา"
                                        value={field.value}
                                        onChange={(newValue) => field.onChange(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        maxDate={dayjs()}
                                        error={!!errors.survey_contract_date}
                                        helperText={errors.survey_contract_date?.message}
                                    />
                                )}
                            />
                        </div>

                        {/* วันที่ Call Center ก่อน */}
                        <div className='mb-6'>
                            <Controller
                                name="survey_before_call"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="วันที่ Call Center ก่อน"
                                        value={field.value}
                                        onChange={(newValue) => field.onChange(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        maxDate={dayjs()}
                                        error={!!errors.survey_before_call}
                                        helperText={errors.survey_before_call?.message}
                                    />
                                )}
                            />
                        </div>

                        <hr />

                        <h3 className="text-lg font-semibold mt-4 mb-4">ข้อมูลผู้ซื้อ</h3>
                        <div className='grid grid-cols-2 gap-4 mb-4'>
                            <Controller
                                name="firstname"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="ชื่อ"
                                        error={!!errors.firstname}
                                        helperText={errors.firstname?.message}
                                        fullWidth
                                        value={field.value || ''}
                                    />
                                )}
                            />
                            <Controller
                                name="lastname"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="นามสกุล"
                                        error={!!errors.lastname}
                                        helperText={errors.lastname?.message}
                                        fullWidth
                                        value={field.value || ''}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-4'>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="หมายเลขโทรศัพท์"
                                        error={!!errors.phone}
                                        helperText={errors.phone?.message}
                                        fullWidth
                                        value={field.value || ''}
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "\\d*",
                                            inputMode: "numeric"
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const onlyNums = value.replace(/[^0-9]/g, '');
                                            field.onChange(onlyNums);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-6'>

                            <FormControl component={'fieldset'} variant='standard' error={buyerQuestions.map(question => errors[question.fieldName]).some(Boolean)}>
                                <FormLabel component="legend">สอบถามข้อมูลส่วนตัว ที่อยู่อาศัย ปีที่อาศัย เบอร์โทรศัพท์<br />(ติ๊กเมื่อสอบถามข้อมูลลูกค้าแล้ว)</FormLabel>
                                <FormGroup>
                                    {buyerQuestions.map((question, index) => (
                                        <Controller
                                            key={index}
                                            name={question.fieldName}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label={question.label}
                                                />
                                            )}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>


                        </div>

                        <hr />

                        <h3 className="text-lg font-semibold mb-4 mt-4">ข้อมูลผู้ค้ำประกัน</h3>

                        <div className='grid grid-cols-2 gap-4 mb-4'>
                            {/*	ss_guarantor_name, ss_guarantor_lastname */}
                            <Controller
                                name="ss_guarantor_name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="ชื่อผู้ค้ำ"
                                        error={!!errors.ss_guarantor_name}
                                        helperText={errors.ss_guarantor_name?.message}
                                        fullWidth
                                        value={field.value || ''}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />

                            <Controller
                                name="ss_guarantor_lastname"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="นามสกุลผู้ค้ำ"
                                        error={!!errors.ss_guarantor_lastname}
                                        helperText={errors.ss_guarantor_lastname?.message}
                                        fullWidth
                                        value={field.value || ''}
                                        onChange={(e) => field.onChange(e.target.value)}
                                    />
                                )}
                            />
                        </div>

                        {/* ss_guarantor_phone*/}
                        <div className='mb-4'>
                            <Controller
                                name="ss_guarantor_phone"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="หมายเลขโทรศัพท์ผู้ค้ำประกัน"
                                        error={!!errors.ss_guarantor_phone}
                                        helperText={errors.ss_guarantor_phone?.message}
                                        fullWidth
                                        value={field.value || ''}
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "\\d*",
                                            inputMode: "numeric"
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const onlyNums = value.replace(/[^0-9]/g, '');
                                            field.onChange(onlyNums);
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div className='mb-6'>

                            <FormControl component={'fieldset'} variant='standard' error={buyerQuestions.map(question => errors[question.fieldName]).some(Boolean)}>
                                <FormLabel component="legend">สอบถามข้อมูลส่วนตัว ที่อยู่อาศัย ปีที่อาศัย เบอร์โทรศัพท์<br />(ติ๊กเมื่อสอบถามข้อมูลลูกค้าแล้ว)</FormLabel>
                                <FormGroup>
                                    {guarantorQuestions.map((question, index) => (
                                        <Controller
                                            key={index}
                                            name={question.fieldName}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label={question.label}
                                                />
                                            )}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </div>

                        <hr />

                        <div className='mt-6 mb-6'>
                            <Controller
                                name="source"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.source}>
                                        <FormLabel component="legend">ข้อมูลจาก</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="source"
                                            name="source"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {source.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.source?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='mb-6'>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="ที่อยู่ปัจจุบัน"
                                        error={!!errors.address}
                                        helperText={errors.address?.message}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={field.value || ''}
                                    />
                                )}
                            />
                        </div>

                        {/*
                        <div className='mb-6'>
                            <Controller
                                name="product_type"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.product_type}>
                                        <FormLabel component="legend">ประเภทสินค้าที่ซื้อ</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="product_type"
                                            name="product_type"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {typeOfProducts.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.product_type?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        */}



                        <hr />


                        <h3 className="text-lg font-semibold mb-4 mt-6">ข้อมูลการซื้อสินค้า</h3>

                        {/* ประเภทสินค้าที่ซื้อ: product_type, Radiobox */}
                        <div className='mb-4'>
                            <Controller
                                name="product_type"
                                control={control}
                                defaultValue='gold'
                                render={({ field }) => (
                                    <FormControl error={!!errors.product_type}>
                                        <FormLabel component="legend">ประเภทสินค้าที่ซื้อ</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="product_type"
                                            name="product_type"
                                            value={field.value || ''}
                                            onChange={(event) => {
                                                field.onChange(event.target.value)
                                                setSelectedProductType(event.target.value);
                                            }}
                                        >
                                            {productType.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.product_type?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>

                        {selectedProductType === 'gold' && (
                            <div className='mb-6'>
                                <FormControl component={'fieldset'} variant='standard'>
                                    <FormLabel component="legend">ข้อมูลการซื้อสินค้า<br />(ติ๊กเมื่อสอบถามข้อมูลลูกค้าแล้ว)</FormLabel>
                                    <FormGroup>
                                        {goldQuestions.map((question, index) => (
                                            <Controller
                                                key={index}
                                                name={question.fieldName}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                        }
                                                        label={question.label}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        )}

                        {selectedProductType === 'appliance' && (
                            <div className='mb-6'>
                                <FormControl component={'fieldset'} variant='standard'>
                                    <FormLabel component="legend">ข้อมูลการซื้อสินค้า<br />(ติ๊กเมื่อสอบถามข้อมูลลูกค้าแล้ว)</FormLabel>
                                    <FormGroup>
                                        {applianceQuestions.map((question, index) => (
                                            <Controller
                                                key={index}
                                                name={question.fieldName}
                                                control={control}
                                                defaultValue={false}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={field.value}
                                                                onChange={(e) => field.onChange(e.target.checked)}
                                                            />
                                                        }
                                                        label={question.label}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        )}

                        <hr />

                        <h3 className="text-lg font-semibold mb-4 mt-6">PA</h3>
                        <div className='mt-6 mb-4'>
                            <Controller
                                name="pa_insurance"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        options={paInsurances}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="แผนประกัน PA"
                                                error={!!errors.pa_insurance}
                                                helperText={errors.pa_insurance?.message}
                                            />
                                        )}
                                        value={field.value || null}
                                        onChange={(event, newValue) => field.onChange(newValue)}
                                    />
                                )}
                            />
                        </div>

                        {/* paQuestions Checkboxes */}
                        <div className='mb-6'>
                            <FormControl component={'fieldset'} variant='standard'>
                                <FormLabel component="legend">สอบถามข้อมูลส่วนตัว ที่อยู่อาศัย ปีที่อาศัย เบอร์โทรศัพท์<br />(ติ๊กเมื่อสอบถามข้อมูลลูกค้าแล้ว)</FormLabel>
                                <FormGroup>
                                    {paQuestions.map((question, index) => (
                                        <Controller
                                            key={index}
                                            name={question.fieldName}
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    }
                                                    label={question.label}
                                                />
                                            )}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </div>

                        <hr />


                        <div className='mt-8 mb-6'>
                            <Controller
                                name="satisfaction_date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        label="วันที่ Call Center หลังรับสินค้า"
                                        value={field.value || dayjs()}
                                        onChange={(newValue) => field.onChange(newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        maxDate={dayjs()}
                                        error={!!errors.satisfaction_date}
                                        helperText={errors.satisfaction_date?.message}
                                    />
                                )}
                            />
                        </div>
                        <div className='mb-6'>
                            <Controller
                                name="contract_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="เลขที่สัญญา"
                                        error={!!errors.contract_number}
                                        helperText={errors.contract_number?.message}
                                        fullWidth
                                        value={field.value || ''}
                                        inputProps={{
                                            maxLength: 13,
                                            pattern: "\\d*",
                                            inputMode: "numeric"
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const onlyNums = value.replace(/[^0-9]/g, '');
                                            field.onChange(onlyNums);
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <h3 className="text-lg font-semibold mb-4">ความพึงพอใจลูกค้า</h3>

                        <h4 className="text-base font-semibold mb-2">ด้านสินค้า</h4>
                        <div className='mb-2'>
                            <Controller
                                name="product_quality"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.product_quality}>
                                        <FormLabel component="legend">คุณภาพสินค้า</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="product_quality"
                                            name="product_quality"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {productQuality.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.product_quality?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='mb-6'>
                            <Controller
                                name="products_according_to_specifications"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.products_according_to_specifications}>
                                        <FormLabel component="legend">สินค้าตรงตามสเปค</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="products_according_to_specifications"
                                            name="products_according_to_specifications"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {productsAccordingToSpecifications.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.products_according_to_specifications?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <h4 className="text-base font-semibold mb-2">ด้านการบริการของพนักงาน</h4>
                        <div className='mb-6'>
                            <Controller
                                name="dress_and_speak"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.dress_and_speak}>
                                        <FormLabel component="legend">แต่งกายและพูดจา</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="dress_and_speak"
                                            name="dress_and_speak"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {dressAndSpeak.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.dress_and_speak?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <h4 className="text-base font-semibold mb-2">ด้านการส่งสินค้า</h4>
                        <div className='mb-6'>
                            <Controller
                                name="delivery"
                                control={control}
                                render={({ field }) => (
                                    <FormControl error={!!errors.delivery}>
                                        <FormLabel component="legend">จัดส่งตรงเวลาและสถานที่</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-label="delivery"
                                            name="delivery"
                                            value={field.value || ''}
                                            onChange={(event) => field.onChange(event.target.value)}
                                        >
                                            {delivery.map((item, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <FormHelperText>{errors.delivery?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className='mb-6'>
                            <div className='bg-gray-400 px-4 py-1 text-white text-center rounded-3xl mt-2 w-full relative'>
                                <div
                                    className='absolute top-0 left-0 h-full z-0 bg-indigo-500 rounded-3xl'
                                    style={{ width: `${percentage}%`, }}
                                />
                                <div className='z-10 relative text-xs'>รวมคะแนน: {totalScore} / 12 คะแนน ({percentage.toFixed(2)}%)</div>
                            </div>
                        </div>
                        <div className='mb-6'>
                            <Controller
                                name="remark"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="หมายเหตุ"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={field.value || ''}
                                    />
                                )}
                            />
                        </div>


                    </LocalizationProvider>
                    <div className="mb-4">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            fullWidth
                            size='large'
                            disableElevation
                        >
                            บันทึกข้อมูล
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SatisfactionSurveyForm;
