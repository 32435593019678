import { useEffect, useState, useMemo } from 'react';
import api from '../services/apiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { TextField } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { debounce } from 'lodash';

const ApproveProducts = ({ allowSearch = true, initiateData, onInputChange, approveDetailError = [], setApproveDetailError }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);
    const [productData, setProductData] = useState([]);
    const [approveDetail, setApproveDetail] = useState([
       /* {
            contractDate: dayjs(),
            contractNumber: '',
            productId: null,
            productName: '',
            productQty: 1,
            unitPrice: 0,
            totalPrice: 0,
        }*/
    ]);

    //const [approveDetailError, setApproveDetailError] = useState([]);

    const fetchProducts = (keyword) => {
        api({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/search-for-order?keyword=${keyword}`,
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    setProductData(response.data.product);
                } else {
                    setProductData([]);
                }
            })
            .catch(function (error) {
                setProductData([]);
            });
    };

    const addNumberFormat = (number) => {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const debouncedOnInputChange = useMemo(
        () => debounce(fetchProducts, 300),
        [] // Empty dependency array means this will only be created once
    );

    const handleInputChange = (e, index) => {
        const newValue = e.target.value;
        let newApproveDetail = [...approveDetail];
        newApproveDetail[index].productName = newValue;
        setApproveDetail(newApproveDetail);
        debouncedOnInputChange(newValue);
        setIsOpen(true);
    };

    const handleOptionSelect = (option, index) => {
        let newApproveDetail = [...approveDetail];
        newApproveDetail[index].productId = option.id;
        newApproveDetail[index].productName = option.name;
        newApproveDetail[index].unitPrice = option.sale !== '0.00' ? option.sale : option.price;
        setApproveDetail(newApproveDetail);
        setIsOpen(false);

        if (approveDetailError[index]?.productName || approveDetailError[index]?.unitPrice) {
            setApproveDetailError((prev) => {
                let newApproveDetailError = [...prev];
                newApproveDetailError[index].productName = null;
                newApproveDetailError[index].unitPrice = null;
                return newApproveDetailError;
            });
        }
    };

    useEffect(() => {
        if (initiateData) {
            setApproveDetail(initiateData);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (typeof onInputChange !== 'function') {
            return;
        }

        onInputChange(approveDetail);
        // eslint-disable-next-line
    }, [approveDetail]);

    return (
        <div className='p-8 bg-white mb-4'>
            <h3
                className='text-xl font-semibold'
                onClick={() => { console.log(approveDetail) }}
            >
                การอนุมัติสินค้า
            </h3>

            {approveDetail.map((item, index) => (

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 items-start pr-8 relative border-b py-8' key={index}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
                        <DatePicker
                            label="วันที่ออกสัญญา"
                            value={item.contractDate}
                            onChange={(date) => {
                                if (date !== null && date !== undefined && approveDetailError[index]?.contractDate) {
                                    setApproveDetailError((prev) => {
                                        let newApproveDetailError = [...prev];
                                        newApproveDetailError[index].contractDate = null;
                                        return newApproveDetailError;
                                    });
                                }
                                let newApproveDetail = [...approveDetail];
                                newApproveDetail[index].contractDate = date;
                                setApproveDetail(newApproveDetail);
                            }}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!approveDetailError[index]?.contractDate}
                                    helperText={approveDetailError[index]?.contractDate}
                                    size='small'
                                />
                            }
                        />
                    </LocalizationProvider>
                    <TextField
                        label="เลขที่สัญญา"
                        value={item.contractNumber}
                        onChange={(e) => {
                            if (e.target.value !== '' && approveDetailError[index]?.contractNumber) {
                                setApproveDetailError((prev) => {
                                    let newApproveDetailError = [...prev];
                                    newApproveDetailError[index].contractNumber = null;
                                    return newApproveDetailError;
                                });
                            }
                            let newApproveDetail = [...approveDetail];
                            newApproveDetail[index].contractNumber = e.target.value;
                            setApproveDetail(newApproveDetail);
                        }}
                        error={!!approveDetailError[index]?.contractNumber}
                        helperText={approveDetailError[index]?.contractNumber}
                        size='small'
                    />

                    <div className='col-span-2 flex gap-4 flex-col lg:flex-row items-center'>
                        <div className='text-sm lg:w-5/12 relative'>

                            <span className='text-left'>ชื่อสินค้า</span>
                            <input
                                type='text'
                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                                style={approveDetailError[index]?.productName ? { borderColor: '#d32f2f' } : {}}
                                placeholder={allowSearch ? 'พิมพ์เพื่อค้นหาสินค้า' : 'ชื่อสินค้า'}
                                value={item.productName}
                                onChange={(e) => handleInputChange(e, index)}
                                onFocus={() => {
                                    if(allowSearch) {
                                        setIsOpen(true);
                                        setOpenIndex(index);
                                    }
                                }}
                                onBlur={() => allowSearch && setTimeout(() => {
                                    setIsOpen(false);
                                    setOpenIndex(null);
                                }, 200)}
                                autoComplete="off"
                            />
                            {approveDetailError[index]?.productName && <span className='text-[#d32f2f] text-xs'>{approveDetailError[index]?.productName}</span>}

                            {isOpen &&  openIndex === index && (
                                <div className='absolute z-10 max-h-60 w-full px-2 overflow-auto bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"'>

                                    {productData.map((item, i) => (
                                        <div
                                            key={i} className='flex items-center py-2 border-b cursor-pointer'
                                            onClick={() => handleOptionSelect(item, index)}
                                        >
                                            <div className='text-sm'>
                                                <strong>{item.brand} {item.name}</strong><br />
                                                <small>
                                                    Model: {item.modelName ? item.modelName : 'ไม่ระบุ'},
                                                    ราคา: {addNumberFormat(item.sale !== '0.00' ? item.sale : item.price)},
                                                    สต๊อก: {item.stock ? item.stock : 'ไม่กำหนด'}
                                                </small>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>
                        <p className='text-sm lg:w-2/12'>
                            <span className='text-left'>จำนวน</span>
                            <input
                                type='number'
                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none text-center'
                                style={approveDetailError[index]?.productQty ? { borderColor: '#d32f2f' } : {}}
                                placeholder="จำนวน"
                                value={item.productQty}
                                onChange={(e) => {
                                    let newApproveDetail = [...approveDetail];
                                    newApproveDetail[index].productQty = e.target.value;
                                    setApproveDetail(newApproveDetail);

                                    if (e.target.value !== '' && e.target.value !== '0' && approveDetailError[index]?.productQty) {
                                        setApproveDetailError((prev) => {
                                            let newApproveDetailError = [...prev];
                                            newApproveDetailError[index].productQty = null;
                                            return newApproveDetailError;
                                        });
                                    }
                                }}
                            />
                            {approveDetailError[index]?.productQty && <span className='text-[#d32f2f] text-xs'>{approveDetailError[index]?.productQty}</span>}
                        </p>
                        <p className='text-sm lg:w-3/12 lg:text-right'>
                            <span className=''>ราคาต่อชิ้น</span>
                            <input
                                type='number'
                                className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none text-right'
                                style={approveDetailError[index]?.unitPrice ? { borderColor: '#d32f2f' } : {}}
                                placeholder="ราคาต่อชิ้น"
                                value={item.unitPrice}
                                onChange={(e) => {
                                    let newApproveDetail = [...approveDetail];
                                    newApproveDetail[index].unitPrice = e.target.value;
                                    setApproveDetail(newApproveDetail);

                                    if (e.target.value !== '' && approveDetailError[index]?.unitPrice) {
                                        setApproveDetailError((prev) => {
                                            let newApproveDetailError = [...prev];
                                            newApproveDetailError[index].unitPrice = null;
                                            return newApproveDetailError;
                                        });
                                    }
                                }}
                            />
                            {approveDetailError[index]?.unitPrice && <span className='text-[#d32f2f] text-xs'>{approveDetailError[index]?.unitPrice}</span>}
                        </p>
                        <p className='text-sm lg:w-2/12 lg:text-right'>
                            <span className=''>รวม</span>
                            <span className='block'>
                                {addNumberFormat(item.unitPrice * item.productQty)}
                            </span>
                        </p>
                    </div>

                    {/* remove button */}
                    <button
                        className='absolute top-2 right-0'
                        onClick={() => {
                            let newApproveDetail = [...approveDetail];
                            newApproveDetail.splice(index, 1);
                            setApproveDetail(newApproveDetail);
                        }}
                    >
                        <CloseOutlinedIcon />
                    </button>
                </div>
            ))}


            {/* Add button */}
            <div className='flex justify-end mt-4'>
                <button
                    className='bg-indigo-800 px-4 py-2 text-white'
                    onClick={() => {
                        let newApproveDetail = [...approveDetail];
                        newApproveDetail.push({
                            contractDate: dayjs(),
                            contractNumber: '',
                            productId: null,
                            productName: '',
                            productQty: 1,
                            unitPrice: 0,
                            totalPrice: 0,
                        });
                        setApproveDetail(newApproveDetail);
                    }}
                >
                    เพิ่มรายการ
                </button>
            </div>
        </div>
    );
};

export default ApproveProducts;