import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useClickAway } from 'react-use';

export default function ProductModelSearch({ onModelSelected, defModel }) {

    const [keyword, setKeyword] = useState('');
    const [products, setProducts] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const dropdownRef = useRef(null);
    useClickAway(dropdownRef, () => {
        if (isDropdownVisible) {
            setIsDropdownVisible(false);
        }
    });

    useEffect(() => {
        if (defModel && defModel !== 'null - null') {
            setKeyword(defModel);
        }
    }, [defModel]);


    const searchProduct = useCallback((value) => {
        if (!value) {
            setProducts([]);
            setIsDropdownVisible(false);
            return;
        }

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/search/full-search?keyword=${value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.length) {
                setProducts(response.data);
                setIsDropdownVisible(true);
            }
        });
    }, []);


    const debounceSearch = useCallback((value) => {
        const debounced = _.debounce(() => searchProduct(value), 1000);
        debounced();
    }, [searchProduct]);


    const onInputChange = (event) => {
        setKeyword(event.target.value);
        debounceSearch(event.target.value);
        let val = event.target.value;
        if(val.length === 0) {
            onModelSelected(null);
        }
    };

    const onProductSelect = (product) => {
        setKeyword(`${product.product_name} - ${product.model_code}`);
        setProducts([]);
        onModelSelected(product);
    }

    return (
        <div ref={dropdownRef} className='relative'>
            <input
                type='text'
                name='product_title'
                value={keyword}
                onChange={onInputChange}
                placeholder='พิมพ์เพื่อค้นหาสินค้าที่เชื่อมโยง'
                className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 appearance-none focus:outline-none focus:shadow-outline'
            />
            {isDropdownVisible && products.length > 0 && (
                <div className="dropdown-list shadow overflow-auto absolute w-full bg-white z-10" style={{ maxHeight: '350px' }}>
                    {products.map(product => (
                        <div
                            key={product.id}
                            className="dropdown-list-item py-2 px-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => onProductSelect(product)}
                        >
                            {product.product_name} - {product.model_code}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
